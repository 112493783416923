.nav-att {
    margin-left: -2% !important;
    width: 100% !important;
}



.date-feild {
    box-sizing: border-box;

    position: absolute;
    width: 249px;
    height: 48px;
    left: 1160px;
    top: 270px !important;

    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.to-feild {
    box-sizing: border-box;

    position: absolute;
    width: 249px;
    height: 48px;
    left: 1160px;
    top: 210px !important;
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.check-align {
    margin-top: 130%;
}

.check-align2 {
    margin-top: 130%;
    margin-left: -17%;
}

.name-author {
    margin-top: -15%;
    margin-left: 20%;
}

.name-author2 {
    margin-top: -1%;
    margin-left: 20%;
}

.title-sm {
    margin-top: -10%;
    margin-left: 100%;
}

.text-feild1 {
    width: 249px;
    height: 225px !important;
    left: 1169px;
    top: 510px !important;

    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .text-feild1 {
      
        margin-left: 20px !important;
        top: 510px !important;
    
    
    }
    .check-align {
        margin-top: 110%;
        margin-left: 10%;
    }
    
    .check-align2 {
        margin-top: 110%;
        margin-left: -15%;
    }
    .apply-btn2 {
        margin-left: -120px !important;
        margin-top: 55px !important;
    }
}
.apply-btn2 {
    position: absolute;
    width: 249px;
    height: 48px;
    left: 1160px;
    top: 765px;

    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
    border-color: #0080FF;
}

.leave-sm {
    margin-left: -5%;
}



.cal-align2 {
    margin-top: 1%;
    margin-left: -5%;
}

.pre-sm {
    margin-left: -6%;
}

.next-align2 {
    margin-left: 79%;
}

.cal-img {
    margin-top: 10%;
}

.date-bg {
    background: #0080FF !important;
}

.select-feild {

    box-sizing: border-box !important;

    position: absolute !important;
    width: 249px !important;
    height: 48px !important;
    left: 1160px !important;
    top: 150px !important;

    /* background: #F3F3F3 !important; */
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;
}

.nav-normalview {
    margin-left: -10%;
}

.right-norm {
    margin-top: 1% !important;
}
.drpcd{
    position:absolute !important;
    margin-top: 70px !important;
    margin-left: 10px !important;
    background-color: #C4C4C4 !important;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .table-ml {
        margin-left: -2%;
    }

    .days-ml {
        margin-left: 10%;
    }

    .leaves-ml {
        margin-left: -2%;
    }

    .text-ml {
        margin-left: -9%;
        margin-top: 1%;
    }

    .sess-ele {
        margin-top: 150%;
        margin-left: 3%;
        }

    .text-ele {
        margin-top: 2%;
        margin-left: 2%;
    }

    .appl-ele {
        margin-top: 4%;
        margin-left: -9% !important;
    }

    .right-ml {
        margin-left: -1%;
    }
    .ccword14 {
        margin-left: 4%;

    }
    .wed-mob {
        margin-left: 6%;
        margin-top: 9%;
    }
    .times-m14 {
        margin-top: -1% !important;
    }
    .app-mtext {
        margin-left: 3% !important;
    }
    .liv-10 {
        margin-left: 30% !important;
    }


}

@media screen and (min-device-width: 600px)and (-webkit-min-device-pixel-ratio: 1) {
    .hideother {
        display: none !important;
    }
}

@media screen and (min-device-width: 800px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
    .text-mvieww {
        margin-left: -14%;
    }

    .comm-twxt {
        margin-left: 7% !important;
        margin-top: -3%;
    }

    .sess-mb {
        margin-top: 140%;
        margin-left: 10%;
    }
    .app-10 {
        margin-left: 7% !important;
    }
    .ccc-10 {
        margin-top: 23% !important;
        margin-left: 10%;
    }

    .table-mleftt {
        margin-left: -2% !important;
    }
    .pre-10 {
        margin-left: -13%;
    }
    .app-10inch {
        margin-left: -14% !important;
        margin-top: 2%;
    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .apply-none {
        display: none;
    }
     .rbc-button-link {
        color: #4EB4E6 !important;
        background: none !important;
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        -webkit-user-select: text;
        user-select: text;
   
    }
     
    
   
    .days-mll {
        margin-left: 8%;
        margin-top: 10% !important;
    }

    .sele-mb {
        margin-left: -290%;
        margin-top: 15%;
        width: 83%;
    }

    .sele-mb1 {
        margin-left: -290% !important;
        width: 83%;
        margin-top: 1%;
        margin-left: -270%;

    }

    .sele-mb2 {
        margin-left: -290% !important;
        width: 83%;
        margin-top: 32%;
        margin-left: -270%;
    }

    .comm-ml {
        margin-left: -2% !important;
        margin-top: 2%;
        width: 87%;
    }

    .select-wd {
        width: 83% !important;
    }

    .session-ml {
        margin-top: 52%;
        margin-left: 3%;
    }

    .session2-ml {
        margin-top: -6%;
        margin-left: 63%;
    }

    .appbtn {
        margin-top: 22%;
        width: 78%;
        margin-left: -268%;

    }

    .app-text {
        margin-left: 4%;
    }

    .ellipse-round {
        margin-left: 4%;
    }
    .ccword {
        margin-left: 5%;
    }

    .nav-fs {
        /* position: absolute; */

        margin-left: -300px !important;
        top: 80px;

        /* font-family: 'Open Sans'; */
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        color: #212121;
    }

    .leave-fs1 {
        margin-left: -130%;

    }

    .holiday-non {
        display: none !important;
    }

    .holiday-mb {

        margin-left: -150px !important;

    }

    .nav-inmview {
        margin-top: -2%;
        width: 120%;
        margin-left: -22%;
    }

    .month-mb {
        margin-top: -5% !important;
        margin-left: 1% !important;
    }

    .time-mb {
        margin-left: 4%;
    }
    .hidelh {
        margin-left: -6% !important;
    }

    


}
@media (min-width: 768px) and (max-width: 1024px){ 
    
    .wd-tab {
        width: 90% !important;
        margin-left: 10% !important;
    }
    .applyleave-none {
        display: none !important;
    }

    .leavenavtab {
        margin-left: -20% !important;
        margin-top: -3% !important;
    }
    .leavetab {
        margin-left: -5% !important;
        width: 40% !important;
        margin-top: -2% !important;
    }
    .leavenum {
        margin-left: -105% !important;
        margin-top: -1% !important;

    }
    .losstab {
        margin-left:-13% !important;
        width: 40% !important;
        margin-top: -2% !important;
    }
    .lossnum {
        margin-top: -1% !important;
        margin-left: 3% !important
    }
    .earntab {
        margin-left: -19% !important;
        width: 40% !important;
        margin-top: -2% !important;
    }
    .earnum {
        margin-top: -2% !important;
        margin-left: -4% !important;
    }
    .sicktab {
        margin-left: 70% !important;
        margin-top: -3% !important;
    }
    .sicknum {
        margin-left: 1% !important;
    }
    .table-leavetab {
        margin-top: -2% !important;
    }
    .select-feild {
        top: 850px !important;
        left: 20px !important;
         width: 249px !important;
         height: 48px !important;
              
            }
    .to-feild {
        width: 249px;
        height: 48px;
        left: 20px;
        top: 910px !important;
       
    }
    .date-feild {
        width: 249px;
        height: 48px;
        left: 20px;
        top: 970px !important;
    }
    .apply-btn2 {
       left: 20px !important;
       top: 1025px !important;
    }
    .check-align {
        margin-top: 170%;
    }
    
    .check-align2 {
        margin-top: -15%;
        margin-left: 57%;
        width: 200px !important;
    }
    .name-author {
        margin-top: 15%;
        margin-left: 05%;
    }
    
    .name-author2 {
        margin-top: -1%;
        margin-left: 05%;
    }
}