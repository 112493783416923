.date_pay {
    height: 48px !important;
    width: 249px !important;

    border-radius: 10px;

}
.loadtxt{
margin-top: 250px !important;
position: relative;

}
.recbtn{
    width: 144px !important;
}
.bt_pri{
    background-color: #0080FF !important;
}
.nav-link.active {
    font-weight: bold;
}
.nav-link {
    font-weight: normal;
}
.headfonts{
font-size: 12px !important;
}
.salupdate{
    height: 30px !important;
}

 @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
   
  } 
  

  .loadtxt::after {
    content: ".........";
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 2.5em;
    animation: ellipsis 1s infinite;
  }
  
  @keyframes ellipsis {
    0% {
      font-size: 1.5em;
    }
    50% {
      font-size: 1em;
    }
    100% {
      font-size: 1.5em;
    }
  }
  

.clickable {
    text-decoration: underline;
    color: #0D6EFD;
}

.disable-click {
    color: grey;
    cursor: default;
    opacity: 50%;
}

.align-button {
    text-align: end;
}

.date_btn {
    height: 48px !important;
    width: 253px !important;
    top: 200px !important;
    position: absolute;
    border-radius: 5px;

}

.send_img {
    margin-left: 39% !important;
    margin-top: -10% !important;
}

.paydt {
    margin-top: -110px !important;
    margin-left: 600px !important;
}

.paydt1 {
    margin-top: -110px !important;
    margin-left: 890px !important;
}


.filecard {
    width: 215px !important;
    height: 48px !important;
}

.fileupld {
    position: absolute !important;
    margin-left: 10px !important;
    top: 20% !important;
}

.upsele {
    width: 160px !important;
    height: 45px !important;
    border-radius: 5px;
    border: 1px solid gray !important;
}

.dwnbtn {
    margin-left: -100px !important;
}

.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none !important;
}

.react-date-picker__inputGroup {
    display: none !important;
}

.react-date-picker__clear-button {
    display: none !important;
}

.payrolebtn {
    position: absolute;
    width: 160px;
    height: 48px;
    left: 1150px;
    top: 136px;
    border-radius: 5px;
}

.payrolebtn1 {
    left: 1340px;
}

.backrowcolor {
    background: #FFEDED !important;
}

.popupalign {
    margin-top: 220px !important;
    margin-left: 520px !important;
}

.modalback {
    background: #E6E9ED !important;
}

.flbtn {
    width: 100px;
    height: 45px;
    margin-left: 10px !important;
}

.wagesize {
    width: 40% !important;
    height: 65% !important;
}

.modaltext {
    position: absolute;
    width: 301px !important;
    height: 48px !important;
    left: -500px !important;
    top: -200px !important;
    border: 1px solid gray !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}

.comedii {
    position: absolute !important;
    top: 100px !important;
    left: 520px !important;
    background: #E6E9ED !important;
    width: 520px !important;
    height: 500px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.drpsz {
    width: 220px !important;
    
}

.comedi1 {
    position: absolute !important;
    top: -1px !important;
    background-color: white !important;
    height: 120% !important;
}

.comedit {
    position: absolute !important;
    top: 75px !important;
    background-color: white !important;
    height: 120% !important;
}

.backoverlay {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.appbtn {
    margin-top: -65.5px !important;

}

.downsize {
    width: 850px !important;
    height: 550px !important;
    overflow-y: auto;
}
.downsizee {
    width: 850px !important;
}

.tiname {
    margin-left: -500px !important;
    margin-top: -350px !important;
}

.clbt {
    position: absolute !important;
    margin-top: -200px !important;
    margin-left: -120px !important;
}

.modaltestn {
    position: relative !important;
    left: -480px !important;
    top: -200px !important;
}

.modal_text {
    position: absolute;
    width: 40px;
    height: 16px;
    left: 50px;
    top: 30px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: #E6E9ED;
    color: gray;
}

.modal_text1 {
    position: absolute;
    width: 100px;
    height: 16px;
    left: 370px;
    top: 30px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: #E6E9ED;
    color: gray;
}

.modal_text2 {
    position: absolute;
    width: 50px;
    height: 16px;
    left: 370px;
    top: 95px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: #E6E9ED;
    color: gray;
}

.txtpos {
    margin-top: 80px !important;
}

.modal1_text {
    position: absolute;
    width: 30px;
    height: 16px;
    left: 50px;
    top: 95px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: #E6E9ED;
    color: gray;
}

.txtpos1 {
    margin-top: 145px !important;
}

.modal2_text {
    position: absolute;
    width: 110px;
    height: 16px;
    left: 45px;
    top: 160px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: #E6E9ED;
    color: gray;
}

.txt1pos {
    margin-top: 65px !important;
}

.txtpos2 {
    margin-top: 213px !important;
}

.txt2pos {
    margin-top: 198px !important;
}

.txtpos3 {
    margin-top: 198px !important;
}

.txt3pos {
    margin-top: 297px !important;
    margin-left: 30px !important;
}

.txtpos4 {
    margin-top: 138px !important;
    width: 70px !important;
}

.wagebtn {
    position: absolute;
    width: 301px;
    height: 48px;
    left: 200px;
    top: 355px !important;

    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}

.eyeicn {
    margin-left: -35px !important;
}

@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1) {
    .date_pay {
        left: -45px !important;
        height: 48px !important;
        width: 119px !important;

        border-radius: 10px;

    }

    .date_btn {

        height: 48px !important;
        width: 123px !important;

        border-radius: 5px;

    }

    .paydt {

        margin-top: -170px !important;
        margin-left: 185px !important;
    }

    .paydt1 {
        margin-top: -170px !important;
        margin-left: 320px !important;
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .paydt {
        margin-top: -110px !important;
        margin-left: 540px !important;
    }
    .downsize {
        width: 850px !important;
        height: 480px !important;
        overflow-y: auto;
    }
    .paydt1 {
        margin-top: -110px !important;
        margin-left: 790px !important;
    }

    .search_box {
        margin-left: 6% !important;
    }

    .srhicn {
        margin-left: 95% !important;
    }

    .payrolebtn {
        width: 130px !important;
        left: 1050px;
    }

    .payrolebtn1 {
        left: 1190px;
    }

    .tablcard {
        margin-left: 6% !important;
    }

    .dwnbtn {
        margin-left: -50px !important;
    }

    .calicn {
        margin-left: -5% !important;
    }

    .pytextali {
        font-size: 14px !important;
    }
}

@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1) {
    .hidecoltab {
        display: none !important;
    }

    .calicn {
        margin-left: -5% !important;
    }

    .pytextali {
        position: absolute;
        left: -20px !important;
    }

    .upsele {
        margin-left: 80px !important;
    }

    .flbtn {
        margin-left: 80px !important;
        margin-top: 10px !important;
    }

    .eyeicn {
        margin-left: 2px !important;
    }

    .payrolebtn {
        width: 120px !important;
        left: 490px;
    }

    .payrolebtn1 {
        left: 630px;
    }

    .dwndtxttab {
        margin-left: 10px !important;
        width: 250px !important;
    }

    .dwnbtn {
        margin-left: 20px !important;
    }

}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .pytextali {
        position: absolute;
        left: -20px !important;
        top: 110px !important;
    }

    .search_box {
        margin-left: -16% !important;
    }

    .srhicn {
        margin-left: 90% !important;
    }

    .upsele {
        margin-left: -50px !important;
        margin-top: 10px !important;
    }

    .flbtn {
        margin-left: -50px !important;
        margin-top: 10px !important;
    }

    .fileupld {
        position: absolute !important;
        margin-left: 10px !important;
        top: 25% !important;
    }

    .payrolebtn {
        width: 80px !important;
        left: 204px;
        top: 150px !important;
        font-size: 14px !important;
    }

    .payrolebtn1 {
        left: 291px;
        font-size: 14px !important;
    }

    .dwndtxttab {
        margin-left: -2px !important;
        width: 250px !important;
    }

    .dwnbtn {
        margin-left: 100px !important;
    }

    .date_btn {

        height: 48px !important;
        width: 123px !important;

        border-radius: 5px;

    }

    .d1 {
        left: -20px !important;
        width: 80px !important;
        position: absolute;
    }

    .d2 {
        left: 105px !important;
        width: 80px !important;
        position: absolute;
    }

    .d3 {
        left: 215px !important;
        width: 80px !important;
        position: absolute;
    }

    .d4 {
        left: 305px !important;
        width: 80px !important;
        position: absolute;
    }

    .d5 {
        top: 80px !important;
    }

    .paydt {
        margin-top: -266px !important;
        margin-left: 95px !important;
    }

    .paydt1 {
        margin-top: -266px !important;
        margin-left: 180px !important;
    }

    .comedii {
        position: absolute !important;
        top: 190px !important;
        left: 520px !important;
        background: #E6E9ED !important;
        width: 380px !important;
        height: 500px !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
}

.neg2 {
    font-size: x-small;

}

.text_pay {
    position: absolute;
    width: 180px;
    height: 10px;
    margin-left: 2%;
    margin-top: 1%;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #0080FF;
    background-color: white;

}

.text_pay2 {
    position: absolute;
    width: 180px;
    height: 15px;
    margin-left: 2%;
    margin-top: -0.5%;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #0080FF;
    background-color: white;


}