
.loginback
{
background-color:#0080FF; 
height:1024px ;

}

.imgbor {
    max-width: 100%;
    height: 95%;
  }

  .imgborallign {
    width: 99%;
    height: 74%;
  
  }
  

.loginht
{
    height: 1024px;
}
.loginbackground
{
    background-image: url("../Images/Vectu.png");
    height: 606px;
    background-repeat: no-repeat;
    left:5px;
    margin-top:200px;
    background-size: contain;
    background-size: 100%;
}
 .loginimg
{
    position: absolute;
    height:80px !important;
    left: 1% !important;
    top:1%!important;
}
.loginlang
{
    
    height:80px !important;
    left: 1% !important;
    top:1%!important;
}

.logscr{
position: fixed;
 overflow: hidden; 
}
.logallign{
   position:fixed;
     overflow: hidden; 
    }
.signuptxt{
    height: 40px !important;
    border-radius: 10px !important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
    .loginimg
    {
        position: absolute;
    height:80px !important;
    left: 1% !important;
    top:1%!important;
    }

    .loginlang
{
    
    height:80px !important;
    left: 1% !important;
    top:1%!important;
}
    
.imgbor {
    max-width: 100%;
    height: 100%;
 
  }

  .imgborallign {
    width: 99%;
    height: 82%;
 
  }
    }
.curimg{
    height: 400px !important;
}
.circleimg
{
    width:890px;
    height:200px;
    padding-top:0px;
}
.new
{
    height: 340px;
   width: 890px;
}
.circleimg2
{
    padding-left: 740px; 
    padding-top: 50px;
} 
.circleimg1
{
 padding-left: 67px;
 padding-top: 5px;
}

.headn
{
    padding-top:100px;
}
.div3
{
    left:110px;
    top:110px;
    position: relative;

}
.txtstyle
{
    box-sizing: border-box;
    position: relative;
    width: 340px;
    height: 56px;  
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;
    left: -30px !important;
}
.pastxt
{
    box-sizing: border-box;
    position: relative;
    width: 300px;
    height: 46px;  
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;
    left: 0px !important;
}
.lbltext
{
position: relative;
width: 44px;
height: 19px;
left: 15px !important;
top:-40px !important;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #000000;
}
.chekb{
margin-left: 20px;
box-sizing: border-box;
width: 20px;
height: 20px;
border: 1px solid #C4C4C4;
}
.btntxt
{
width: 340px;
height: 56px;
background: #0080FF;
border-radius: 5px;
}
.txtn
{
    padding-left: 20px;
}

form-control inputcolor
{
    width: 50%;
}
.btnc
{
position: absolute;
width: 340px;
height: 56px;
background: #0080FF;
border-radius: 5px;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;



color: #FFFFFF;

}
.divbtm
{
   margin-top:500px;
    
}
.lblaccount
{
    width: 215px;
    height: 19px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
 
    color: #000000;
    padding-left: 40px;
}
labelwidth
{
    width: 10% !important;;
}
.widd
{
    position: absolute;
width: 154px;
height: 19px;
left: 30px !important;
margin-top:-10px !important;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #000000;
}
.txtcenter
{
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #000000;
margin-top: 90px !important;
}
.signup
{
    height: 19px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    
    color:#0080FF;
    
}
.headings
{
    margin-left:120px;
    padding-top:50px;
}
.headlanguage
{
margin-left: 10px;
width: 117px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 12px !important;
line-height: 19px;

color: #0080FF;
}
.headeng
{
    margin-left: 10px;
width: 117px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 12px !important;
line-height: 19px;

color: #727272;
}
.polygon
{
width: 9px;
height: 9px;
margin-left:1px;
}
.login_btn
{
    margin-top: 50px;
    margin-left: -20px !important;
    
}
.lblremeber
{
padding-left:2px;
width: 73px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #727272;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.logbg{
    position: relative;
    height: 279.69705200195312px;
    width:440.0002136230469px !important;
    left: -10px;
    top: 170.6201171875px;
    border-radius: 0px;

}

.sceenmob{
position: static !important;
overflow-y: auto !important;
}
.loginsymply{
    position: absolute;
height:80px !important;
left: 1% !important;
top:1%!important;
}
.cents {
    align-items: center !important;
    text-align: center !important;
} 
.mar {
    align-items: left !important;
   text-align: left !important;
   padding: 0 30px !important;
}

.logleft{
    margin-left: 32px !important;
}

.topmar{
    margin-top: -37px !important; 
}

.forleft{
    margin-top: -57px !important;
    margin-left: 32px !important;
}

.topmar1{
    margin-top: 37px !important;   
}

.log{
    position: relative;
    height: 170px !important;
width: 209px;
left: 4.5px;
top: -105px;
border-radius: 0px;

}

.login_btn
{
    margin-top: 50px;
    margin-left: -25px !important;
}
.headlanguage
{
margin-left: -60px !important; 
width: 117px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 19px;

color: #0080FF;
}
.icn_lg{
    position: absolute;
margin-top: 50px !important;
    margin-left: 17px !important; 
}
.div3
{
    left:50px !important;
    top:110px;
    position: relative;

}
.sym{
    position: absolute;
   margin-top: 55px !important;
   margin-left: 120px !important;
   font-size: larger !important;
    
}
.loginimg
{
   
    left: 19% !important;
    height: 95px !important;
    
}

.loginlang
{
    position: absolute !important;
    left: 15% !important;
    height: 95px !important;
}
}
.bgbtn{
    background-color: #F36E35 !important; 
}
.btn.btn-pri {
    background-color: #F36E35 !important; 
    opacity: 1 !important ;
    border: none;
    color: white !important; 
    cursor: pointer !important; 
    transition: color 0.3s, background-color 0.3s, font-size 0.3s !important; 
   
  
    font-size: 14px !important;
}


.btn.btn-pri:active,
.btn.btn-pri:focus {
    
    color: white !important;
    font-size: 14px !important; 
}


.btn.btn-pri:hover {
    background-color: #4EB4E6 !important; 
    color: white !important;
    font-size: 14px !important; 
}
.btn-secondary{
    font-size: 14px !important;  
}

.btn.btn-pri:disabled {
    opacity: 0.5 !important; 
    cursor: not-allowed !important; 
}

.fnt-clr{
color: #4EB4E6;

}
.inputcolor{

    color: #87D5F4!important;
    
 
}

.inputcolor::placeholder{

    color: #87D5F4!important;
    
 
}

.dasbo{
background-color: #F6AC79;
border-radius: 5px;

}

.grey{
color: #717372;
}
.greyh{
    color: #707070;
}

.das{
    color: #F36E35 !important;

    
    }
     .bgclr{
        background-color: #F36E35 !important;
           }
.btnh{
        
        font-size: 14px !important;
    }


    .imgbore{
        max-width: 110%;
        height: 95%;
      }
      
      .imgbo{
        max-width: 95%;
        height: 95%;
      }

      

      @media screen and (min-device-width: 1200px) and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1) {
        .imgbo{
            max-width: 100% !important ;
            height: 100% !important;
          }

          
    .imgbore{
        max-width: 100%;
        height: 100%;
      }

      .btnh{
        
        font-size: 12px !important;
    }

          .btn.btn-pri {
            background-color: #F36E35 !important; 
            opacity: 1 !important ;
            border: none;
            color: white !important; 
            cursor: pointer !important; 
            transition: color 0.3s, background-color 0.3s, font-size 0.3s !important; 
           
          
            font-size: 12px !important;
        }
        
        
        .btn.btn-pri:active,
        .btn.btn-pri:focus {
          
            color: white !important;
            font-size: 12px !important; 
        }
        
        
        .btn.btn-pri:hover {
            background-color: #4EB4E6 !important; 
            color: white !important; 
            font-size: 12px !important; 
        }
        
        
        .btn.btn-pri:disabled {
            opacity: 0.5 !important; 
            cursor: not-allowed !important; 
        }
        .btn-secondary{
            font-size: 12px !important;  
        }
      }

      .btn.btn-pr {
        background-color: #F36E35 !important; 
        opacity: 1 !important ;
        border: none;   
        color: white !important; 
        cursor: pointer !important; 
        transition: color 0.3s, background-color 0.3s, font-size 0.3s !important; 
       
      
        font-size: 14px !important;
    }

    select option.btn.btn-pr {
        background-color: grey !important;
    }
    
         
    .btn.btn-pr:active,
    .btn.btn-pr:focus {
        color: #4EB4E6!important; 
        font-size: 14px !important; 
    }
    
    
    .btn.btn-pr:hover {
        background-color: #4EB4E6 !important; 
        font-size: 14px !important; 
    }
    
    
    .btn.btn-pr:disabled {
        opacity: 0.5 !important; 
        cursor: not-allowed !important; 
    }

@media screen and (min-device-width: 600px)and (max-device-width: 1024px)and (-webkit-min-device-pixel-ratio: 1)  {

       
.imgbor {
    max-width: 100% !important;
    height: 680px !important;
  }
  .imgborallign {
    max-width: 100% !important;
    height: 680px !important;
  }
.hide_tab{
    display: none !important;
}
.tablf{
    margin-left: -70px !important;
}
.inpucent{
    margin-left: 220px !important;
}
.tabimgcent{
    margin-left: 260px !important;
}
.tabimgcentoggle{
    margin-left: 80px !important;
    margin-top: 15% !important;
}
.tabimgcen{
    margin-left: 260px !important;
    position: absolute !important;
}
.tabinpwid{
    width: 70% !important;
}
} 