/* CSS for all screen sizes */
.gbtmimg1 {
    position: absolute;
    left: 440px !important;
    margin-top: -240px !important;
    border-radius: 0px;
}

/* CSS for screens smaller than 11 inches */
@media only screen and (max-width: 768px) {
    .gbtmimg1 {
        left: auto !important;
        right: 0px !important;
        margin-top: 0px !important;
        border-radius: 0px;
    }
}

/* CSS for screens between 11 and 12 inches */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .gbtmimg1 {
        left: 20px !important;
        margin-top: -120px !important;
        border-radius: 20px;
    }
}

/* CSS for screens between 12 and 13 inches */
@media only screen and (min-width: 1024px) and (max-width: 1100px) {
    .gbtmimg1 {
        left: 50px !important;
        margin-top: -170px !important;
        border-radius: 30px;
    }
}

/* CSS for screens between 13 and 14 inches */
@media only screen and (min-width: 1100px) and (max-width: 1200px) {
    .gbtmimg1 {
        left: 120px !important;
        margin-top: -220px !important;
        border-radius: 40px;
    }
}

/* CSS for screens between 14 and 15 inches */
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .gbtmimg1 {
        left: 200px !important;
        margin-top: -270px !important;
        border-radius: 50px;
    }
}

/* CSS for screens larger than 15 inches */
@media only screen and (min-width: 1300px) {
    .gbtmimg1 {
        left: 380px !important;
    margin-top: -240px !important;
        border-radius: 60px;
    }
   
}



.bottom-dots {
    display: flex;
   margin-top: -40px;
  }
  
  .dotbtm {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    margin-right: 10px;
    margin-top: -20px !important;
    cursor: pointer;
  }
  
  .dotbtm.active {
    background-color: blue;
    width: 30px;
    border-radius: 30%;
  }
  
  .arrow {
    font-size: 30px;
    margin: 0 10px;
    cursor: pointer;
  }


  .im_hi{
    margin-top: -20px !important;
  }
  .im_he{
   position: absolute;
  top: 80px !important;
  left: 400px !important;
  
  }

  .bottombtn {
  width: 120px !important;
    margin-top: -100px;
  }
  @media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
    .bottombtn {
        
          margin-top: -40px;
        }
     .mobnextbt{
    margin-left: -150px !important;
     }   
}
