.profileimg_position{
    position: absolute;
width: 36px;
height: 36px;
left: 334px;
margin-top: 0px ;

}
.tab_pos{
    margin-left:-25px;
       font-size: 13px;
    
    }

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .profileimg_position{
    left: 294px;
   } 
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .profileimg_position{
         left: 280px;
         height: 25px !important;
         margin-top:5px !important;
    }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .profilename{
    left: 69px !important;
    top:90px !important;
   } 
}
.profilename{
    position: absolute;
width: 140px;
height: 19px;
left: 387px;


}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .profilename{
    left: 344px;
   } 
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .profilename{
        left: 314px;
        font-size: small !important;
       }
}
.activesele_1{
    width: 50% !important;
    height: 20px;
    margin-top: -5% !important;
    margin-left: 3px !important;
    font-size: small;
    background: #34BF2C !important;
    }
    .activesele_2{
        margin-left: 10px !important;
    }
    @media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
        .activesele_1{
            margin-top: -5% !important;
            margin-left: 30px !important;
            font-size: x-small;
            height: 22px !important;
            } 
            .activesele_2{
                margin-left: 30px !important;
            }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
        .activesele_1{
            margin-top: -7% !important;
            margin-left: 17px !important;
            font-size: xx-small;
            height: 15px !important;
            }
            .activesele_2{
                margin-left: 10px !important;
            }
          
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){ 
    .button_edit{
        width: 210px !important;
      
    }
}
.base_pro{
    margin-left: 20px !important;
    height: 58px !important;
width: 48px;
border-radius: 10px;
margin-top: 70px !important;



}
.chart_graph{
    margin-left:-26px !important;
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .chart_graph{
        margin-left:-15px !important;
      
        
    }
}
.statusbtn{
   margin-top:-10px !important;
    width:75px !important;
    height: 25px !important;
    font-size: x-small !important;
    background-color: #34BF2C !important;
   
}
.profiletext{
    font-size: x-small !important ;
}
.camera{
    position: absolute;
height: 100px !important;
width: 100px !important;
margin-left: 72px;
top :100px;



}
.col_data{
    margin-left:-50px !important;
}
.empsal{
margin-left: -10px !important;
}
.emp_sal{
margin-left: 278px!important;
margin-top: -66px !important;
}
.emp_sal1{
margin-left: 538px!important;
margin-top: -66px !important;
}
.emp_sal2{
margin-left: 277px!important;
margin-top: -1px !important;
}
.emp_sal3{
margin-left: 537px!important;
margin-top: -1px !important;
}
.emp_add{
position: absolute;
margin-left: 525px !important;
margin-top: -38px !important;
}
.emp_cl{
position: absolute;
margin-left: 555px !important;
margin-top: -38px !important;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .col_data{
        margin-left:-50px !important;
    }
    .col_6data{
        margin-left:-10px !important;
    }
    .negw{
        font-size: x-small;
     }
     .emp_sal{
        margin-left: 228px!important;
        margin-top: -76px !important;
        }
        .emp_sal1{
            margin-left: 498px!important;
            margin-top: -76px !important;
            }
    .emp_sal2{
margin-left: 227px!important;
margin-top: -12px !important;
}
.emp_sal3{
    margin-left: 497px!important;
    margin-top: -10px !important;
    } 
.ressalbtn{
position: absolute;
margin-left: -800px !important;
margin-top: -250px !important;
}  
.ressalbtn1{
    position: absolute;
    margin-left: -1000px !important;
    margin-top: -250px !important;
    }     
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .col_data{
        margin-left:10px !important;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
 
    .profiletext{
        font-size: 8.5px !important ;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .col_6data{
        margin-left:-10px !important;
    }
    
}

.button_edit{
    width: 253px ;
    height: 48px !important;
}
.nav-link.active{
    color: #4EB4E6 !important;
       
}
.txt_tp{

    position: relative;

    top: 15px !important;

}

.txt_tp{
    position: relative;
    top: 15px !important;
}
 /* .nav-link{
    color: #4EB4E6!important;
 } */
 .border_left{
    border-left:solid !important;
 }
 .textboxbasic_1{
    margin-left: 10px !important;
    height: 48px ;
width: 428px;
border-radius: 10px;
margin-top: 20px !important;

border: 1px solid #0080FF;
 }
 .text_basic_1{
    position: absolute;
width: 72px;
height: 15px;
left: 887px;
top: 105px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 15px;
color: #0080FF;
background-color: white;

 }

.text_basic_2{


 position: absolute;
width: 249px !important;
height: 48px !important;
left: 823px;
top: 177px;

background: #F3F3F3;
border: 1px solid #C4C4C4;
border-radius: 10px;
}
.text_basic_3{

    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 1090px;
    top: 177px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;
   }
   .text_basic_21{


    position: absolute;
   width: 249px !important;
   height: 48px !important;
   left: 823px;
   top: 247px;
   
   background: #F3F3F3;
   border: 1px solid #C4C4C4;
   border-radius: 10px;
   }
   .text_basic_31{
   
       position: absolute;
       width: 249px !important;
       height: 48px !important;
       left: 1074px;
       top: 247px;
       
       background: #F3F3F3 !important;
       border: 1px solid #C4C4C4 !important;
       border-radius: 10px !important;
      }
   .textboxbasic_4{
    position: absolute;
    width: 518px !important;
    height: 48px !important;
    left: 823px;
    top: 325px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
   }
   .textboxbasic_5{
    position: absolute;
width: 518px !important;
height: 48px;
left: 823px;
top: 403px;

background: #F3F3F3;
border: 1px solid #C4C4C4;
border-radius: 10px;
}
.textboxbasic_6{
    position: absolute;
width: 518px !important;
height: 48px !important;
left: 822px;
top: 512px;

background: #F3F3F3 !important;
border: 1px solid #C4C4C4 !important;
border-radius: 10px !important;
}
.check_box{
    position: absolute;

left: 821px;
top: 467px;


}
.btn_basic_update{
    position: absolute;
    width: 253px !important;
height: 48px;
left: 1084px !important;
top: 592px !important;

background: #0080FF;
box-shadow: 0px 4px 12px #73A2DA;
border-radius: 5px !important;
}
.btn_basic_updat{
    position: absolute;
    width: 253px !important;
height: 48px;
left: 824px !important;
top: 592px !important;

background: #0080FF;
box-shadow: 0px 4px 12px #73A2DA;
border-radius: 5px !important;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .camera{
        left: -115px;
        top:215px;
       }
       .base_pro{
        margin-left: -15px !important;
       
    margin-top: 50px !important;
    
    
    
    }
            .textboxbasic_1{
                
               margin-left: 20px !important;
               width: 280px !important;
               margin-top:50px !important;
            }
            .text_basic_1{
           left: 130px;
           top:200px;
           position: absolute;
           }
           
           .text_basic_2{
           left: 30px;
           width: 160px !important;
           }
           .text_basic_3{
               left: 190px;
               width: 160px !important;
             }
              .textboxbasic_4{
                left: 30px;
                width: 340px !important; }
              .textboxbasic_5{
                  left: 30px;
                width: 340px !important;}
           .textboxbasic_6{
             left: 30px;
            width: 340px !important;
         }
           .check_box{  
             left: 30px;
            width: 340px !important;}
           .btn_basic_update{
               position: absolute;
               width: 100px !important;
           left:180px !important;
           }
           .btn_basic_updat{
            position: absolute;
            width: 100px !important;
        left:50px !important;
        }
          
           


           .edit_ren{
            
            background-color: white !important;
            height: 158% !important;
        } 
           
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
   .camera{
    left: 120px;
   }
        .textboxbasic_1{
            
           margin-left: 30px !important;
           width: 320px !important;
        }
        .text_basic_1{
       left: 687px;
       top:150px;
       position: absolute;
       }
       
       .text_basic_2{
       left: 623px;
       width: 180px !important;
       }
       .text_basic_3{
           left: 794px;
           width: 180px !important;
         }
          .textboxbasic_4{   left: 623px;
            width: 370px !important; }
          .textboxbasic_5{   left: 623px;
            width: 370px !important;}
       .textboxbasic_6{  left: 623px;
        width: 370px !important; }
       .check_box{   left: 623px;
        width: 370px !important;}
       .btn_basic_update{
           position: absolute;
           
       left:884px !important;
       }
       
       .btn_basic_updat{
        position: absolute;
        
    left:684px !important;
    }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1){
    .text_basic_21{


        position: absolute;
       width: 249px !important;
       height: 48px !important;
       left: 713px;
       top: 247px;
       
       background: #F3F3F3;
       border: 1px solid #C4C4C4;
       border-radius: 10px;
       }
       .text_basic_31{
       
           position: absolute;
           width: 249px !important;
           height: 48px !important;
           left: 950px;
           top: 247px;
           
           background: #F3F3F3 !important;
           border: 1px solid #C4C4C4 !important;
           border-radius: 10px !important;
          }
        .textboxbasic_1{
           margin-left: 40px !important;
        }
        .text_basic_1{
       left: 807px;
       top:100px;
       position: absolute;
       }
       
       .text_basic_2{
       left: 713px;
       }
       .text_basic_3{
           left: 964px;
         }
          .textboxbasic_4{   left: 713px; }
          .textboxbasic_5{   left: 713px;}
       .textboxbasic_6{   left: 713px; }
       .check_box{   left: 713px;}
       .btn_basic_update{
           position: absolute;
           
       left:984px !important;
       }
       .btn_basic_updat{
        position: absolute;
        
    left:714px !important;
    }
       }
     
.textboxjob_1{
    position: absolute;
width: 518px !important;
height: 48px !important;
left: 821px !important;
top: 167px !important;

border: 1px solid #0080FF;
border-radius: 10px;
}
.textboxtax_1{
    position: absolute;
width: 518px !important;
height: 48px !important;
left: 821px !important;
top: 167px !important;

border: 1px solid #0080FF;
border-radius: 10px;
}
.text_job_2 {
    position: absolute;
width: 249px !important;
height: 48px !important;
left: 822px !important;
top: 245px !important;

background: #F3F3F3 !important;
border: 1px solid #C4C4C4;
border-radius: 10px !important;
}
.text_job_3 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 1090px;
    top: 245px;
    
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}
.text_job_4 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 822px;
    top: 323px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}
.text_job_5 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 1091px;
top: 323px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text_job_6 {
    position: absolute;
    width:519px !important;
    height: 48px !important;
    left: 822px;
    top: 401px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}
.text_job_7 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 1091px;
top: 401px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text__job_1{
    position: absolute;
width: 45px;
height: 15px;
left: 866px;
top: 158px !important;


font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 15px;
background-color: white !important;


color: #0080FF;

 }
 .text_acc_1{
    position: absolute;
width: 45px;
height: 15px;
left: 866px;
top: 158px !important;


font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 15px;
background-color: white !important;


color: #0080FF;

 }
 .btn_job_update{
    position: absolute;
    width: 253px !important;
height: 48px;
left: 953px !important;
top: 493px !important;

background: #0080FF;
box-shadow: 0px 4px 12px #73A2DA;
border-radius: 5px !important;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .button_edit{
        width: 150px !important;
      
    }
    
    .textboxjob_1{
        top: 250px !important;
        left: 30px !important;
        width: 340px !important;
    }
    .text_job_2 { left: 30px !important;
        width: 160px !important;
        top: 320px !important;
   }
    .text_job_3 { left: 210px !important;
        width: 160px !important;
        top: 320px !important;
    }
    .text_job_4 {left: 30px !important;
        width: 160px !important;
        top: 380px !important;
    }
    .text_job_5 {left: 210px !important;
        width: 160px !important;
        top: 380px !important;
    }
    .text_job_6 {left: 30px !important;
        width: 160px !important;
        top: 440px !important;
       }
    .text_job_7 {left: 210px !important;
        width: 160px !important;
        top: 440px !important;
   }
    .text__job_1{
        left: 80px !important;
        top: 410px !important;
     }
     .text_acc_1{

        left: 80px !important;
        top: 480px !important;
     }
     .btn_job_update{
        top: 520px !important;
       left: 80px !important;
        width: 200px !important;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    
    .textboxjob_1{
        left: 571px !important;
        width: 420px !important;
    }
    .textboxtax_1{
        left: 571px !important;
        width: 420px !important;
    }
    .text_job_2 { left: 571px !important;
        width: 200px !important;
   }
    .text_job_3 { left: 791px !important;
        width: 200px !important;
    }
    .text_job_4 {left: 571px !important;
        width: 200px !important;
    }
    .text_job_5 {left: 791px !important;
        width: 200px !important;
    }
    .text_job_6 {left: 571px !important;
        width: 200px !important;
       }
    .text_job_7 {left: 791px !important;
        width: 200px !important;
   }
    .text__job_1{
        left: 621px !important;
     }
     .btn_job_update{

       left: 691px !important;
        width: 200px !important;
    }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1){
    
    .textboxjob_1{
        left: 771px !important;
        width: 420px !important;
    }
    .textboxtax_1{
        left: 771px !important;
        width: 420px !important;
    }
    .text_job_2 { left: 771px !important;
        width: 200px !important;
   }
    .text_job_3 { left: 991px !important;
        width: 200px !important;
    }
    .text_job_4 {left: 771px !important;
        width: 200px !important;
    }
    .text_job_5 {left: 991px !important;
        width: 200px !important;
    }
    .text_job_6 {left: 771px !important;
        width: 200px !important;
       }
    .text_job_7 {left: 991px !important;
        width: 200px !important;
   }
    .text__job_1{
        left: 821px !important;
     }
     .btn_job_update{

       left: 891px !important;
        width: 200px !important;
    }
}
.textboxper_4{
    position: absolute;
    width: 518px !important;
    height: 48px !important;
    left: 823px;
    top: 325px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
   }
   .textboxper_5{
    position: absolute;
width: 518px !important;
height: 48px;
left: 823px;
top: 403px;

background: #F3F3F3;
border: 1px solid #C4C4C4;
border-radius: 10px;
}
.text_per_2 {
    position: absolute;
width: 249px !important;
height: 48px !important;
left: 822px !important;
top: 245px !important;

background: #F3F3F3 !important;
border: 1px solid #C4C4C4;
border-radius: 10px !important;
}
.text_per_3 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 1090px;
    top: 245px;
    
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}
.text_per_4 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 822px;
    top: 167px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}
.text_per_5 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 1091px;
top: 165px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text_per_6 {
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    left: 822px;
    top: 401px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}
.text_per_7 {
    position: absolute;
    height: 48px;
    width: 159px !important;
    left: 822px;
    top: 479px;
    border-radius: 10px;
    
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text_per_8 {
    position: absolute;
    height: 48px;
    width: 159px !important;
    Top : 479px;
    Left:1002px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text_per_9 {
    position: absolute;
    width: 159px !important;
height: 48px;
left: 1179px;
top: 479px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}


.text_per_91 {
    position: absolute;
    width: 159px !important;
    
height: 48px;
left: 822px;
top: 557px;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text_per_92 {
    position: absolute;
    width: 159px !important;
    height: 48px;
left: 1002px;
top: 557px;

    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}
.text_per_93 {
    position: absolute;
    width: 159px !important;
    height: 48px;
left: 1179px;
top: 557px;

    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;

}


.text__per_1{
    position: absolute !important;
width: 60px !important;
height: 15px;
left: 866px;
top: 160px ;
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 15px;
background-color: white !important;


color: #0080FF;

 }
 .btn_per_update{
    position: absolute;
    width: 253px !important;
height: 48px;
left: 1080px !important;
top: 635px !important;

background: #0080FF;
box-shadow: 0px 4px 12px #73A2DA;
border-radius: 5px !important;
}
.btn_per_updat{
    position: absolute;
    width: 253px !important;
height: 48px;
left: 823px !important;
top: 635px !important;

background: #0080FF;
box-shadow: 0px 4px 12px #73A2DA;
border-radius: 5px !important;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    
    .text_per_2 {
        left: 30PX !important;
        width: 160px !important;
        top: 395px !important;
    }
   .text_per_3 {
    left: 210PX !important;
    width: 160px !important;
    top: 395px !important;
      }
   .text_per_4 {
    left: 30PX !important;
    width: 160px !important;
    top: 325px !important;
   }
   .text_per_5 {
    left: 210PX !important;
    width: 160px !important;
    top: 325px !important;
    }
    .textboxper_4{
        left: 30PX !important;
    width: 160px !important;
    top: 460px !important;
      
    }
    .textboxper_5{
        left: 210PX !important;
    width: 160px !important;
    top: 460px !important;
      
    }
   
   .text_per_7 {
    left: 30PX !important;
    width: 160px !important;
    top: 540px !important;
    }
   .text_per_8 {
    left: 210PX !important;
    width: 160px !important;
    top: 540px !important;
   
     }
   .text_per_9 {
    left: 30PX !important;
    width: 160px !important;
    top: 610px !important;
     }
   
   
   .text_per_91 {  left: 210PX !important;
    width: 160px !important;
    top: 610px !important;
   }
   .text_per_92 {  left: 30PX !important;
    width: 160px !important;
    top: 680px !important;
     }
   .text_per_93 { left: 210PX !important;
    width: 160px !important;
    top: 680px !important;
      }
   
   
   .text__per_1{
    left: 60PX !important;
   TOP: 315px !important;
    }
    .btn_per_update{
        top: 750px !important;
        width: 100px !important;
        left: 60PX !important;
   }
   .btn_per_updat{
    top: 750px !important;
    width: 100px !important;
    left: 200PX !important;
}
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    
    .text_per_2 {
        left: 571PX !important;
        width: 210px !important;
    }
   .text_per_3 {
    left: 791PX !important;
    width: 210px !important;
      }
   .text_per_4 {
    
    left: 571PX !important;
    width: 210px !important;
   }
   .text_per_5 {
    left: 791PX !important;
    width: 210px !important;
    }
    .textboxper_4{
        left: 571PX !important;
        width: 430px !important;
      
    }
    .textboxper_5{
        left: 571PX !important;
        width: 430px !important;
      
    }
   
   .text_per_7 {
    left: 571PX !important;
    width: 135px !important;
    }
   .text_per_8 {
    left: 721PX !important;
    width: 135px !important;
   
     }
   .text_per_9 {
    left: 864PX !important;
    width: 135px !important;
     }
   
   
   .text_per_91 { left: 571PX !important;
    width: 135px !important;
   }
   .text_per_92 { left: 721PX !important;
    width: 135px !important;
     }
   .text_per_93 {left: 864PX !important;
    width: 135px !important;
      }
   
   
   .text__per_1{
    left: 601PX !important;
   
    }
    .btn_per_update{
        left: 721PX !important;
   }
   .btn_per_updat{
    left: 601PX !important;
}
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1){
    
    .text_per_2 {
        left: 671PX !important;
     }
    .text_per_3 {
        left: 937PX !important;
       }
    .text_per_4 {
        left: 671PX !important;
    }
    .text_per_5 {
        left: 937PX !important;
     }
     .textboxper_4{
        left: 671PX !important;
        
      
    }
    .textboxper_5{
        left: 671PX !important;
    
      
    }
    .text_per_7 {
        left: 671PX !important;
     }
    .text_per_8 {   left: 851PX !important;
    
      }
    .text_per_9 {
        left: 1031PX !important;
      }
    
    
    .text_per_91 {  left: 671PX !important;
    }
    .text_per_92 {left: 851PX !important;
      }
    .text_per_93 { left: 1031PX !important;
       }
    
    
    .text__per_1{
        left: 691PX !important;
    
     }
     .btn_per_update{
        left: 941PX !important;
    }
    .btn_per_updat{
        left: 671PX !important;
    }
    
}

    .text_tax_2 {
        position: absolute;
    width: 517px !important;
    height: 48px !important;
    left: 822px !important;
    top: 245px !important;
    
    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4;
    border-radius: 10px !important;
    }
  
    .text_tax_3 {
        position: absolute;
        width: 517px !important;
        height: 48px !important;
        left: 822px;
        top: 323px;
        
        background: #F3F3F3;
        border: 1px solid #C4C4C4;
        border-radius: 10px;
    
    }
    .btn_tax_update{
        position: absolute;
        width: 253px !important;
    height: 48px;
    left: 1080px !important;
    top: 423px !important;
    
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px !important;
    }
    .btn_tax_updat{
        position: absolute;
        width: 253px !important;
    height: 48px;
    left: 813px !important;
    top: 423px !important;
    
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px !important;
    }
    .btn_acc_update{
        position: absolute;
        width: 253px !important;
    height: 48px;
    left: 953px !important;
    top: 323px !important;
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px !important;
    }
    @media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
        .textboxtax_1{
            top: 420px !important;
            left: 30px !important;
            width: 340px !important;
        }
        .text_tax_2 {
            top: 490px !important;
            left: 30px !important;
            width: 340px !important;
       }
       
        .text_tax_3 {
            top: 560px !important;
            left: 30px !important;
            width: 340px !important;
        
        }
        .btn_tax_update{
            top: 630px !important;
            left: 80px !important;
            width: 100px !important;
        }
        .btn_tax_updat{
            top: 630px !important;
            left: 200px !important;
            width: 100px !important;
        }
        .btn_acc_update{
            left: 90px !important;
            width: 170px !important;
            top:640px !important;
        } 
        .textboxacc_1{
            position: absolute;
        width: 340px !important;
        height: 48px !important;
        left: 30px !important;
        top: 560px !important;
        
        border: 1px solid #0080FF;
        border-radius: 10px;
        }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
        .text_tax_2 {
           margin-left: -250px !important;
            width: 420px !important;
       }
       
        .text_tax_3 {
            margin-left: -250px !important;
            width: 420px !important;
        
        }
        .btn_tax_update{
            margin-left: -250px !important;
            width: 170px !important;
        }
        .btn_tax_updat{
            margin-left: -200px !important;
            width: 170px !important;
        }
        .btn_acc_update{
            margin-left: -250px !important;
            width: 170px !important;
        } 
    }
        @media screen and (min-device-width: 1200px)and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1){
            .text_tax_2 {
                margin-left: -50px !important;
                 width: 420px !important;
            }
            
             .text_tax_3 {
                 margin-left: -50px !important;
                 width: 420px !important;
             
             }
             .btn_tax_update{
                 margin-left: -60px !important;
                 width: 170px !important;
             }
             .btn_tax_updat{
                margin-left: -20px !important;
                width: 170px !important;
            }
            .btn_acc_update{
                margin-left: -50px !important;
                 width: 170px !important;
            }}
            .tab_pos{
                margin-left: -12px !important;
            }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
        .tab_pos{
            margin-left:-20px;
               font-size: x-small;
            
            }
    }
    @media screen and (min-device-width: 1000px)and (max-device-width: 1099px) and (-webkit-min-device-pixel-ratio: 1){
        .tab_pos{
            margin-left:-40px;
               font-size: x-small;
            
            }
    }
 
    @media screen and (min-device-width: 760px)and (max-device-width: 899px) and (-webkit-min-device-pixel-ratio: 1){
        .ressalbtn{
            position: absolute;
            margin-left: 200px !important;
            margin-top: -100px !important;
            }  
            .ressalbtn1{
                position: absolute;
                margin-left:-200px !important;
                margin-top: -100px !important;
                }   
    .col_data{
        margin-left:10px !important;
        width: 380px !important;
    }
    
    .col_6data{
        width: 100% !important;
    }
    .profilename{
        left: 30px !important;
        top:70px !important;
       } 
       .profileimg_position{
        left: 30px;
        height: 35px !important;
        margin-top:20px !important;
   }
   .camera{
    left: 15px;
  
    width: 340px !important;
    position: relative !important;
   top: -200px !important; 
   }
   .base_pro{
    margin-left: 80px !important;
   
margin-top: 50px !important;



}
        .textboxbasic_1{
            
           margin-left: 70px !important;
           width: 280px !important;
           margin-top:50px !important;
        }
        .text_basic_1{
       left: 130px;
       top:200px;
       position: absolute;
       }
       
       .text_basic_2{
        position: relative !important;
       left: 30px;
       width: 160px !important;
       margin-top: -230px !important;
       }
       .text_basic_3{
           left: 30px;
           width: 160px !important;
           position: relative !important;
           margin-top: -230px !important;
         }
          .textboxbasic_4{
            left: 30px;
            width: 340px !important;
            position: relative !important;
           top: 40px !important; 
        }
          .textboxbasic_5{
              left: 30px;
              width: 340px !important;
              position: relative !important;
             top: 90px !important; 
        }
       .textboxbasic_6{
         left: 30px;
         width: 340px !important;
         position: relative !important;
        top: 100px !important; 
     }
       .check_box{  
         left: 30px;
         width: 340px !important;
         position: relative !important;
        top: 130px !important; }
       .btn_basic_update{
           position: absolute;
           width: 140px !important;
           position: relative !important;
          top: 170px !important; 
       left:0px !important;
       }
       .btn_basic_updat{
        position: absolute;
        width: 140px !important;
        position: relative !important;
       top: 170px !important; 
    left:50px !important;
    }
       .textboxjob_1{
        position: relative !important;
        left: 1px !important;
        top:0px !important ;
        width: 420px !important;
    }
    .textboxtax_1{
        position: relative !important;
        margin-left: 1px !important;
        width: 420px !important;
    }
    .text_job_2 { 
        position: relative !important;
     left: 15px !important;
        top: 0 !important;
        width: 200px !important;
   }
    .text_job_3 { left: 25px !important;
        top: 0 !important;
        position: relative !important;
        width: 200px !important;
    }
    .text_job_4 {left: -385px !important;
        top: 60px !important;
        position: relative !important;
        width: 200px !important;
    }
    .text_job_5 {left: 228px !important;
        top: 15px !important;
        position: relative !important;
        width: 200px !important;
    }
    .text_job_6 {left: -185px !important;
        top: 75px !important;
        position: relative !important;
        width: 200px !important;
       }
    .text_job_7 {left: -170px !important;
        top: 75px !important;
        position: relative !important;
        width: 200px !important;
   }
    .text__job_1{
        left: 35px !important;
        top: -53px !important;
        position: relative !important;
     }
     .btn_job_update{
        position: relative !important;
       left: 110px !important;
     
        top: 90px !important;
        width: 200px !important;
    }
        
    .text_per_2 {
        position: relative !important;
        left: 1PX !important;
        top: 70px !important;
        width: 210px !important;
    }
   .text_per_3 {
    position: relative !important;
    left: 10PX !important;
    top: 70px !important;
    width: 210px !important;
      }
   .text_per_4 {
    position: relative !important;
    left: -420PX !important;
    top: 10px !important;
    width: 210px !important;
   }
   .text_per_5 {   position: relative !important;
    left: 220PX !important;
    top: -37px !important;
    width: 210px !important;
    }
    .textboxper_4{   position: relative !important;
        left: -210PX !important;
        top: 70px !important;
        width: 430px !important;
      
    }
    .textboxper_5{   position: relative !important;
        left: 1PX !important;
        top: 70px !important;
        width: 430px !important;
      
    }
   
   .text_per_7 {   position: relative !important;
    left: -430PX !important;
    top: 140px !important;
    width: 135px !important;
    }
   .text_per_8 {   position: relative !important;
    left: 155PX !important;
    top: 85px !important;
    width: 135px !important;
   
     }
   .text_per_9 {   position: relative !important;
    left: 165PX !important;
    top: 85px !important;
    width: 135px !important;
     }
   
   
   .text_per_91 {    position: relative !important;
    left: -270PX !important;
    top: 140px !important;
    width: 135px !important;
   }
   .text_per_92 {   position: relative !important;
    left: -250PX !important;
    top: 140px !important;width: 135px !important;
     }
   .text_per_93 {   position: relative !important;
    left: 301PX !important;
    top: 90px !important;
    width: 135px !important;
      }
   
   
   .text__per_1{
    
    position: relative !important;
    left: 1PX !important;
    top: -255px !important;
    }
    .btn_per_update{
        position: relative !important;
        left: 30PX !important;
        top: 150px !important;
   }
   .btn_per_updat{
    position: relative !important;
    left: -30PX !important;
    top: 150px !important;
}
   .textboxtax_1{
    position: relative;
    top: 420px !important;
    left: 30px !important;
    width: 340px !important;
}
.text_tax_2 {
    position: relative;
    top: 0px !important;
    left: 15px !important;
    width: 420px !important;
}

.text_tax_3 {
    position: relative;
    top: 30px !important;
    left: 15px !important;
    width: 420px !important;

}
.btn_tax_update{
    position: relative;
    top: 120px !important;
    left: 220px !important;
    width: 220px !important;
}
.btn_tax_updat{
    position: relative;
    top: 120px !important;
    left: -220px !important;
    width: 220px !important;
}
.btn_acc_update{
    position: relative;
    left: -280px !important;
    width: 170px !important;
    top:80px !important;
} 
.textboxacc_1{
    position: relative;
width: 340px !important;
left: 30px !important;
top: 560px !important;
}
}