.rightimg{
position: absolute;
left: 81.79%;
right: 1.61%;
top: 80.17%;
bottom: -4.65%;
width:239.11px !important;
height:353.06px !important;
}

.bankimg{
position: absolute;
left: 64.2%;    
}
.tabs-center{
margin-left: 27%;

}

.textbox-work{
    height: 48px;
    border: 1px solid #0080FF !important;
    border-radius: 5px;   
}
.required:after {
    content: "*";
    color: red;
  }
.inclr{
    color:red !important;
}

.txtaddr{
    height: 48px !important;
    border-radius: 10px !important;
}


.phonedrop{
    border-radius: 8px !important;
    border: 1px solid whitesmoke !important;  
}



.leftimg{
position: absolute;
left: 23.35%;
right: 60.23%;
top: 101.82%;
bottom: -0.11%;
width: 193.25px !important;
height:166.74px !important;
}

.leftimg1{
 position: absolute;
 left: 32.79%;
 top: 98%;
 width: 8% !important;
 height:30% !important;
}
.leftimg2{
    position: absolute;
    left: 31.5%;
    top: 113%;
    width: 6% !important;
    height:10% !important;
   }
.leftimg3{
    position: absolute;
    left: 21%;
    top: 113%;
    width: 6% !important;
    height:10% !important;
   }   
.leftimg4{
    position: absolute;
    left: 25.2%;
    top: 122%;
    width: 6% !important;
    height:1% !important;
   }  
   
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {



.leftimg{
left: 24.95% !important;
top:108% !important;
}

.leftimg1{
 left: 36.79% !important;
 top: 103% !important;
}
.leftimg2{
left: 35.5% !important;
top: 119% !important;
}
.leftimg3{
    left: 23% !important;
    top: 119% !important;
   }   
.leftimg4{
    left: 27%;
    top: 128% !important;
   } 


 
.rightimg{
top: 92.17% !important;
}
}
@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {



.leftimg{
left: 26.35% !important;
}

.leftimg1{
 left: 38.79% !important;
}
.leftimg2{
left: 37.5% !important;
}
.leftimg3{
left: 24% !important;
top:112.5% !important;
}   
.leftimg4{
left: 28% !important;
top:121.5% !important;
   } 


  
}   

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.mobcompy{
margin-left: -55px !important;
margin-top: 10px !important;
}

.mobtext{
margin-left: -63px !important;
font-size: 13.5px !important;
}
.mobadd{
margin-left: -205px !important;
}
.mobaddalign{
margin-left: 10px !important;
}
.leftimg{
display: none !important;
}

.leftimg1{
 display: none !important;
}
.leftimg2{
display: none !important;
}
.leftimg3{
display: none !important;
}   
.leftimg4{
display: none !important;
}
.rightimg{
display: none !important;
} 

.mobcompy1{
margin-left: -55px !important;
margin-top: 120px !important;    
}

.scroll-containercomp {
    display: flex !important; 
    overflow-x: auto !important; 
    white-space: nowrap !important; 
    -webkit-overflow-scrolling: touch !important; 
    margin-left: -100px !important;
    width: auto !important;
  }
.addlefmob{
    margin-left: -19px !important;
    width: 280% !important;
}
.taxlefmob{
    margin-left: -10px !important;
    width: 220% !important;
}
.banklefmob{
    margin-left: -30px !important;
}
.banklefmob1{
    margin-left: -12px !important;
}
.addtextmob{
    margin-left: -44px !important;
}
.textsml{
    font-size: 10px !important;
}
.topst{
    margin-top: 10px !important;
}
}


@media screen and (min-device-width: 760px)and (max-device-width: 1024px)and (-webkit-min-device-pixel-ratio: 1) {
    .tabs-center{
        margin-left: 0 !important;
        
        }
                .leftimg{
                    left: 2.35% !important;
                    top: 99% !important
                    }
                    
                    .leftimg1{
                     left: 23.79% !important;
                     top: 96% !important
                    }
                    .leftimg2{
                    left: 37.5% !important;
                    }
                    .leftimg3{
                    left:-14% !important;
                    top:92.5% !important;
                    }   
                    .leftimg4{
                    left: -11% !important;
                    top:89.5% !important;
                       } 
.rightimg{
    left: 68% !important;
                    top:85.5% !important;
    } 
   
}