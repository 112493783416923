.Bank_master{
    height: 48px !important;
    width: 349px !important;
  
}
.setbtn{
height: 48px;
}
.Master_btn{
    height: 48px !important;
width: 253px !important;
top: 280px !important;
position: absolute;
border-radius: 5px;
margin-left: 100px !important;
}
.btn-l_g{
    width: 140px!important;
}
.master_text{
margin-left: 220px !important;
}
.master_text1{
margin-left: 234px !important;
}
.master_tablesize{
width: 70% !important;
margin-left: 340px !important;
}
.textresp{
width: 355px !important;
}
.from_m{
margin-top: -12px !important;
margin-left: 50%!important;
}
.txtbx{
margin-left: 400px !important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
 .error_ad{
   margin-left: -60px !important;
    }
.error_pcode{
 margin-left: -100px !important;
    }
.master_text{
margin-left: 235px !important;
}
.master_text1{
margin-left: 206px !important;
}
.master_tablesize{
margin-left: 320px !important;
}  
.masteradd{
margin-left: 100px !important;
width: 150px !important;
}
.from_m{
margin-top: -12px !important;
margin-left: 60%!important;
    
}
.tabmast{
margin-left: 10px !important;
width: 341px !important;
}
.tabmast1{
left: 340px !important;
width: 341px !important;
}

}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
 .master_text{
margin-left: 235px !important;
}
.master_text1{
margin-left: 222px !important;
}
.master_tablesize{
margin-left: 320px !important;
}  
.masteradd{
margin-left: 70px !important;
width: 150px !important;
}
.textresp{
width: 440px !important;
}
.from_m{
    margin-top: -12px !important;
margin-left: 50%!important;
}

}
@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {
.master_text{
margin-left: -100px !important;
}
.textresp{
width: 440px !important;
left: -180px !important;
}
.masteradd{
margin-left: -130px !important;
width: 150px !important;
}
.master_tablesize{
width: 100% !important;
margin-left: -100px !important;
}
.tabmast{
margin-left: 10px !important;
width: 300px !important;
}
.tabmast1{
left: -40px !important;
width: 300px !important;
}
.from_m{
margin-top: -12px !important;
margin-left: -45%!important;
}
.tabeditcard{
width: 180% !important;
margin-left: -240px !important;
}
.tabbtn{
margin-top: 10px !important;
margin-left: 70px !important;
}
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.master_text{
margin-left: -80px !important;
}
.textresp{
width: 240px !important;
left: -250px !important;
}
.masteradd{
margin-left: -180px !important;
width: 90px !important;
}
.master_tablesize{
width:100% !important;
margin-left: -25px !important;
}
.tabmast{
margin-left: -10px !important;
width: 165px !important;
}
.tabmast1{
left: -160px !important;
width: 165px !important;
}
.from_m{
margin-top: -12px !important;
margin-left: -45%!important;
} 
.texttim{
font-size: 13px !important;
}
.tabeditcard{
width: 180% !important;
margin-left: -125px !important;
}
.tabbtn{
margin-top: 0px !important;
margin-left: 20px !important;
}
}


.error_f{
    position: absolute !important;
    left: 1px !important;
    margin-top: 60px !important;
    width: 300px !important;
    }

    .error_P{
        position: absolute !important;
        left: 600px !important;
        margin-top: 50px !important;
        width: 200px !important;
        }
        .error_name{
            position: absolute !important;
            left: 600px !important;
            margin-top: 60px !important;
            width: 200px !important;
            }
    .master_add{
      
       margin-left:490% !important;
        width: 150px !important;
        }
.error_com{
    position: absolute !important;
    left: 10px !important;
    margin-top: 58px !important;
    width: 400px !important;
}
.error_comwor{
    position: absolute !important;
    left: -10px !important;
    margin-top: 64px !important;
    width: 400px !important;
}
.error_comp{
    position: absolute !important;
    left: 10px !important;
    margin-top: 46px !important;
    width: 400px !important;
}
.error_ad{
    position: absolute !important;
    left: 640px !important;
    margin-top: 1px !important;

    }
.error_pcode{
    position: absolute !important;
    left: 900px !important;
    margin-top: -10px !important;
    width: 200px !important;
    }
.error_ph{
    position: absolute !important;
    left: 10px !important;
    margin-top: 46px !important;
    width: 300px !important;
    }
.error_ho{
    position: absolute !important;
    left: 10px !important;
    margin-top: -15px !important;
    width: 400px !important;
}
.error_s{
    position: absolute !important;
    left: 1px !important;
    margin-top: 72px !important;
    width: 300px !important;
    }