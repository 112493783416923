.bell{
    margin-left: 455px !important;
}
.profile-iicon{
margin-left: 95% !important;
margin-top: -8.5% !important;
}
.time_sheet_txt{
    position: absolute;
width: 493px;
height: 56px;
left: 140px;
top: 86px;
}
.datesmall{

width: 127px !important;
height: 26px !important;
margin-left: 665px !important;
top: 70px !important;

border: 1px solid #727272 !important;
border-radius: 6px !important;
}
.datesmall1{

width: 127px !important;
height: 26px !important;
margin-left: 53px !important;
top: 80px !important;

border: 1px solid #727272 !important;
border-radius: 6px !important;
}

.toimg{
position: absolute;
width: 14px !important;
height: 19px !important;
margin-left: 9px !important;
top: 85px !important;
}
.pdfleft{
margin-left: 75% !important;
margin-top: -7.5% !important;
}
.sendimg{
margin-left: 89% !important;
margin-top: -10% !important;
}
.silghtleft{
margin-left: 35px !important;
margin-top: 40px !important;
}
.pausebtn{
width: 125px !important;
height: 55px !important;
border-radius: 5px !important;
font-size:16px !important;
font-weight: bold !important;
}
.timecard{

height: 85px !important;


box-shadow: 0px 4px 20px #DBDBDB !important;
border-radius: 10px !important;
}
.time_card{

    height: 55px !important;
    
    top: 20px !important;
    box-shadow: 0px 4px 20px #DBDBDB !important;
    border-radius: 10px !important;
    }
.commentsize{
width: 185px !important;
height: 45px !important;
border: 1px solid #C4C4C4 !important;
background: #F3F3F3 !important;
border-radius: 10px !important;
}
.downicon{
margin-left: 55px !important;
margin-top: -22px !important;
background: #F3F3F3 !important;
}
.timesize{
width: 130px !important;
height: 45px !important;
background: #F3F3F3 !important;
border: 1px solid #C4C4C4 !important;
border-radius: 10px !important;
}
.timecal{
position: absolute !important;
margin-top: 2.5% !important;
margin-left: -3.5% !important;
}
.cardtimersize{
margin-left: 100px !important;
}
.workin{
position: absolute !important;
margin-left: -41.5px !important;
margin-top: 18px !important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
 .headeralignment{
height: 90px !important;
margin-left: 50px !important;
}
.profile-iicon{
    margin-left: 92% !important;
    margin-top: -18.5% !important;
    }
.resdropicon{
margin-top: 0px !important;
margin-left:10px !important;}
.resbell{
margin-top: -168px !important;
margin-left: 280px !important;
}
.resdot{
margin-top: -19 8px !important;
margin-left: 302px !important;
}

.timecard{

height: 85px !important;

}
.time_card{
    width: 1024px !important;
    height: 85px !important;
    left: 95px !important;
    }
.rescomment{
margin-left: -10px !important;
}
.restext{
position: absolute;
margin-left: 5.5% !important;
}
.timecal{
position: absolute !important;
margin-top: 2.79% !important;
margin-left: -2.5% !important;
}
.datesmall{
left: 900px !important;
top: 78px !important;
}
.datesmall1{
left: 1073px !important;
top: 78px !important;
}
.toimg{
left: 1039px !important;
top: 80px !important;
}
.pdfleft{
margin-left: 80% !important;
margin-top: -12.5% !important;
}
.sendimg{
margin-left: 90% !important;
margin-top: -15% !important;
}
.downicon{
margin-left: 40px !important;
}
}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
.profile-iicon{
margin-top: -18.5% !important;
}
.resdropicon{
margin-top: 80px !important;
}
.resbell{
margin-top: -198px !important;
margin-left: 220px !important;
}
.resdot{
margin-top: -198px !important;
margin-left: 258px !important;
}

.timecard{

height: 85px !important;

}
.time_card{
    width: 970px !important;
    height: 85px !important;
    left: 85px !important;
    }
.rescomment{
margin-left: -15px !important;
}
.restext{
position: absolute;
margin-left: 6% !important;
}
.timecal{
position: absolute !important;
margin-top: 3% !important;
margin-left: -2.5% !important;
}
.datesmall{
left: 850px !important;
top: 90px !important;
}
.datesmall1{
left: 1015px !important;
top: 90px !important;
}
.toimg{
left: 989px !important;
top: 93px !important;
}
.pdfleft{
margin-left: 80% !important;
}
.sendimg{
margin-left: 88% !important;
margin-top: -4% !important;
}
.downicon{
margin-left: 35px !important;
}
} 

@media screen and (min-device-width: 600px)and (-webkit-min-device-pixel-ratio: 1)  {
    .dotmv {
        display: none !important;
    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .text-none {
        display: none !important;
    }

    .date-1 {
        margin-left: -40% !important;
         margin-top: 10%; 
    }

    .date-2 {
        margin-left: -40% !important;
        margin-top: 10%;
    }

    .to-mv {
        margin-left: -55% !important;
        margin-top: -40%;
    }

    .work-mv {
        width: 600px !important;
        height: 19px;
        margin-left: -290% !important;
        margin-top: -50px !important;

       
        font-style: normal;
        font-weight: 400;
        
        line-height: 19px;
       
        color: #727272;
    }


    .dep-mv {
        margin-top:20%;
        margin-left: -70%;
         width: 100% !important; 
    }

    .pausebtn-mv {
        width: 100px;
        height: 36px;
        margin-left: 40%;
        margin-top: 260% !important;
        background: #0080FF;
        border-radius: 5px;
    }

    .card-1 {
        margin-left: -35%;
        width: 122% !important;
        height: 100px !important;
        
        top: 383px;


    }
    .card-2 {
        margin-left: -45% !important;
        width: 120% !important;
        height: 200px !important;

    }

    .indate-1 {
        margin-left: -26%;

        width: 90px !important;
    }

    .indate-2 {
        margin-left: -5%;
        width: 90px !important;
    }

    .restext-mv {
        margin-left: 5% !important;
        width: 20% !important;
    }

    .today-mv {
        margin-left: 130% !important;
    
        margin-top: 37% !important;
        width: 200%;

        height: 19px;


       
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
       


        color: #727272;
    }

    .timer-mv {
        margin-top: 40% !important;
        margin-left: -80% !important;
    }

    .dots-mv {
        margin-left: 90% !important;
       
        margin-top: -22% !important;
    }

    .dots-mv1 {
        margin-left:92% !important;
        margin-top: -25% !important;
    }

    .indate-mv1 {
        margin-left: -2% !important;
    }

    .indate-mv2 {
        margin-left: -5%;
    }

    .restext-mv1 {
        margin-left: 20% !important;
        width: 20% !important;
    }

    .timer-mv1 {
        margin-top: 10% !important;
        margin-left: -62%;
    }
    .timer-mv2 {
        margin-top: 12% !important;
        margin-left: -70%;
    }
    .today-mv2 {
        margin-left: 200%;
    
        margin-top: 35% !important;
        width: 100%;

        height: 19px;


       
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #727272;
       
    }
    .yourwork-mv {
        margin-left: -30% !important;
        height: 10%;
    }
    .work-mveiw {
        margin-left: -15%;
        margin-top: 1% !important;
    }
    .doteds {
        margin-left: 60% !important;
        margin-top: 5%;
    }
    .your-timemv {
        margin-top: -27%;
        margin-left: 40%;
        
    }
    .your-workmv {
        margin-left: -40% !important;
        margin-top: 10% !important;
    }
      
}
@media (min-width: 768px) and (max-width: 1024px){  
    .timesheet-tab {
        margin-left: -20% !important;
        width: 90% !important;
        margin-top: 50px !important;
    }
    .resbell{
        margin-top: -18px !important;
       
        }
    .bell{
        margin-left: 95px !important;
        margin-top: 25px !important;
    }
    .timesheetnone {
        display: none !important;
    }
    .lheaderdis {
        display: inline !important;
    }
    .date-tab {
        margin-left: 120% !important;
        margin-top: 40% !important;
    }
    .to-tab {
        margin-left: 60% !important;
        margin-top: -19% !important;
    }
    .date-tab1 {
        margin-left: 70% !important;
        margin-top: 40% !important;
    }
    .pdftab {
        margin-left: 110% !important;
        margin-top: -80% !important;
    }
    .send-tab{
        margin-top: -47% !important;
        margin-left: 120% !important;
    }
    .pause-tab {
        margin-top: 5% !important;
        margin-left: 2% !important;
    }
    .qulity-tab {
        width: 200% !important;
    }
    .dep-tab {
        margin-left: 3% !important;
        width: 50% !important;
    }
    .time-tabview {
        margin-left: 80% !important;
        margin-top: -10% !important;
    }
    .card1-tab {
        width: 130% !important;
        height: auto !important;
    }

    .indate1-tab {
        margin-left: 10% !important;
    }
    .addcomm-tab {
        margin-left: -4% !important;
        width: 350% !important;
    }
    .texttab-wd {
        width: 35% !important;
    }
    .indate2-tab{
        margin-left: -3% !important;
    }
    .tomob-tab{
        margin-left: 9% !important;
    }
    .dots-tab1 {
        margin-left: 95% !important;
        margin-top: -20% !important;
    }
    .cal1-tab {
        margin-left: 70% !important;
        margin-top: -2% !important;
    }
    .today1-tab{
        margin-left: 5% !important;
        margin-top: 80% !important;
    }
    .timer-tab {
        margin-left: 5% !important;
        margin-top: -2% !important;
    }
    .card2-tab {
        height: auto !important;
        width: auto !important;
    }
    .des-tab1 {
        width: 35% !important;
    }
   
    .card3-tab {
        height: auto !important;
        width: auto !important;
    }
    .card4-tab {
        height: auto !important;
        width: auto !important;

    }
    .profile-iicon{
        margin-top: 3% !important;
        margin-left: 98% !important;
        }
}



  
   
  
    .timer-display {
      background: linear-gradient(-135deg, #0048ce, #008bfd);
      padding: 40px 0;
      font-family: "Roboto mono", monospace;
      color: #ffffff;
      font-size: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      margin-bottom: 6rem;
    }
  
    .buttons-wrapper {
      display: flex;   
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      margin: 0 auto;
      
    }
  
      
  
       
      
    
  
  