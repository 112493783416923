.textbox_work{
  width: 518px;
    height: 48px;
    border: 1px solid #0080FF !important;
    border-radius: 10px;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .textbox_work{
        position: absolute;
        width: 324px !important;
          height: 48px;
          left: -85px !important;
         
      }
}
.text_size{
    font-size: small !important;
    height: 48px !important;
    width: 340px !important;
    margin-left: 425px !important;
    margin-top: 90px !important;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .text_size{
       
   margin-left: 350px !important;
   margin-top: 50px !important;
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .text_size{
        margin-top: 50px !important;
        margin-left: 340px !important;
        width: 250px !important; 
    }
}
@media screen and (min-device-width: 300px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .text_size{
    position: absolute;
        margin-left:70px !important;
        width: 250px !important; 
        top: 400px !important;
    }
}
.font_text{
 
font-size: 24px;
font-weight: 600;
line-height: 33px;
letter-spacing: 0em;
text-align: left;
margin-left: 38% !important;

}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .font_text{
       
   margin-left: 430px !important;
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .font_text{
    
        margin-left: 370px !important;
        
    }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .font_text{
    
        margin-left: 20px !important;
       
        
    }
}
.textwork{
    position: absolute;
width: 121px;
height: 16px;
left: 660px;
top: 140px;


font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
background-color: white;
color: #0080FF;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .textwork{
       top: 145px;
    left: 610px;
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .textwork{
        top: 175px;
        left: 510px;
        
    }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .textwork{
        top: 295px;
        left: 50px;
        
    }
}
.time_one{
font-size: 12px;
border-radius: 10px !important;
background-color: #F3F3F3 !important;

}
@media screen and (min-device-width: 300px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .time_one1{
        position: absolute;
            height: 48px;
width: 140px;
left: -65px !important;
top: 59px;
border-radius: 10px;
border: gainsboro !important;
        }
        .time_one2{
            position: absolute;
            height: 48px;
width: 140px;
border: none;
margin-left: -59px !important;
top: 59px !important;
border-radius: 10px;

    
            }
            .time_one3{
                position: absolute !important;
                height: 48px;
                border: none;
width: 140px !important;
left: -80px ;
top: 123px;
border-radius: 10px;

        
                }
                
                .add_com{
                    position: absolute;
                    top:550px !important;
                }
}
.workingdays{
    position: absolute;
    height: 19px;
    font-size: smaller;
width: 108px;
left: 690px !important;
top: 305px;
border-radius: 0px;

}
@media screen and (min-device-width: 300px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
   
.workingdays_mob{
    position: absolute;
    height: 19px;
    font-size: smaller;
width: 108px;
left: 200px;
top: 427px !important;
border-radius: 0px;

}
}
.time_one1{
    position: absolute;
        height: 48px;
width: 140px;
border-radius: 10px;


    }
    .time_one2{
        position: absolute;
        height: 48px;
width: 140px;
left: 180px !important;
border-radius: 10px;


        }
        
.image_work{
   
   
   position: absolute;
   margin-top: 360px;
   margin-left: 40% !important;
   position: absolute;
   height: 401.9946594238281px;
   width: 725.5438842773438px;
  
   border-radius: 0px;
   
 
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .image_work{
         margin-left: 30% !important;
      }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .image_work{
         margin-left: 35% !important;
      }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .image_work{
        margin-left: 0% !important;
     }
     .hide_all{
        display: none !important;
       

     }
}
.hide_all_pc{
    display: none !important;
   

 } 
@media screen and (min-device-width: 600px) and (-webkit-min-device-pixel-ratio: 1){
    .hide_all_pc{
        display: none !important;
       
    
     } 
     .hide_for_pc{
        display: none !important;
       
    
     } 
}
@media screen and (min-device-width: 760px)and (max-device-width: 899px){
   
.textbox_work{
    position: absolute;
    width: 324px !important;
      height: 48px;
      left: 29px !important;
     
  }
  .time_one{
    position: absolute;
        height: 48px;
width: 240px;
left: -65px !important;
top: 59px;
border-radius: 10px;
border: gainsboro !important;
    }
    .time_one2{
        position: absolute;
        height: 48px;
width: 140px;
border: none;
margin-left: -59px !important;
top: 59px !important;
border-radius: 10px;


        }
        .time_one3{
            position: absolute !important;
            height: 48px;
            border: none;
width: 140px !important;
left: -80px ;
top: 123px;
border-radius: 10px;

    
            }
            .col5_wrk{
                width: 450px !important;
                margin-left: 200px !important;
            }
            .text_size{
       width: 180px !important;
                margin-left: 250px !important;
                margin-top: 110px !important;
                 
                 }
                 .textwork{
                    top: 140px;
                 left: 220px;
                 
                 }
                 .font_text{
       
                   left: 130px !important;
                     
                     }
                     .image_work{
                        margin-left: 0% !important;
                        height: 300px !important;
                       
                     }
                     .workingdays{
                        position: absolute;
                        height: 19px;
                        font-size: smaller;
                    width: 208px !important;
                    left: 190px !important;
                    top: 305px !important;
                    border-radius: 0px;
                    
                    }
}
.btnwrk{
margin-left: -45px !important;
}