.repsmallcard {
    
    height: 218px !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 10px !important;
}
.repsmallcard:hover {
    border-color: #007bff !important;
  }

  @media screen   and (min-device-width: 600px)  and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)  {


.downtop{

    margin-top: -34px !important;
}

.timecard {
    height: 150px !important;
    box-shadow: 0px 4px 20px #DBDBDB !important;
    border-radius: 10px !important;
}

.test{

    margin-left: 22px !important;
}

.test2{

    margin-left: 32px !important;
}

.calenright{

    margin-left: 72px !important;
}
.benifits{

    margin-left: 100px !important;
}

.forgrght{

    margin-left: -44px !important;

}

.viewtop{

    margin-top: 90px !important;
}

.viewtop1{
    margin-top: 50px !important;
}

.demosize {
    width: 145% !important;
    height: 35% !important;
    margin-left: -240px !important;
}

.wanttxt {
    position: absolute !important;
    margin-left: 10px !important;
    top: 60px !important;
}

.downsize {
    width: auto !important;
    height: 480px !important;
    overflow-y: auto;
}

.settb{
    margin-left: -60px !important;
 }

 .skipleft{

    margin-left: -220px !important;
 }

 .bookleft{

    margin-left: -400px !important;
 }
.searchic{

    margin-left: 615px !important;
}


.comleft{

    margin-left: -64px !important;


}

.comleft2{

    margin-left: -44px !important;


}

.threedotrig{

    margin-left:70px !important;
}

.canleft{

    margin-left: 52px !important;
}

.clndright{

    margin-left: 263% !important;
}

.cardtab{
     
    margin-left: -400px !important;
    margin-top: 170px !important;
    
}

.signupleft{
        margin-left: -80px !important;
        
}

.rbc-button-link {
    color: #4EB4E6 !important;
    background: none !important;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-user-select: text;
    user-select: text;

}
.rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
   color:#4EB4E6 !important;
background-color: transparent !important;


    padding: 2px 5px;
 
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.rbc-event {
    
    text-align: center !important;
}
.pytextali {
    position: absolute;
    left: -8px !important;
}

.companynamesize{
    font-size: 10px !important;
}
 }
 .payment-modal {
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%; /* Adjust the width as needed */
    max-width: 600px; /* Add max-width if you want to limit the width */
    border: 1px solid;
    border-radius: 20px;
  }
  /* SuccessPage.css */

.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .success-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
    width: 20%;
  }
  
  .success-icon {
    font-size: 5em;
    color: #4caf50; /* Green color */
    margin-bottom: 20px;
  }
  
 
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .failure-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .failure-card {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .failure-icon {
    font-size: 48px;
    color: red; /* Adjust the color based on your design */
  line-height: 1; /* Remove any extra line height */
  border: none !important; /* Remove any border */
  }
  
  .text-danger {
    color: red; /* Adjust the color based on your design */
  }
  
  .btn-primary {
    background-color: #007bff; /* Adjust the color based on your design */
   
  }
  
  .btn-primary:hover {
    background-color: #0056b3; /* Adjust the color based on your design */
  }
  .loading-screen {
    width: 80px;
    height: 80px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  