.roles_text{
position: absolute !important;
width: 518px !important;
height: 48px !important;
left: 330px !important;
top: -5px !important;

border: 1px solid #0080FF !important;
border-radius: 10px !important;
}
.role_name{
position: absolute !important;
width: 68px !important;
height: 15px !important;
left: 390px !important;
top: 145px !important;

font-weight: 400 !important;
font-size: 11px !important;
line-height: 15px !important;
background-color: white !important;
color: #0080FF !important;
}
.create_nam{
margin-left: -2.5% !important;
}
.createbtn{
width: 158px !important;
height: 48px !important;
margin-left: 50px !important;
box-shadow: 0px 4px 12px #73A2DA !important;
border-radius: 5px !important;
}
.rolesalign{
margin-left: 22.8% !important;
margin-top: 3% !important;
}

@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
.create_nam{
margin-left: 1% !important;
}
.createbtn{
margin-left: 50% !important;
}
.rolesalign{
margin-left: 25% !important;
margin-top: 3% !important;
}

}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
.create_nam{
margin-left: 1% !important;
}
.createbtn{
margin-left: 70% !important;
}
.roles_text{
left: 300px !important;
}
.role_name{
left: 370px !important;
top: 144px !important;
}
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
    .rolesalign{
    margin-left: 5.8% !important;
    margin-top: 3% !important;
    width: 95% !important;
    }
    .createbtn{
    width: 108px !important;
    height: 48px !important;
    margin-left: 45px !important;
    }
    .createbtntop{
    margin-top: -15px !important;
    margin-bottom: 30px !important;
    }
    .roles_text{
    width: 218px !important;
    height: 48px !important;
    left: 8px !important;
    top: -25px !important;
    }
    .role_name{
    width: 68px !important;
    height: 15px !important;
    left: 55px !important;
    top: 150px !important;
    }
    .create_nam{
    margin-left: -25% !important;
    margin-bottom: 40px !important;
    }
    
    }   