.email_bgcolor{
    background: #0080FF;
}
.email_text{
    margin-top: 150px !important;
}

.circle1img{
    position: absolute;
    margin-top: 160px !important;
    left: 10px;
}
.email_cardsize{
    width: 460px !important;
    height: 378px !important;
    border-radius: 0% !important;
}
.email_cardsize_1{
    width: 460px !important;
    height: 350px !important;
    border-radius: 0% !important;
}
.image_email{
    margin-top:-200px !important;
   }
.email_btn{
    width: 180%;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
.res11textleft{
margin-left: 45px !important;
}

.comediemail{
    
    margin-left: -96px !important;
    top: 255px !important;
    
}
.email_bgcolor{
    background: #0080FF;
    width: 100% !important;
    height: 985px !important;
    }


}
@media screen   and (min-device-width: 1640px)   and (-webkit-min-device-pixel-ratio: 1)  {
    .comediemail{
    
        margin-left: 166px !important;
        top: 305px !important;
        
    }
}



.comediemail{
    position: absolute !important;
    width: 490px !important;
    height: 398px !important;
    left: 529.5px !important;
    top: 235px !important;
    border-radius: 0% !important;
}
.otptext{
    
    width: 318px !important;
    height: 48px !important;
    border-radius: 10px !important;
    border: 1px solid #0080FF !important;
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
    .email_bgcolor{
    background: #0080FF;
    width: 100% !important;
    height: 896px !important;
    }
    .email_text{
    margin-top: 150px !important;
    }
    
    .circle1img{
    display: none !important;
    }
    .email_cardsize{
        width: 300px !important;
        height: 378px !important;
        border-radius: 0% !important;
    }
    .emailtittpmob{
        margin-top: 30px !important;
    }
    .image_email{
    display: none !important;
    }   
    .mobemailsimply{
    margin-left: 85px !important;
    } 
    .mobemailimg{
    position: absolute !important;
    left: 39.24% !important;
    right: 36.94% !important;
    top: 36.11% !important;
    bottom: 52.37% !important;
    }
    .mobalign{
    position: absolute !important;
    left: 152.25px !important;
    top: 415px !important;   
    }
    .mobalign1{
    position: absolute !important;
    left: 42.25px !important;
    top: 485px !important;   
    }
    .mobverifybtn{
    position: absolute !important;
    width: 306px !important;
    height: 48px !important;
    left: 39px !important;
    top: 685px !important;
    
    background: #FFFFFF !important;
    border-radius: 5px !important;
    }
    
    .email_cardsize_1{
        width: 300px !important;
        height: 350px !important;
        border-radius: 0% !important;
    }
    .comediemail{
        width: 340px !important;
        height: 398px !important;
        left: 18px !important;
        top: 205px !important;
        border-radius: 0% !important;
    }
    .otptext{
    
        width: 218px !important;
        
    }
    }