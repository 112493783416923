.Your_work_box{
    box-sizing: border-box;

    position: absolute;
    width: 493px;
    height: 217px;
    left: 387px;
    top: 326px;
    
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;   
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_box{
        box-sizing: border-box;
    
        position: absolute;
        width: 393px;
        height: 197px;
        left: 357px;
        top: 306px;
        
        background: #FFFFFF;
        border: 1px solid #CECECE;
        border-radius: 5px;   
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_box{
        box-sizing: border-box;
    
        position: absolute;
        width: 353px;
        height: 197px;
        left: 327px;
        top: 306px;
        
        background: #FFFFFF;
        border: 1px solid #CECECE;
        border-radius: 5px;   
    }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_box{
        height: 67px;
        width: 320px;
        top: 314px;
        border-radius: 5px;
        
    }
}
.Your_work_text1{
    position: absolute;
width: 188px;
height: 27px;
left: 385px;
top: 94px;


font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 27px;

color: #212121;


}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text1{
        position: absolute;
    width: 188px;
    height: 27px;
    left: 357px;
    top: 94px;
    
    
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    
    color: #212121;
    
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text1{
        position: absolute;
    width: 188px;
    height: 27px;
    left: 327px;
    top: 94px;
    

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    
    color: #212121;
    
    
    }
}

.Your_work_text2{
    position: absolute;
    width: 28px;
    height: 15px;
    left: 387.5px;
    top: 154.75px;
    
 
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
       color: #727272;


}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text2{
    position: absolute;
    width: 28px;
    height: 15px;
    left: 357px;
    top: 154.75px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
       color: #727272;


}
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text2{
        position: absolute;
        width: 28px;
        height: 15px;
        left: 329px;
        top: 154.75px;
        
     
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
           color: #727272;
    
    
    }
}
.Your_work_text3{
    position: absolute;
width: 186px;
height: 19px;
left: 387.5px;
top: 169.75px;

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #0A0A0A;


}
.Your_work_ip{
    position: absolute;
width: 186px;
height: 19px;
left: 386.5px;
top: 129.75px;

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #0A0A0A;


}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text3{
        position: absolute;
    width: 186px;
    height: 19px;
    left: 357.5px;
    top: 169.75px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #0A0A0A;
    
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text3{
        position: absolute;
    width: 186px;
    height: 19px;
    left: 327.5px;
    top: 169.75px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #0A0A0A;
    
    
    }
}
.Your_work_text4{
    position: absolute;
width: 181px;
height: 15px;
left: 387.5px;
top: 189.05px;
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 15px;
background-color: white !important;



    
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text4{
        position: absolute;
    width: 181px;
    height: 15px;
    left: 357.5px;
    top: 189.05px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    
    
   
        
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text4{
        position: absolute;
    width: 181px;
    height: 15px;
    left: 327.5px;
    top: 189.05px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    
    
 
        
    }
}
.Your_work_text5{
    position: absolute;
    width: 163px;
    height: 15px;
    left: 387.5px;
    top: 225.7px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;    
    color: #727272;

    
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text5{
        position: absolute;
        width: 163px;
        height: 15px;
        left: 357.5px;
        top: 225.7px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;    
        color: #727272;
    
        
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text5{
        position: absolute;
        width: 163px;
        height: 15px;
        left: 327.5px;
        top: 225.7px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;    
        color: #727272;
    
        
    }
}

.Your_work_text6{
    
position: absolute;
width: 219px;
height: 19px;
left: 388.5px;
top: 258.7px;

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #0A0A0A;
    
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text6{
    
        position: absolute;
        width: 219px;
        height: 19px;
        left: 357.5px;
        top: 258.7px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #0A0A0A;
            
        }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text6{
    
        position: absolute;
        width: 219px;
        height: 19px;
        left: 328.5px;
        top: 258.7px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #0A0A0A;
            
        }
}
.Your_work_text7{
    position: absolute;
    width: 24px;
    height: 15px;
    left: 387.5px;
    top: 323.1px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    
    
    color: #727272;


}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text7{
        position: absolute;
        width: 24px;
        height: 15px;
        left: 357.5px;
        top: 323.1px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        
        
        color: #727272;
    
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text7{
        position: absolute;
        width: 24px;
        height: 15px;
        left: 327.5px;
        top: 323.1px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        
        
        color: #727272;
    
    
    }
}
.Your_work_text8{
    position: absolute;
    width: 110px;
    height: 19px;
    left: 388.5px;
    top: 348.1px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
     color: #0A0A0A;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text8{
        position: absolute;
        width: 110px;
        height: 19px;
        left: 358.5px;
        top: 348.1px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
         color: #0A0A0A;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text8{
        position: absolute;
        width: 110px;
        height: 19px;
        left: 328.5px;
        top: 348.1px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
         color: #0A0A0A;
    }
}
.Your_work_text9{
    position: absolute;
    width: 70px;
    height: 19px;
    left: 387px;
    top: 397px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    
    
    color: #212121;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text9{
        position: absolute;
        width: 70px;
        height: 19px;
        left: 357px;
        top: 397px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        
        
        color: #212121;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .Your_work_text9{
        position: absolute;
        width: 70px;
        height: 19px;
        left: 327px;
        top: 397px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        
        
        color: #212121;
    }
}
.work_btn{
    position: absolute;
width: 493px;
height: 56px;
left: 387px;
top: 566px;


border-radius: 5px;
}
.work_txt{
    position: absolute;
width: 493px;
height: 56px;
left: 387px;
top: 686px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .work_btn{
        position: absolute;
    width: 393px;
    height: 56px;
    left: 357px;
    top: 526px;
    
    border-radius: 5px;
    }
    .work_txt{
        position: absolute;
    width: 393px;
    height: 56px;
    left: 357px;
    top: 686px;
    
    }
    .Your_work_ip{
        position: absolute;
    width: 186px;
    height: 19px;
    left: 356.5px;
    top: 129.75px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #0A0A0A;
    
    
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .work_btn{
        position: absolute;
    width: 353px;
    height: 56px;
    left: 327px;
    top: 566px;
    
    border-radius: 5px;
    }
    .work_txt{
        position: absolute;
    width: 493px;
    height: 56px;
    left: 387px;
    top: 686px;
    }
}
@media screen and (min-device-width: 200px)and (max-device-width:599px) and (-webkit-min-device-pixel-ratio: 1){
    .work_btn{
        height: 36px;
        width: 320px;
        border-radius: 5px;
        top:550px;
        
    }
 
}
.wrk_img{
    
    height: 372.6000061035156px !important;
    width: 500px !important;
    left: 880px !important;
    top: 234px !important;
    border-radius: 0px;
    position: absolute;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .wrk_img{
    
      
        left: 850px !important;
        top: 234px !important;
        border-radius: 0px;
        position: absolute;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .wrk_img{
    
     
        left: 620px !important;
        top: 234px !important;
        border-radius: 0px;
        position: absolute;
    }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .mob_wrk_left{
    position: absolute;
        left: -350px !important;
        
         }
}
@media screen and (min-device-width: 760px)and (max-device-width: 899px) and (-webkit-min-device-pixel-ratio: 1){
    .mob_wrk_left{
    position: absolute;
        left: -350px !important;
        
         }
}