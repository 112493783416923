.card_emp {
   font-weight: bold;
   position: fixed !important;
   width: 200px !important;
   height: 58px !important;

   top: 5px !important;
   left: 300px !important;
   border: none !important;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .searchbox {

      width: 1065px !important;
   }

   .card_emp {
      font-weight: bold;
      width: 200px !important;
      height: 58px !important;
      background-color: white !important;
      top: 5px !important;
      left: 270px !important;
      border: none !important;
   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .searchbox {

      width: 740px !important;
      width: 40px;
   }

   .card_emp {
      font-weight: bold;
      position: absolute !important;
      width: 200px !important;
      height: 58px !important;
      background-color: white !important;
      top: 5px !important;
      left: 260px !important;
      border: none !important;
   }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1) {
   .searchbox {
      margin-left: 0px !important;
      width: 100% !important;
      font-size: small !important;
   }
   .searchicon{
      margin-left: 280px !important;
      margin-top: 10px !important;
      }
   .searchboxd {
      margin-left: -25px !important;
      width: 350px !important;
      font-size: small !important;
   }

   .searchicon {
      margin-left: 280px !important;
      margin-top: 10px !important;
   }

   .viewdetails {

      width: auto !important;
      height: 105px !important;

      font-size: x-small !important;
      border: none !important;
   }

   .card_todo {
      width: 97% !important;
   }
   .card_leave {
      width: 97% !important;
   }

   .card_1_txt {
      margin-left: 35px !important;
   }

   .card_1_img {
      margin-left: 50px !important;
      margin-top: -15px;
   }

   .card_graph {
      width: 350px !important;
      height: 350px !important;
      left: -30px !important;
   }

   

   .slider {
      width: 380px;
      display: flex;
      overflow-x: auto;
      margin-left: -30px !important;
   }

   .slide {
      width: 350px;
      flex-shrink: 0;

   }

  

}

.searchicon {
   margin-left: 1190px;
   margin-top: -50px !important;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .searchicon {
      margin-left: 1080px !important;
      margin-top: -60px !important;
   }
   .searchiconbiling {
      margin-left: 1000px !important;
      margin-top: -50px !important;
   }

   
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .searchicon {
      margin-left: 830px !important;
      margin-top: -60px !important;
   }

}

.viewdetails {
   box-sizing: border-box;

   position: absolute;
   width: 1180px;
   height: 85px;

   font-size: 20px;
   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;
}

.empviewdetails {
   box-sizing: border-box;

   position: absolute;
   height: 85px !important;

   font-size: 20px;
   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;
}
.empviewdetailssup {
   box-sizing: border-box;

   position: absolute;
   height: 85px !important;

   font-size: 20px;
   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .viewdetails {

      width: 1050px !important;
      height: 85px !important;


   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .viewdetails {

      width: 740px !important;
      height: 85px !important;

   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .txt_dt {
      font-size: smaller !important;
   }
}

.txt_sat {
   margin-top: -15px !important;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .txt_sat {
      margin-top: -15px !important;
      font-size: xx-small !important;
   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .txt_sat {
      margin-top: -15px !important;
      font-size: xx-small !important;
   }
}

.btn_clock {
   height: 55px;
   width: 187px;
   border-radius: 5px;
   margin-left: -20px !important;

}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .btn_clock {
      height: 50px;
      width: 134px;
      border-radius: 5px;

   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .btn_clock {
      height: 45px;
      width: 117px;
      border-radius: 5px;

   }
}

.card_1 {
   box-sizing: border-box;

   position: absolute;

   height: 156px !important;


   background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #3288C7 2.84%, #5BD6FD 37.5%, #5CB0E0 57.29%, #3085D3 95.34%);
   box-shadow: 0px 4px 20px #4B8AA8;
   border-radius: 10px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {}

.card_1_txt {
   margin-left: 125px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_1_txt {
      margin-left: 105px;
   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_1_txt {
      margin-left: 65px !important;
   }
}

.card_2 {
   box-sizing: border-box;
   position: absolute;

   height: 156px !important;


   background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #3935FE 2.84%, #85D3EC 37.5%, #A7D1F7 57.29%, #486AC0 95.34%);
   box-shadow: 0px 4px 20px #6F94D6;
   border-radius: 10px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_2 {
      height: 156px !important;
   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_2 {
      height: 156px !important;

   }
}

.card_3 {
   box-sizing: border-box;
   height: 156px !important;
   background: transparent linear-gradient(180deg, #30FAD0 0%, #4EB4E6 64%, #4EB4E6 100%) 0% 0% no-repeat padding-box;
   box-shadow: 0px 3px 15px #00000040;
   box-shadow: 0px 4px 20px #A4F4C1;
   border-radius: 10px;

}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_3 {

      height: 156px !important;


   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_3 {

      height: 156px !important;


   }
}

.card_1_img {
   margin-left: 150px;
   margin-top: -15px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_1_img {
      margin-left: 120px;
      margin-top: -15px;
   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_1_img {
      margin-left: 80px;
      margin-top: -15px;
   }
}

.card_graph {
   box-sizing: border-box;

   position: absolute;
   width: 652px;
   height: 350px !important;
   left: 60px;
   top: 20px;

   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_graph {

      width: 517px !important;
      height: 350px !important;
      left: 90px;



   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_graph {

      width: 352px !important;
      height: 350px !important;
      left: 130px;



   }
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_holiday {

      height: 350px !important;

   }
   .card_overtime {

      height: 350px !important;

   }
   .card_holidaysuper {

      height: 350px !important;

   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_holiday {

      height: 350px !important;

   }
   .card_overtime {

      height: 350px !important;

   }
   .card_holidaysuper {

      height: 350px !important;

   }
}

.card_todo {

   box-sizing: border-box;

   position: absolute;

   height: 350px;


   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;

}
.card_leave {

   box-sizing: border-box;

   position: absolute;

   height: 350px;


   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;

}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_todo {


      height: 350px !important;


   }
   .card_leave {


      height: 350px !important;


   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .card_todo {

      height: 350px !important;



   }
   .card_leave {

      height: 350px !important;



   }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
   .text_small {
      font-size: xx-small;
   }
}

.text_small {
   font-size: x-small;
}

@media screen and (min-device-width: 600px)and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
   .comediemail{
      
      height: 398px !important;
      left: 128px !important;
      top: 335px !important;
      border-radius: 0% !important;
  }
   .btn_wid{
      width: 80px !important;
  }
   .searchbox {

      width: 93.5% !important;
      font-size: small !important;
   }
   .tbl{
      margin-left: -20px !important;
   }
   .tblle{
      margin-left: -40px !important;
   }
   .searchicon {
      margin-left: 700px !important;
      margin-top: -50px !important;
   }

   .search_icon {
      margin-left: 580px !important;
      margin-top: -60px !important;
   }

   .viewdetails {

      width: 680px !important;
      height: 85px !important;

      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }

   .viewdetails_btn {

      width: 220px;
      height: 48px;
      left: 407px;
      top: 15px;

   }
   .empviewdetails {
      width: 680px !important;
      height: 85px !important;
   
      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }
   .empviewdetailssup {
      width: 680px !important;
      height: 85px !important;
   
      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }

   .card_1 {

      height: 166px !important;
      width: 110% !important;
      left: -18px !important;
   }

   .card_2 {

      height: 166px !important;
      width: 110% !important;
      left: 50px !important;

   }

   .card_3 {
      height: 166px !important;
      width: 110% !important;
      left: 30px !important;

   }

   .card_1_txt {
      margin-left: 35px !important;
   }

   .card_1_img {
      margin-left: 90px !important;
      margin-top: -15px;
   }

   .card_graph {
      width: 720px !important;
      height: 350px !important;
      left: 10px !important;
   }
   .card_overtime {

      height: 350px !important;
      width: 125% !important;
   }

   .card_holiday {

      height: 350px !important;
      width: 175% !important;
   }
   .card_holidaysuper {

      height: 350px !important;
      width: 125% !important;
      left:30px !important
   }
   .crdholtabemp {

      height: 350px !important;
      width: 180% !important;
   }

   .card_todo {
      width: 102% !important;
      height: auto !important;
      left: 25px !important;

   }
   .card_leave {
      width: 99% !important;
      height: 350px !important;
      left: 115px !important;

   }

   .btn_clock {
      height: 39px !important;
      width: 207px !important;

      margin-left: 10px !important;
      margin-top: 10px !important;

   }

   .slider {
      height: 170px !important;
      width: 750px;
      display: flex;
      overflow-x: auto;
      margin-left: -10px !important;
   }

   .slide {
      width: 750px;
      flex-shrink: 0;

   }

   .card_1_text {
      margin-left: 65px !important;
   }

   .card_emp {
      top: 5px !important;
      left: 20px !important;

   }
   .tabfltwd{
      width: 60% !important;
      margin-left: 150px !important;
   }
.tabprofimg{
   height: 30px !important;
   width: 60px !important;
}
.tabprofimg1{
   height: 40px !important;
   width: 80px !important;
}
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1) {
.empviewdetails {
   box-sizing: border-box;

   position: absolute;
   height: 105px !important;

   font-size: 20px;
   background: #FFFFFF;
   box-shadow: 0px 4px 20px #DBDBDB;
   border-radius: 10px;
}
.btn_clock {
   height: 39px !important;
   width: 151px !important;
   margin-left: 272% !important;
}

}

@media screen and  (min-device-width: 820px) and  (max-device-width: 820px) and (-webkit-min-device-pixel-ratio: 1) {
   .viewdetails {

      width: 725px !important;
      height: 85px !important;

      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }
   .empviewdetails {
      width: 725px !important;
      height: 85px !important;
   
      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }
   .empviewdetailssup {
      width: 725px !important;
      height: 85px !important;
   
      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }
   .comediemail{
      
      height: 398px !important;
      left: 158px !important;
      top: 335px !important;
      border-radius: 0% !important;
  }
}

@media screen and  (min-device-width: 1024px) and  (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
   .viewdetails {

      width: 900px !important;
      height: 85px !important;

      font-size: medium !important;
      border: none !important;
      left:-18px !important
   }
   .viewdetails_btn {

      left: 570px !important   ;


  }
  .empviewdetails {
   width: 900px !important;
   height: 85px !important;

   font-size: medium !important;
   border: none !important;
   left:-18px !important
}
.empviewdetailssup {
   width: 900px !important;
   height: 85px !important;

   font-size: medium !important;
   border: none !important;
   left:-18px !important
}
  .card_holiday {

   height: 350px !important;
   width: 135% !important;
   left: -15px !important;
}

.card_todo {
   width: 135% !important;
   height: 350px !important;
   left: 50px !important;

}
  .searchbox {

   width: 95% !important;
   font-size: small !important;
}
.searchicon {
   margin-left: 900px !important;
   margin-top: -50px !important;
}
.crdholtabemp {

   height: 350px !important;
   width: 170% !important;
}
.inpucent{
   margin-left: 310px !important;
}
.tabimgcent{
   margin-left: 310px !important;
}
.comediemail{
      
   height: 398px !important;
   left: 258px !important;
   top: 220px !important;
   border-radius: 0% !important;
}
.card_overtime {

   height: 350px !important;
   width: 115% !important;
}
.card_holidaysuper {

   height: 350px !important;
   width: 115% !important;
   left:30px !important
}

  }