.tab-sm {
    margin-left: -7%;
    height: 60vh;
    width: 100%;
}
.rbc-event{
    background-color:  #4EB4E6 !important;
    text-align: center !important;

}
.rbc-event_1{
    background-color: #db153d !important;
    text-align: center !important;

}
th,
td {
    border: 2px solid white;
    border-color: white;
} 

.sun-sm {
    background: #F1F4FF !important;
}

.mon-sm {
    background: #F7F7F7 !important;
}

.tue-sm {
    background: #F1F4FF !important;
}

.wed-sm {
    background: #F7F7F7 !important;
}

.thr-sm {
    background: #F1F4FF !important;
}

.fri-sm {
    background: #F7F7F7 !important;
}

.sat-sm {
    background: #F1F4FF !important;
}

.date-1 {
    background: #D7E4ED !important;
}

.date-2 {
    background: #F3F3F3 !important;
}

.date-3 {
    background: #F3F3F3 !important;
}

.date-4 {
    background: #F3F3F3 !important;
}

.date-5 {
    background: #F3F3F3 !important;
}

.date-6 {
    background: #F3F3F3 !important;
}

.date-7 {
    background: #F3F3F3 !important;
}

.date-8 {
    background: #0080FF !important;
}

.leave-align {
    margin-left: -6%;
    height: 55vh;
}

.date-bgrd {
    background-color: #FFE2E2 !important;
}
.nav-leaveview {
    margin-left: -10%;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .leave-web {
        margin-left: -2%;
    }

    .leavehis-web {
        margin-left: -2%;
    }

    .leavehis-web1 {
        margin-left: -1%;
    }

    .his-wd {
        margin-left: 0%;
        width: 200px;
    }
}

@media screen and (min-device-width: 800px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
    .leavehis-web {
        margin-left: -1%;
    }

    .history-web {
        margin-left: 4%;
    }

    .history-webview {
        margin-left: -1%;
    }
}

@media screen and (min-device-width: 600px)and (-webkit-min-device-pixel-ratio: 1)  {
    .tablehide {
        display: none !important;
    }
    .calhideother {
        display: none !important;
    }
    }

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .table_hide {
        display: none !important;
    }

    .extrarow {
        width: 190px !important;
        height: 19px !important;
        left: 40px !important;
        top: 200px !important;

       
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
       


        color: #212121;
    }
    .leavehis-ml {
        margin-left: 5%;
    }
    .bal-mob {
        margin-left: 1%;   
     }
     .leavemb {
        margin-top: -16% !important;
        margin-left: 2%;
     }
     .loss-mb {
        margin-left: 57%;
        margin-top: -6% !important;
     }
     .pay-num{
        margin-left: 57%;
        margin-top: -16% !important;
     }
     .earn-mb {
        margin-top: 9% !important;
        margin-left: 1% !important;
     }
     .sick-mb {
        margin-left: 57%;
        margin-top: -5% !important;
     }
     .earn-num {
        margin-left: 2%;
        margin-top: -5%;
     }
     .sick-num {
        margin-left: 57%;
        margin-top: -6% !important;
     }
     .right-ml {
        margin-left: 6%;
     }
     .calhide {
        display: none !important;
     }
     .leave1-fs {
        margin-left: -14%;
     }
     .apply-fs{
        margin-left: -7%;
     }
     .holiday1-mb {
        margin-left: -15%;
     }
     .leavelh {
        margin-left: -6%;
     }

     .nav-mview {
        margin-left: -1%;
     }

     .extrarow {
        margin-left: 1%;
        margin-top: 7% !important;
    }
    
    

   
}
@media (min-width: 768px) and (max-width: 1024px){  
    .leavelh {
        margin-left: -1%;
     }
    .leave-wdd {
        width: 90% !important;
        margin-left: 05% !important;
    }

.leavehistab {
    margin-left: -10% !important;

}
.frist-tab {
    margin-left: 20% !important;
    width: 200% !important;
}
.sec-tab {
    margin-left: 160% !important;
    width: 200% !important;
    margin-top: -55% !important;
}
.third-tab {
    margin-left: 300% !important;
    width: 200% !important;
    margin-top: -55% !important;
}
.dis-none {
    display: none !important;
}
}
