.attane_align {
    margin-left: 4%;
}
.nav-reg {
    margin-left: -10%;
}
.third-mbv {
 
    margin-left: 4%;
}
.tab-web {
    margin-left: 10%;
}

.next-align {
    margin-left: 100%;
    margin-top: -2%;

}

.next-align2 {
    margin-top: -3%;
    margin-left: 150%;
}

.month-align {
    margin-left: 10%;
}

.previous-algn {
    margin-left: -4%;
}


.att-line {
    margin-top: 10%;
}


.penalty_days {
    margin-top: -6%;
    margin-left: 25%;
}

.late_in {
    margin-left: 45%;
    margin-top: -6.2%;
}

.early_out {
    margin-top: -6.2%;
    margin-left: 62%;
}

.except_days {
    margin-top: -6%;
    margin-left: 81%;
}

.calendar {
    width: 100%;
    height: 5%;
}

.right-side {
    margin-left: 120%;
    margin-top: -80%;
}


.align-regu {
    margin-left: -15%;
    width: 90%;
}
.swipes-no {
    margin-left: -5% !important;
}
.labeltext {
    box-sizing: border-box;

    position: absolute;
    width: 249px;
    height: 48px;
    left: 1160px;
    top: 300px;

    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;

}

.form-to {
    box-sizing: border-box;

    position: absolute;
    width: 249px;
    height: 48px;
    left: 1160px;
    top: 390px;

    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.time-am {
    margin-top: 25% !important;
    margin-left: 3% !important;
}

.time-pm {
    margin-top: 16% !important;
    margin-left: 3%;
}

.comment-box {
    box-sizing: border-box;

    position: absolute;
    width: 249px;
    height: 91px;
    left: 1164px;
    top: 485px;

    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}


.apply-btn {
    position: absolute;
    width: 249px;
    height: 48px;
    left: 1164px;
    top: 600px;

    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
    border-color: #0080FF;
}

.cal-align {
    margin-left: -5%;

}

.cal-2 {
    margin-left: 35% !important;
    margin-top: -3%;


}

.cal-3 {
    margin-top: -3%;
    margin-left: 49%;

}

.cal-4 {
    margin-top: -3%;
    margin-left: 63%;
}

.cal-6 {
    margin-left: 78%;
    margin-top: -6%;
}

.cal-7 {
    margin-left: 93%;
    margin-top: -4%;
}

.cal-8 {
    margin-top: 6%;
    margin-left: 35%;
}

.cal-9 {
    margin-left: 48%;
    margin-top: -3%;
}

.cal-10 {
    margin-left: 62%;
    margin-top: -3%;
}

.cal-11 {
    margin-top: -3%;
    margin-left: 77%;
}

.cal-12 {
    margin-left: 20%;
    margin-top: 5%;
}

.cal-13 {
    margin-left: 35%;
    margin-top: 5;
}

.cal-14 {
    margin-left: 50%;
    margin-top: -6%;
}

.cal-15 {
    margin-left: 62%;
    margin-top: -3%;
    font-size: 2%;
}

.cal-16 {
    margin-left: 78%;
    margin-top: -3%;
}

.cal-17 {
    margin-left: 93%;
    margin-top: -3%;
}

.cal-18 {
    margin-left: 20%;
    margin-top: 5%;
}

.cal-19 {
    margin-left: 35%;
    margin-top: -3%;
}


.tab-align {
    margin-left: 2%;
    height: 45vh;
    margin-top: 20%;
}

.sun-sm {
    background: #F1F4FF !important;
}

.mon-sm {
    background: #F7F7F7 !important;
}

.tue-sm {
    background: #F1F4FF !important;
}

.wed-sm {
    background: #F7F7F7 !important;
}

.thr-sm {
    background: #F1F4FF !important;
}

.fri-sm {
    background: #F7F7F7 !important;
}

.sat-sm {
    background: #F1F4FF !important;
}

.date-1 {
    background: #D7E4ED !important;
    font-weight: normal !important;
}

.date-2 {
    background: #F3F3F3 !important;
}

.date-3 {
    background: #F3F3F3 !important;
}

.date-4 {
    background: #F3F3F3 !important;
}

.date-5 {
    background: #F3F3F3 !important;
}

.date-6 {
    background: #F3F3F3 !important;
}

.date-7 {
    background: #F3F3F3 !important;
}

.date-8 {
    background: #0080FF !important;
}

.pres-sm {
    margin-left: 40%;
}

.frist-row {

    width: 100px;
    height: 40px;
}

.second-sm {
    width: 100px;
    height: 40px;
}

.second-row {
    width: 100px;
    height: 35px;
}

.pres2-sm {
    margin-left: 40%;
}

.pres3-sm {
    margin-left: 40%;
}

.date-frist {
    background: #FBECB1 !important;
}

.row-4 {
    width: 100px;
    height: 65px;
}

.pres4-sm {
    margin-left: 40%;
}
.living-ml {
    margin-left: 2%;
}
.bg-tab {
    border: 2px solid white;
    border-color: white;
    border-radius: 10px solid !important;

}

.feild-nv {
    margin-top: -5%;
    margin-left: -1%;
}

.border-red {
    border: red solid 1px !important;
    display: block;
}

.dis-none {
    display: none !important;
}
.time-mb {
    margin-top: 18%;
    margin-left: 01%;

}
.time-mb2 {
    margin-top: 21%;
    margin-left: 0%;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .avg-mb {
        margin-left: 5%;
    }

    .abs-mb {
        margin-left: 7%;
        margin-top: -9%;
    }

    .table-14 {
        margin-left: 8%;
    }

    .abs-mb2 {
        margin-left: 7%;
    }

    .late-mb {
        margin-top: -13%;
        margin-left: 9%;
    }

    .late-mb2 {
        margin-left: 9%;
    }

    .early-mb {

        margin-left: 65%;
        margin-top: -7%;
    }

    .earlly-mb {
        margin-left: -15%;
        margin-top: 4%;
    }
    .living-ml {
        margin-left: 5%;
    }
    .except-mb {
        margin-left: 80%;
        margin-top: -7%;
    }

    .table-mb {
        margin-top: 4%;
        margin-left: 8%;
    }

    .reg-mb {
        margin-left: -2%;
    }

    .reg-mb2 {
        margin-left: 9%;
    }

    .reg3-mb {
        margin-left: -1%;
    }

    .fromdate-mb {
        margin-left: -10%;
    }

    .time-mb {
        margin-top: 21%;
        margin-left: 5%;

    }

    .time-mb2 {
        margin-top: 21%;
        margin-left: 4%;
    }

    .ellipse-mt {
        margin-top: 100%;
    }

    .dis-none {
        display: none !important;
    }


}

@media screen and (min-device-width: 600px)and (-webkit-min-device-pixel-ratio: 1) {
    .view-none {
        display: none !important;
    }

    .diff-no {
        display: none !important;
    }
}

@media screen and (min-device-width: 800px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
    .table-mt {
        margin-left: 8% !important;
    }


    .work-web {
        margin-left: 20%;
    }

    .days-web {
        margin-left: 25%;
        margin-top: -20%;
    }

    .web-col {
        margin-left: -30%;
    }

    .reg-web {
        margin-left: 12%;
    }

    .reg-web1 {
        margin-left: 15%;
    }

    .regular-mb {
        margin-left: -1.2%;

    }

    .avg-ml {
        margin-left: 10%;
        margin-top: 20%;
    }

    .avg-mleft {
        margin-left: 10%;
    }

    .abse-ml {
        margin-left: 15%;
        margin-top: -10%;
    }

    .abse-mleft {
        margin-left: 10%;
        margin-left: 15%;
    }

    .late-ml {
        margin-left: 15%;
        margin-top: -14%;
    }

    .late-mleft {
        margin-left: 15%;
    }

    .early-mt {
        margin-top: -7.5%;
    }

    .table-ml {
        margin-left: 10%;
        margin-top: 3%;
    }

    .table-mleft {
        margin-left: 9%;
    }

    .col-ml {
        margin-left: -5%;
    }

    .reg-ml {
        margin-left: 5%;
    }

    .reg-mleft {
        margin-left: -1%;
        width: 10%;
    }

    .feild-ml {
        margin-left: -15%;
    }

    .living-ml {
        margin-left: 6%;
    }

    .dis-none {
        display: none !important;
    }

}



@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .mob-none {
        display: none;
    }
    .attlh {
    margin-left: -6% !important; 
  
    }

    .mob-reg {
        margin-left: 5%;
    }

    .mob-feild {
        margin-left: -292% !important;
        margin-top: 35%;
        width: 80%;
    }

    .time-mv {
        margin-top: -15% !important;
        margin-left: -1% !important;
    }

    .time-mobile {
        margin-top:-17% !important;
        margin-left: -1% !important;
    }

    .diff-no {
        margin-left: -2% !important;
        margin-top: 10%;
    }

    .diff-num {
        margin-top: 10% !important;
        margin-left: -100% !important;
    }

    .dis-no {
        display: none;
    }

    .nav-mob {
        margin-left: 10% !important;}
       
    

    .nav-mob1 {
        margin-left: -45% !important;
      
    }
    .nav-att {
     
        width: 105% !important;
    }

    .atthl1 {
        margin-left: -15% !important;
    }

    

    .third-mbv {
        width: 50% !important;
        margin-left: 10%;
    }

    .card-ml {
        margin-left: -3%;
    }

    .mb-non {
        display: none !important;
    }

    .swipe-mob {
        box-sizing: border-box;

        position: absolute;
        width: 300px;
        height: 123px;
        left: -30px !important;

        top: 15px;

        background: #FFFFFF;
        box-shadow: 0px 4px 20px #DBDBDB;
        border-radius: 10px;
    }

    .inout-mt {
        margin-top: 8%;
    }

    .inmob {
        margin-top: -8%;
        margin-left: 70%;
    }

    .timemob {
        margin-top: -10% !important;
        margin-left: 70%;
    }

    .swtime-mt {
        margin-top: 9%;
    }

    .days-cal {
        box-sizing: border-box;

        position: absolute;
        width: 300px;
        height: 145px !important;
        left: -30px;
        top: 50px;

        background: #FFFFFF;
        box-shadow: 0px 4px 20px #DBDBDB;
        border-radius: 10px;
    }

    .days-mb {
        position: absolute;
        width: 253px;
        height: 19px;
        left: 30px;
        top: 600px !important;

      
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;

        color: #727272;


    }

    .leave-ml {
        position: absolute;
        width: 253px;
        height: 19px;
        left: 30px;
        top: 620px;

        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;

        color: #727272;
    }

    .half-ml {
        position: absolute;
        width: 217px;
        height: 19px;
        left: 19px;
        top: 650px;

    
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;

        color: #727272;
    }

    .half-num {
        position: absolute;
        width: 217px;
        height: 19px;
        left: 229px;
        top: 650px;

        
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;

       

    }

  

    .pen-mb {
        position: absolute;
        width: 66px;
        height: 15px;
        left: 127px;
        top: 12px;

     
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #727272;
    }

    .pen-num {
        margin-top: 10px !important;
        margin-left: 47%;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
    }

    .avg-mob {
        position: absolute;
        width: 99px;
        height: 15px;
        left: 7px;
        top: 10px;

       
        font-style: normal;
        font-weight: 400;
        font-size: 13px; 
        line-height: 15px;
        


        color: #727272;
    }

    .avg-num {
        position: absolute;
        width: 40px;
        height: 27px;
        left: 10px;
        top: 23px;

       
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;

        color: #212121;

    }

    .early-mob {
        position: absolute;
        width: 48px;
        height: 15px;
        left: 220px;
        top: 14px;

       
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
       


        color: #727272;
    }

    .early-num {
        position: absolute;
        width: 23px;
        height: 27px;
        left: 230px;
        top: 23px;


        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;

        color: #212121;
    }

    .late-mob {
        position: absolute;
        width: 35px;
        height: 15px;
        left: 17px !important;
        top: 70px;

     
        font-style: normal;
        font-weight: 400;
        font-size: 11.5px;
        line-height: 15px;
      


        color: #727272;

    }

    .exce-mob {
        position: absolute;
        width: 78px;
        height: 15px;
        left: 180px;
        top: 70px;

        
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
       


        color: #727272;
    }

    .exec-num {
        position: absolute;
        width: 23px;
        height: 27px;
        left: 179px;
        top: 80px;

      
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;

        color: #212121;

    }

    .late-num {
        position: absolute;
        width: 23px;
        height: 27px;
        left: 20px;
        top: 80px;

     
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;

        color: #212121;

    }
}
@media (min-width: 600px) and (max-width: 1024px){
    .attcaltb{
       margin-left: 100px !important;
       width: 100% !important;
    }
        .tabheader {
        margin-top: 10% !important;
        margin-left: 0% !important;
    }
    .timri{
        
        font-size: 29px !important
    }
    .tabnone {
        display: none !important;
    }
    .tab-nav {
        margin-left: -25% !important;
        margin-top: -1% !important;
     
    }
    .tabavg {
        margin-left: -30% !important;
        width: 40% !important;
    }
    .tabnumavg {
        margin-left: -30% !important;
    }
    .avgabs {
        margin-left: -2% !important;
        width: 40% !important;
        margin-top: -16% !important;
    }
    .tablate {
        margin-top: -25% !important;
        margin-left: 20% !important;
    }
    .tabnulate {
        margin-left: 20% !important;
    }
    .earlytab {
        margin-left: 80% !important;
        margin-top: -13% !important;
    }
    .exceptab {
        margin-left: 110% !important;
        margin-top: -13% !important;
    }
    .table-tab {
        margin-left: -35% !important;
        margin-top: -5% !important;
        width: 180% !important;
        height: 10% !important;
        }
        .swipetab {
            margin-top: 400% !important;
            margin-left: 7% !important;
            width: 50% !important;
        }
        .navregtab {
            margin-left: -20% !important;
            margin-top: -2% !important;
        }
        .reg-tab {
            margin-left: -30% !important;
            width: 180% !important;
            margin-top: -10% !important;
        }
        .regtab {
            margin-top: 390% !important;
            margin-left: -310% !important
        }
        .regtimereg {
           
            margin-left: -310% !important;
        }
        .tabtext {
            margin-left: -160% !important;
            display:inline !important;
            margin-top: 20% !important;
          
        }
        .tabtextnum{
            margin-left: -320% !important;
        }
        .tabellise {
            margin-left: -310% !important;
        }
     .feild1-tab {
        margin-left: 500% !important;
        margin-top: 10% !important;
        display: inline !important;
     }
     .pre-tab {
        margin-left: -35% !important;
     }
     .july-tab {
        margin-left: 40% !important;
     }
     .next-tab{
        margin-left: 120% !important;
     }
     .nav-tabview {
    margin-left: -40% !important; 
    width: 300% !important;
        
     }
     .att-tab {
        margin-left: -80% !important;
     }
     .labeltext {
  
     width: 249px;
     height: 48px;
     left: 460px;
     top: 750px !important;

 
 }
 
 .form-to {
    
     width: 249px;
     height: 48px;
     left: 460px;
     top: 860px;
 
 }
 .comment-box {
    width: 249px;
    height: 91px;
    left:460px;
    top: 965px;

}


.apply-btn {
   
    height: 48px;
    left:460px;
    top:1100px;

}
.time-am {
    margin-top: -115% !important;
    margin-left: -63% !important;
}

.time-pm {
    margin-top: 49% !important;
    margin-left: -63%;
}
    }