@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.mobpdficon{
margin-left: -60px !important;
}
.mobsendicon{
margin-left: 50px !important;
}
}
@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {

.taskcard {
    width: 730px !important;
    left: -50px !important;

}

}
.scrl{
    overflow: hidden !important;
}
.comedi{
position: absolute !important;
top: -1px !important;
background-color: white !important;
}