@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.appr-mv {
    margin-top: -20% !important;
}
.reje-mv{
    margin-top: -30% !important;
    margin-left: 5% !important;
}
.back-mv {
    margin-top: -10% !important;
}
}
