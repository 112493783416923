.profile_icon{
    margin-left: 250px !important;
}
.profile_icon1{
    margin-left: 720px !important;
}
.headeralignment{
     height: 90px !important;
}
.headerposition{
    margin-top: -2% !important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
    .profile_icon{
        margin-left: 55px !important;
    }
    .profile_icon1{
        margin-left: 500px !important;
    }
    }

    @media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
        .profile_icon{
            margin-left: 15px !important;
        }
        }

        .affix {
            top: 0;
            width: 100%;
            z-index: 9999 !important;
          }
        
          @media screen   and (min-device-width: 600px)  and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)  {
            .headerposition{
                margin-left: -11.5% !important;
            }
            .headerposition_i{
                margin-left: -11% !important;
            }
            .profile_icon{
                margin-left: 290px !important;
                position: absolute;
            }
            .dropdown{
                position: absolute !important;
                        }
            .hide_tab{
                display: none !important;
            }
            .mobimgcomp{
                width: 193px !important;
                height: 80px !important;
                margin-top: -9% !important;
                margin-left: 5% !important;
            }
            .extend-border-md {
                width: 140% !important;
              }
                     
        }
        @media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
            .mobeheadali{
                margin-left: -120px !important;

            }
            .headeralignment{
                height: 66px !important;
           }
           .mobimgcomp{
            width: 173px !important;
            height: 60px !important;
            margin-top: -13% !important;
            margin-left: 4% !important;
        }
        }
        