.belpos {
    position: absolute;
    margin-left: 240px !important;
}
.searchboxcrm{
    width: 96% !important;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .belpos {
        margin-left: 50px !important;
    }
}
.sprender{
    position:absolute !important;
    top: 02px !important;
}
.available {
    color: blue;
  }
  
  .unavailable {
    color: gray;
  }
  
  
  
  .my_mod {
   margin-left: -100px !important;
    width: 750px !important;
    background-color: white !important;

  }
  
