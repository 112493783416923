

.saltextbox{
    width: 249px !important;
    height: 48px;
    margin-left: 50% !important; 
    top: 100% !important;  
    border-radius: 10px !important;
    border: 1px solid gray !important;
}
.saltext_box{
    position: absolute;
    width: 170px;
    height: 10px;
    left: 590px;
    top: 200px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: gray;
}
.saltextalign{
    margin-left: -60px !important;
}
.saltexta{
    margin-left: 280px !important;
}
.svbtn{
  
    margin-left:220px !important;
    margin-top:-75px !important;
}
.saltexta1{
    margin-top: 65px !important;
    width: 100px !important;
}
.saltexta2{
    margin-top: 65px !important;
    margin-left: 280px !important;
}
.saladd{
position: absolute;
margin-left: 855px !important;
margin-top: -45px !important;
}
.saladd1{
width: 220px !important;
height: 48px !important;
margin-left: 330px !important;
margin-top:55px!important;
}
.saladd2{
    width: 220px !important;
    height: 48px !important;
    margin-left: 30px !important;
    margin-top:55px!important;
    }
.clspos{
position: absolute !important;
margin-left: 900px !important;
margin-top: -45px !important;
}
.saldr{
margin-top: 20px !important;
}
.saldr1{
margin-top: -30px !important;
margin-left: -60px !important;
}
.labal{
margin-top: 68px !important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
.textbox1-personal{
left:295px !important;
}
.text_personal{
left: 580px !important;
}
.datepersonal{
margin-left: 2% !important;
}
.personalleft{
margin-left: 57.5% !important;
}
.address1{
margin-left: 41.5% !important;
}
.perphno{
    margin-left: -1003px !important;
    margin-top: 65px !important;
} 
.text3{
margin-left: 88% !important;  
}
.locwid{
margin-left: 88% !important; 
}
.texts3{
margin-left:31.5% !important;   
}
.locwid1{
    margin-left:57% !important; 
}
.textts3{
margin-left:-24.5% !important;   
}
.personaltwobtn{
margin-left:305px !important;
}
.basebotimg1{
left: 620px !important; 
 
}
.saltextalign{
margin-left: -20px !important;
}
.saldr1{
margin-top: -28px !important;
margin-left: -20px !important;
}
.saltext_box{
left: 530px;    
}
.saladd{
margin-left: 805px !important;
margin-top: -40px !important;
}
.clspos{
margin-left: 850px !important;
margin-top: -41px !important;
}
.saladd1{
margin-left: 275px !important;
margin-top:55px!important;
}


}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
.textbox1-personal{
left:280px !important;
}
.text_personal{
left: 550px !important;
}
.datepersonal{
margin-left: 4.5% !important;
}
.personalleft{
margin-left: 57.5% !important;
}
.address1{
margin-left: 41% !important;
}
.text3{
margin-left: 88% !important;  
}
.texts3{
margin-left:34.5% !important;   
}
.textts3{
margin-left:-19.5% !important;   
}
.personaltwobtn{
margin-left:290px !important;
}
.basebotimg1{
left: 570px !important;   
}
.saladd{
margin-left: 785px !important;
margin-top: -40px !important;
}
.clspos{
margin-left: 840px !important;
margin-top: -41px !important;
}
.saladd1{
margin-left: 245px !important;
margin-top:55px!important;
}
.saltext_box{
left: 500px;    
}
.saltextalign{
margin-left: -10px !important;
}
.saldr1{
margin-top: -28px !important;
margin-left: -10px !important;
}
}    

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.textbox1-personal{
width: 324px !important;
height: 48px !important;
left: -55px !important;
top: 1px !important;

border-radius: 10px !important;
}
.text_personal{
left: 80px !important;
top: 420px !important;
}
.datebirth{
margin-left: -10px !important;
margin-top: 60px !important;
}
.datepersonal{
position: absolute;
width: 324px !important;
height: 48px !important;
left: 43px !important;
top: 490px !important;
}
.mobtop{
margin-top: 135px !important;
}
.personalleft{
position: absolute !important;
width: 324px !important;
height: 48px !important;
left: -192px !important;
top: 555px !important;
}
.address1{
position: absolute !important;
width: 324px !important;
height: 48px !important;
left: -134px !important;
top: 685px !important;   
}
.mobtop1{
margin-top: 65px !important;
}
.text3{
position: absolute !important;
width: 324px !important;
height: 48px !important;
left: -315px !important;
top: 815px !important;     
}
.texts3{
position: absolute !important;
width: 324px !important;
height: 48px !important;
left: -72px !important;
top: 880px !important;      
}
.textts3{
position: absolute !important;
width: 324px !important;
height: 48px !important;
left: 168px !important;
top: 945px !important;          
}
.mobtop2{
margin-top: 500px !important;
margin-left: -40px !important;
width: 324px !important;
}
.mobtop21{
width: 324px !important;
margin-top: 565px !important;
margin-left: -150px !important;
}
.mobtops21{
width: 324px !important;
margin-top: 630px !important;
margin-left: -305px !important;
}
.personaltwobtn{
position: absolute;
width: 150px !important;
height: 48px !important;
left: -320px !important;
top: 1225px !important;
border-radius: 5px !important;
}
.personaltwobtn1{
position: absolute;
width: 150px !important;
height: 48px !important;
left: 220px !important;
top: 1225px !important;
border-radius: 5px !important;
}
.basebotimg1{
display: none !important;
}


.saltextbox{
    width: 160px !important;
    margin-left: -45% !important; 
    top: 100% !important;  
}
.saltext_box{
    position: absolute;
    width: 70px;
    height: 16px;
    left: 30px;
    top: 428px;
}
.saldr{

width: 160px !important;
margin-top: 18px !important;
margin-left: -45.5% !important;
}

.saltextalign{
position: absolute;
width: 155px !important;
margin-left: -55px !important;
}
.saltexta{
margin-left: 175px !important;
}
.saldr1{

width: 155px !important;
margin-left: -55px !important;
margin-top: -30px !important;
}
.saltexta2{
margin-top: 65px !important;
margin-left: 175px !important;
}
.saladd1{
width: 325px !important;
height: 48px !important;
margin-left: -60px !important;
margin-top:55px!important;
}
.saladd{
position: absolute;
margin-left: 265px !important;
margin-top: -40px !important;
}
.clspos{
position: absolute !important;
margin-left: 290px !important;
margin-top: -40px !important;
}

}

@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {

   .textbox1-personal{
        left:80px !important;
        }
        .text_personal{
        left: 140px !important;
    top: 180PX !important;    
    }
        .datepersonal{
        margin-left: 12.5% !important;
        }
        .personalleft{
        margin-left: 31.5% !important;
        }
        .address1{
        margin-left: 11% !important;
        }
        .text3{
        margin-left: 49% !important;  
        }
        .texts3{
        margin-left:34.5% !important;   
        }
        .textts3{
        margin-left:12.5% !important;   
        }
        .personaltwobtn{
        margin-left:120px !important;
        width: 220px !important;
        }
        .perphno{
            margin-left: -541px !important;
            margin-top: 65px !important;
            width: 519px !important;
        } 
        .basebotimg1{
        left: 150px !important;   
        }
        .personaltwobtn1{
            width: 220px !important;
            height: 48px !important;
            margin-left:0px !important;
            box-shadow: 0px 4px 12px #73A2DA;
            border-radius: 5px;
        }
        .locwid{
            margin-left: 48% !important; 
            }
        .locwid1{
        margin-left: 78% !important; 
        }
.saltextbox{
    width: 249px !important;
    margin-left: 50% !important; 
    top: 100% !important;  
}
.saltext_box{
    position: absolute;
    width: 70px;
    height: 16px;
    left: 210px;
    top: 190px;
}
.saldr{

width: 185px !important;
margin-top: 18px !important;
margin-left: 50.5% !important;
}

.saltextalign{
position: absolute;
width: 185px !important;
margin-left: 40px !important;
}
.saltexta{
margin-left: 215px !important;
}
.saldr1{

width: 185px !important;
margin-left: 40px !important;
margin-top: -30px !important;
}
.saltexta2{
margin-top: 65px !important;
margin-left: 200px !important;
}
.saladd1{
width: 395px !important;
height: 48px !important;
margin-left: 150px !important;
margin-top:55px!important;
}
.saladd{
position: absolute;
margin-left: 555px !important;
margin-top: -40px !important;
}
.clspos{
position: absolute !important;
margin-left: 600px !important;
margin-top: -40px !important;
}
}