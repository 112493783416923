
.pointer_{
    cursor: pointer !important ;
 }
.companyname{
    margin-left: -90px !important;
}
.companynamesize{
    font-size: 13px !important;
}
.activesele{


background: #34BF2C !important;
}
.pencilimg{
    margin-left: -50px!important;
}

.tabcardsize{
    width: 450px !important;
    height: 48px !important;
    
    background: #0F2951 !important;
    border-radius: 5px !important; 
}
.clockimg{
position: absolute;
left: 25.1%;
right: 67.88%;
top: 15.24% !important;
bottom: 55.64% !important;
}
.btn1cancel{
position: absolute;
width: 253px;
height: 48px;
left: 945px;
top: 800px;
border-radius: 5px;
}
.btn2edit{
position: absolute;
width: 253px;
height: 48px;
left: 1220px;
top: 800px;
box-shadow: 0px 4px 12px #73A2DA;
border-radius: 5px;
}
.btn2cancel{
    position: absolute;
    width: 253px;
    height: 48px;
    left: 955px;
    top: 390px;
    border-radius: 5px;
    }
.btn3edit{
    position: absolute;
    width: 253px;
    height: 48px;
    left: 1220px;
    top: 390px;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
    }
 ._textwork{
    position: absolute;
    width: 518px;
    height: 48px;
    border: 1px solid #0080FF !important;
    border-radius: 5px;
    left:-80px !important;

}
.textwork_{
    position: absolute;
    width: 50px;
    height: 16px;
    left: 990px;
    top: 150px;
    
   
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
}
.btn2top{
    margin-top: -65px !important;
} 
.tabtext1{
    margin-left: 340px !important;
} 
.tabphonetxtbox{
    margin-left: -620px !important;
} 

.company_btn {
    width: 250px !important;
    height: 48px !important;
    border-radius: 5px;
    margin-top: 100px !important;
    margin-left: -15px!important;
}
.company_btn1 {
    width: 250px !important;
    height: 48px !important;
    border-radius: 5px;
    margin-top: 100px !important;
    margin-left: 75px !important;
    box-shadow: 0px 4px 12px #73A2DA !important;
}
.textwork1_{
    position: absolute;
    width: 150px;
    height: 16px;
    left: 975px;
    top: 150px;
   
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
}
.bankborder{
    border: 1px solid #0080FF !important;
    border-radius: 5px; 
}
.textwork2_{
    position: absolute;
    width: 100px;
    height: 16px;
    left: 975px;
    top: 180px;
   
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
}


@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {

.companyname{
margin-left: -70px !important;
}
.btn1cancel{
width: 223px !important;
height: 48px !important;
left: 860px !important;
top: 820px !important;
}
.btn2edit{
width: 223px !important;
height: 48px !important;
left: 1105px !important;
top: 820px !important;
}
._textwork{
width: 488px;
height: 48px;
left:-108px !important;
}
.textwork_{
left: 900px !important;    
}
.tabtext1{
margin-left: 283px !important;
width: 490px !important;
} 
.tabphonetxtbox{
margin-left: -600px !important;
width: 490px !important;
} 
.company_btn {
width: 230px !important;    
margin-left: -40px!important;
}
.company_btn1 {
width: 230px !important;
margin-left: 60px !important;
}
.btn2cancel{
width: 223px !important;
left: 860px !important;
top: 390px !important;   
}
.btn3edit{
width: 223px !important;
left: 1105px !important;
}
.textwork1_{
left: 900px !important;
top: 150px !important;
}
.textwork2_{
left: 900px !important;
top: 180px !important;
}
}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {

.companyname{
margin-left: -70px !important;
}
.btn1cancel{
width: 212px !important;
height: 48px !important;
left: 800px !important;
top: 820px !important;
}
.btn2edit{
width: 212px !important;
height: 48px !important;
left: 1030px !important;
top: 820px !important;
}
._textwork{
width: 455px;
height: 48px;
left:-108px !important;
}
.textwork_{
left: 840px !important;    
}
.tabtext1{
margin-left: 265px !important;
width: 457px !important;
} 
.tabphonetxtbox{
margin-left: -570px !important;
width: 457px !important;
} 
.company_btn {
width: 218px !important;    
margin-left: -42px!important;
}
.company_btn1 {
width: 218px !important;
margin-left: 50px !important;
}
.btn2cancel{
width: 212px !important;
left: 800px !important;
top: 390px !important;   
}
.btn3edit{
width: 212px !important;
left: 1030px !important;
}
.textwork1_{
left: 850px !important;
top: 168px !important;
}
.textwork2_{
left: 850px !important;
top: 197px !important;
}
} 
@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.activesele{
margin-left: 22px !important;
}
.tabcol-sec {
    margin-left: -5% !important;
    width: auto !important;
}
.companyname{
margin-left: -50px !important;
}
.pencilimg{
margin-left: -20px!important;
}
.tabcardsize{
width: 320px !important;    
}

.editaddcard{
width: 300px !important;
height: 50px !important;
margin-left: 40px !important;
}
.mobeditpag{
margin-left: 30px !important;
}
.btn1cancel{
width: 153px;
height: 48px;
left: 35px;
top: 800px;
}
.btn2edit{
width: 153px;
height: 48px;
left: 210px !important;
top: 800px;
}
.btn2cancel{
width: 153px;
height: 48px;
left: 35px;
top: 500px;    
}
.btn3edit{
width: 153px;
height: 48px;
left: 210px !important;
top: 500px;
}
.btn2top{
    margin-top: 45px !important;
} 
._textwork{
    width: 320px !important;
    left:-28px !important;

}
.tabtext1{
    margin-left: 10px !important;
} 
.tabphonetxtbox{
    margin-left: 10px !important;
} 
.textwork_{
    width: 50px;
    height: 16px;
    left: 70px;
    top: 230px;
}
.company_btn {
    width: 150px !important;
    margin-top: 100px !important;
    margin-left: 45px!important;
}
.company_btn1 {
    width: 150px !important;
    margin-top: 100px !important;
    margin-left: 75px !important;
}
.textwork1_{
left: 80px !important;
top: 343px !important;
}
.textwork2_{
left: 80px !important;
top: 488px !important;

}

}
@media (min-width: 600px) and (max-width: 1024px){   
    .tabcardsize{
        width: 320px !important;    
        }
        
    .twotab-col {
        margin-left: -15% !important;
        width: 60% !important;
    }
    
    .img-tab {
        right: 10% !important;
    }
    .texttab-az {
        margin-left: -2% !important;
    }
    .web-mltab{
        margin-left: -90% !important;
    }
    .web-mltab1 {
        margin-left: -90% !important;
        margin-top: 20% !important;
    }
    .num-tabs {
        margin-left: -30% !important;
        width: 100% !important;
    }
    .emp-tabs {
        width:60% !important;
        margin-top: -30% !important;
        margin-left: 40% !important;
    }
   
    .chart-container {
        margin-left: 100% !important;
        margin-top: -130% !important;
       height: 250%  !important;
    }
    .month-tabs {
        margin-top: -10% !important;
        margin-left: -20% !important;
    }
    .company-tabs {
        margin-left: -10% !important;
    }
    .company-btntabs {
        width: 100% !important;
        margin-left: -13% !important;
        margin-top: -5% !important;
    }
    .tax-tab {
        margin-left: 20% !important;
        width: 200% !important;
    }
    .btn2edit{
        width: 153px;
        height: 48px;
        left: 20px !important;
        top: 1250px;
        }
        .btn2cancel{
            width: 153px;
            height: 48px;
            left: 205px;
            top: 900px;    
            }
            .btn1cancel{
                width: 153px !important;
                height: 48px !important;
                left: 190px !important;
                top: 1250px !important;
                }
                .btn3edit{
                    width: 153px;
                    height: 48px;
                    left: 10px !important;
                    top: 900px;
                    }
                    ._textwork{
                        width: 320px !important;
                        left:-28px !important;
                    
                    }
                    .tabtext1{
                        margin-left: -115px !important;
                        width: 320px !important;
                    } 
                    .tabphonetxtbox{
                        margin-left: -170px !important;
                        width: 320px !important;
                    } 
                    .textwork_{
                        width: 50px;
                        height: 16px;
                        left: 70px;
                        top: 230px;
                    }
                    .company_btn {
                        width: 150px !important;
                        margin-top: 100px !important;
                        margin-left: 45px!important;
                    }
                    .company_btn1 {
                        width: 150px !important;
                        margin-top: 100px !important;
                        margin-left: -95px !important;
                    }
                    .textwork1_{
                    left: 80px !important;
                    top: 343px !important;
                    }
                    .textwork2_{
                    left: 80px !important;
                    top: 488px !important;
                    
                    }
}
