
.nxtbtn1{
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}
.rightimgg{
position: absolute;
left: 55.79%;
top: 74%;
width: 13% !important;
height:35% !important;
}
.leftimgg{
    position: absolute;
    left: 42.89%;
    top: 85%;
    width: 193.25px !important;
    height:166.74 !important;
    }
    .leftimgg1{
     position: absolute;
     left: 52.79%;
     top: 82%;
     width: 5% !important;
     height:20% !important;
    }
    .leftimgg2{
        position: absolute;
        left: 51.80%;
        top: 95%;
        width: 6% !important;
        height:10% !important;
       }
    .leftimgg3{
        position: absolute;
        left: 41%;
        top: 95%;
        width: 6% !important;
        height:10% !important;
       }   
    .leftimgg4{
        position: absolute;
        left: 45.2%;
        top: 104%;
        width: 6% !important;
        height:1% !important;
       }  
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {



.rightimgg{
left: 58.79% !important;
}
.leftimgg{
left: 43.89%;
}
.leftimgg1{
left: 55.79% !important;
}
.leftimgg2{
left: 53.80% !important;
}
.leftimgg3{ 
left: 42% !important;
}   
.leftimgg4{
left: 46% !important;
}
.rightimgg{
top: 77% !important;
}  
}
@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {


.leftimgg3{ 
top: 94% !important;
}   
.leftimgg4{
top: 103% !important;
}  
}         

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {

.leftimgg{
display: none !important;
}
.leftimgg1{
display: none !important;
}
.leftimgg2{
display: none !important;
}
.leftimgg3{ 
display: none !important;
}   
.leftimgg4{
display: none !important;
}
.rightimgg{
display: none !important;
}
 
}
@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {


    

        .rightimgg{
            left: 70.79% !important;
            width: 23% !important;
            }
            .leftimgg{
            left: 3.89%;
            }
            .leftimgg1{
            left: 5.79% !important;
            }
            .leftimgg2{
            left: 3.80% !important;
            }
            .leftimgg3{ 
            left: 2% !important;
            }   
            .leftimgg4{
            left: 6% !important;
            }
            
}
