.search_box {
    margin-left: 60px !important;
    width: 1180px !important;
    height: 40px !important;
}

.search_icon {
    margin-left: 1190px !important;
    margin-top: -60px !important;
}

.taskcard {
    width: 95%!important;
    
    top: 35px !important;
    max-height: max-content !important;
    
}
.tasedi{
    position: absolute !important;
    top: -1px !important;
    background-color: white !important; 
    height: 200% !important;
    }
.mobsave{
    width: 80% !important;
}
.enternme{
    
    width: 248px !important;
    height: 48px !important;
    border: 1px solid #E8E8E8 !important;
    background-color: #F3F3F3 !important;
    border-radius: 5px !important;
   
}
.enternme1{
    width: 246px !important;
    height: 48px !important;
    border: 1px solid #E8E8E8 !important;
    background-color: #F3F3F3 !important;
    border-radius: 5px !important;
    
}

.taskbtn {
    width: 253px !important;
    height: 48px !important;

    box-shadow: 0px 4px 12px #73A2DA !important;
    border-radius: 5px !important;
}

.tasksmallcard {
    width: 1140px !important;
    height: 180.3px !important;
    top: 15px !important;

    background: #FFFFFF !important;
    border: 1px solid #E8E8E8 !important;
}

.Card_task {
    height: 665px !important;
    width: 1140px !important;
    left: 0px !important;
    top: 8px !important;
    border-radius: 10px;
}

.task_name {
    position: absolute;
    width: 159px;
    height: 19px;
    left: 102px;
    top: 63px;

    
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #212121;
}

.Task_position {

    position: absolute;
    width: 160px;
    height: 19px;
    left: 101px;
    top: 90px;

   
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    color: #727272;

}
.taskdat{
    position: absolute;
    width: 60px;
    height: 16px;
    left: 40px;
    top: 280px;
    
    
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background-color: #F3F3F3 !important;
    color: gray;
}
.endpos{
margin-left: 270px !important;
}
ol,
ul,
dl {
  margin-top: 10px !important;
  margin-bottom: 1rem;
}
.taskdes{
    height: 88px !important;
}
.tast_textbox1 {
    position: relative;
    box-sizing: border-box;
    width: 525px !important;
    height: 48px;
    top: 10px !important;

    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.task_textbox_2 {
    position: relative;
    box-sizing: border-box;
    top: 10px !important;

    width: 760px !important;
    height: 174px;


    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.task_btn {
    width: 250px !important;
    height: 48px !important;



    border-radius: 5px;
}

.text_small {
    font-size: small;
    left: -30px !important;
}

.filter_img {
    right: 50px !important;
    top: 25 px !important;
    position: absolute;
}

.clr_date {
    background-color: #E8E8E8 !important;
    height: 48px !important;
    width: 250px !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 10px !important;
}

@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {

.tasksmallcard {
    width: 990px !important;
    height: 180.3px !important;
    left: -3px !important;
}
.Card_task {
    height: 665px !important;
    width: 990px !important;
    left: -2px !important;   
}
.clr_date1{
    margin-left: 30px !important;
}
.search_icon {
    margin-left: 1050px !important;
    margin-top: -60px !important;
}


}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
.tasksmallcard {
    width: 940px !important;
    height: 180.3px !important;
    left: -5px !important;
}
.Card_task {
    height: 665px !important;
    width: 938px !important;
    left: -3px !important;   
}
.clr_date1{
    margin-left: 42px !important;
}
.search_icon {
    margin-left: 990px !important;
    margin-top: -60px !important;
}
} 
@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
    .mobileviewhide {
        display: none !important;
    }
.search_box {
    margin-left: -50px !important;
    width: 360px !important;
    height: 40px !important;
    font-size: 15px !important;
}
.search_icon {
    margin-left: 285px !important;
    margin-top: -60px !important;
}

.tasksmallcard {
    width: 340px !important;
    height: 100px !important;
    top: 15px !important;
    left: -10px !important;
}
.tascent{
    
    align-items: center !important;
    text-align: center !important;
    margin-left: -14% !important;
     
}
.mobtexttask{
width: 150px!important;
}
.mobtexttask1{
width: 150px!important;
margin-left: -2px !important;
}
.mobtexttask2{
width: 100px!important;
margin-left: -40px !important;
}
.mobtexttask3{
margin-left: 100px !important;
}
.taskfilt{
margin-left: -20px !important;
}
.mobtabali{
margin-left: 60px !important;
}
.taskbtn {
margin-left: 30px !important;
}
.Card_task {
    height: 665px !important;
    width: 340px !important;
    left: -10px !important;
    top: 8px !important;
    border-radius: 10px;
}
.task_textboxx_2 {


    width: 310px !important;
    height: 174px !important;


    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}
.clr_date {
    height: 48px !important;
    width: 140px !important;
}
.clr_date1{
    margin-left: 60px !important;
}

.taskk_btn {
    position: absolute !important;
    margin-left: -50px !important;
margin-top: -250% !important;
}
.task-ml {
    margin-left: -3% !important;
   
}
.comp-assign {
    margin-left: 8% !important;
    width: 200% !important;
}
.comp-assign2 {
    margin-left: 90% !important;
    width: 200% !important;
}
.task-mobv {
width: 200px !important;
    margin-left: 20% !important;
}
.task-assigne2{
width: 200px !important;
margin-left: 5% !important;
}
.task-assigne3 {
    margin-left: 110% !important;
}
.dots-assigne {
    margin-left: 30% !important;
}
.mobcompl2 {
    margin-left: 100% !important;
    width: 100px !important;
}
.mobintext {
    margin-left: 100% !important;
    width: 150% !important;
}
.mobassign4 {
    margin-left: 100% !important ;
    width: 150% !important;
}
.mobpendtext {
margin-left: 100% !important;
width: 150% !important;
}
.mobproextmv {
    margin-left: 100% !important;
    width: 150% !important;
}
.mobcantext {
    margin-left: 100% !important;
    width: 150% !important;
}
.mobcomtextmv {
    margin-left: 100% !important;
    width: 150% !important;
}
.cancel-mv {
    height: 48px !important;
    width: 140px !important;
}
.save_date1 {
    height: 48px !important;
    width: 140px !important;
}
.mobnewmobv {
    margin-left: 100% !important;
    width: 150% !important;
}
.taskdat{
   
    left: 40px;
    top: 370px;
}
.endpos{
margin-left: 161px !important;
top: 370px!important;
}
.mobsave{
    width: 100% !important;
}
.mobpri{
    margin-left: 5px !important;
    margin-top: 10px !important;
}
.tasedi{
    position: absolute !important;
    top: -1px !important;
    height: 310% !important;
    }

}  

@media screen and (min-device-width: 760px)and (max-device-width: 899px) and (-webkit-min-device-pixel-ratio: 1){


.tasksmallcard {
    width: 700px !important;
    height: 220.3px !important;
    left: -5px !important;
   
}
.Card_task {
    height: 665px !important;
    width: 700px !important;
    left: -10px !important;
    top: 8px !important;
    border-radius: 10px;
}
.clr_date {
    height: 48px !important;
    width: 190px !important;
}
.clr_date1{
    margin-left: 90px !important;
}
.endpos{
margin-left: 250px !important;
}
}


