  .possid{
    position: absolute !important;
  }
  .side_img{
    height: 40px !important;
  }
 
 .fb{
  font-weight:900 !important;
 }
  
    .sidebar {
      position: fixed;
      top: 0px;
      bottom: 0;
      height: auto;
      left: 0;
      width: 280px;
      height: auto !important;
      background: #4EB4E6;
      border-right: 0.5px solid lightgrey;
      }

    ul {
      margin-left: -5px;
      padding: 0;
    }
    .ulleft{
      margin-left: -56px;
    }

      
    li {
      display: flex;
      align-items: center;
      padding: 18px;
      cursor: pointer;
    }
    .sidebarlist{
      font-size: 15px;
      font-weight: 400;
      margin-left:25px;  
    }
    .sidebar_list{
      font-size: 15px;
      font-weight: 900;
      margin-left:25px;  
    }
    .sidebar_list{
      font-size: 15px;
      font-weight: 600;
    }
  
  .foot{
    margin-top: 30px !important;
  }
  .setting_side {
    position: fixed;
    top: 60px;
    bottom: 0;
    height: auto;
    left: 280px;
    width: 200px;
    height: auto !important;
    background: #E7E7E7;
    border-right: 0.5px solid lightgrey;
    }

  .sdhibgcolor{
    background: #0080FF;
  }
  .disp_mobile_none{
    display: none !important;
  }
    @media screen and (min-width: 1200px) and (max-width: 1280px) {
      .sidebar {
        width: 250px !important;
        height: 680px !important;
      }
      .foot {
        margin-top: 40px !important;
      }
    }
    
   
  @media screen   and (min-device-width: 500px)  and (max-device-width: 700px) and (-webkit-min-device-pixel-ratio: 1)  {
    .sidebar {
      width: 280px !important;
      height: 680px !important;
    }
    .foot{
      margin-top: 40px !important;
    }
    }   
    @media screen   and (min-device-width: 760px)  and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1)  {
      .sidebar {
        width: 280px !important;

      }
      .sdhi{
        height: 650px !important;
        position: absolute;
        /* margin-left: -25px !important; */
    
        }
      .foot{
        margin-top: 40px !important;
      }
      .setting_side{
       margin-left: -21px !important; 
      }
      } 
    @media screen   and (min-device-width: 1200px)  and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1)  {
      .sidebar {
        width: 280px !important;
     
      }
      .sdhi{
        height: 500px !important;
        position: absolute;
        /* margin-left: -25px !important; */
    
        }
      li {
        display: flex;
        align-items: center;
        padding: 12px;
        cursor: pointer;
      }
      .foot{
        margin-top: -20px !important;
      }
      .setting_side {
        position: fixed;
        top: 60px;
        bottom: 0;
        height: auto !important;
        left: 280px;
        }
        .active_s::before {
          
          top: -4px !important;
          right: -13px !important;
          
        }
      }   

   
      @media screen and (min-device-width: 600px)and (-webkit-min-device-pixel-ratio: 1)  {
        .dotmv {
            display: none !important;
        }
    }


    @media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) { 
      hr {
        height: 0;
        border: 0.5px solid lightgray;
      }
      button {
        font-size: 18px;
        font-weight: 620;
        color: black;
        margin-left: 5px;
      }
      .setting_side {
        top: 48px;
        bottom: 0;
        height: auto !important;
        left: 230px;
        }
      
    }
    
 
    .header {
        display: flex;
        position: fixed;
        flex-direction: column;
        inset: 0 75% 0 0;
        background-color: #0080FF;
    }
    
    .header-mobile {
        display: flex;
        position: fixed;
        flex-direction: column;
        align-items: center;
        inset: 0 95% 0 0;
        background-color: hsla(0,2%,10%,0.558);
    }
    
    nav .ul-item {
        display: flex;
        flex-direction: column;
        margin-block: .5rem;
        list-style: none;
    }
    
    nav .ul-item.oicon a {
        display: none;
    }
    
    nav .ul-item.oicon li::after {
        content: '';
        position: absolute;
        width: 1%;
        height: 4%;
        background-color: #fff;
        right: 0;
    }
    
    nav .ul-item li {
        display: flex;
        align-items: center;
        gap: 2rem;
        cursor: pointer;
        padding-block: 1.2rem;
        margin-block: .1rem;
    }
    
    nav .ul-item li:hover {
        background-color: rgb(66, 81, 52);
    }
    
    nav .ul-item li .icon {
        color: rgb(0, 255, 145);
        margin-inline: 1rem;
        font-size: 30px;
    }
    
    nav .ul-item li a {
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        border-left: 1px solid white;
        padding-left: 2rem;
    }
    .menu-icon {
        margin-inline: 1rem;
    }
    
    .menu-icon .menu {
        color: rgb(0, 255, 145);
        margin-block: 1rem;
        cursor: pointer;
        font-size: 30px;
    }
    
    nav .ul-item li:hover {
        transition: 1s ease;
    }
    
    
    @media (max-width:600px) {
        .header-mobile {
            inset: 0 90% 0 0;
            justify-content: center;
        }
        .header {
          display: flex;
          position: fixed;
          flex-direction: column;
          inset: 0 98% 0 0;
          background-color: #0080FF;
      }
        nav .ul-item {
            display: none;  
        }
    
        nav .ul-item.oicon {
            display: flex;
        }
        .menu-icon {
            display: none;
        }
    
        nav .ul-item li .icon {
            font-size: clamp(1rem,2vw + 1rem, 4rem);
        }
    }
    
    
    
      
    @media screen and (min-device-width: 850px) {
      .onlytab{
        display: none !important;
      }
    }
    

   
    
    .dot {
      width: 15px;
      height: 15px;
      position: relative;
      border-radius: 50%;
      margin-left: -26px !important;
      background-color: white;
    }
    .dot_s {
      width: 8px;
      height: 8px;
      position: relative;
      border-radius: 50%;
      margin-left: -23px !important;
      background-color: white;
    }
    @media screen and (min-device-width: 1400px)and (-webkit-min-device-pixel-ratio: 1) { 
     
    .sdhi{
    height: 555px !important;
    position: absolute;
    margin-left:05px !important;

    }
    
  }
    
   
  .landpos{
    position: absolute !important;
  }
    
   
  .side_text {
    font-size: 0.95rem;
    
  }
   
    
 
  
 
    /* .active_s{
     
      font-size: 15px;
      font-weight: 900;
      margin-left:25px; 
      color: white !important;
      
    } */

    .active_s {
      font-size: 15px;
      font-weight: 600;
      margin-left: 25px;
      color: white !important;
      background-color: #F36E35; 
      position: relative; 
    }
    
    .active_s::before {
      content: '';
      position: absolute;
      top: 0;
      right: -14px; 
      width: 0;
      height: 100%;
      border-style: solid;
      border-width: 30px 0 30px 15px;
      border-color: transparent transparent transparent #F36E35; 
    }

  

.icon {
  width: 24px; 
  height: 24px;
  transition: transform 0.3s; 
}

/* .custom-hover:hover{
  content: '';
  top: 0;
  right:0; 
  width: 100%;
  height:0;
  border-style: solid;
  border-width: 15px 0 15px 10px;
  border-color: transparent transparent transparent #F36E35; 
} */

.custom-hover {
  position: relative;
}

.custom-hover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 55%;
  border-style: solid;
  border-width: 15px 0 15px 10px;
  border-color: transparent transparent transparent #F36E35;
  transform: rotate(45deg);
  transform-origin: 100% 0;
  transition: transform 0.2s;
  opacity: 0;
  pointer-events: none;
}

.custom-hover:hover::before {
  transform: rotate(0deg);
  opacity: 1;
}


/* Hover effect */
.icon:hover  {
  transform: rotate(360deg); 
}
    
    .ex_clr{
      background: #4EB4E6;

    }
  
    @media screen and (min-width: 1600px) and (max-width: 1800px){
      .sidebar {
        
        width: 319px !important;
        }

      .setting_side{
       margin-left: -21px !important; 
       left: 340px !important;
      }  
    }
    @media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
      .sidebarlist{
        font-size: 13px;
        font-weight: 400;
        margin-left:25px;  
      }
      .sidebar {
        width: 250px !important;
        height: 780px !important;
         }
         .sidebar-open.rbc-button-link {
          visibility: hidden !important;
        }
      
      .li-mobile {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
      }
      .sidebarcompmob {
        width: 280px !important;
        height: 780px !important;
      }
    }    