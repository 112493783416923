.searchbox {


    width: 96% !important;

}

.searchicon {
    margin-left: 90% !important;
}

p {
    text-transform: capitalize !important;
}

td {
    text-transform: capitalize !important;
}

select {
    text-transform: capitalize !important;
}

small{
    text-transform: capitalize !important;
}

.viewdetails {
    box-sizing: border-box;

    position: absolute;
    width: 95% !important;
    height: 85px !important;

    font-size: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;
}

.viewdetails_btn {
    position: absolute;
    width: 340px;
    height: 48px;

    font-size: small !important;
    background: #727272;
    box-shadow: 0px 4px 12px #727272;
    border-radius: 5px;
}

.popbtn {
    margin-left: 700px;
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
    .viewdetails_btn {

        width: 260px;
        height: 48px;


    }
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .viewdetails_btn {

        width: 310px;
        height: 48px;
        left: 657px;
        top: 15px;

    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1) {
    .head_side {
        position: fixed !important;
        top: 10px !important;
        left: 326px !important;
    }
.supertxtmob{
    font-size: 14px !important;
    text-align: center;
}
    /* @keyframes scrollIndicator {
        0% {
          transform: translateX(0);
        }
      
        50% {
          transform: translateX(20px);
        }
      
        100% {
          transform: translateX(0);
        }
      }
       */
      .iconpos {
        animation: scrollIndicator 1.5s ease-in-out infinite;
       
      }
      .pendcard{
        margin-left: 1% !important;
      }
      .holcard{
        margin-left: 1.8% !important;
      }

    .viewdetails_btn {

        width: 265px;
        height: 38px;
        left: 40px !important   ;


    }

    .card_1_text {
        margin-left: 20px !important;
    }

}

.card_1 {
    box-sizing: border-box;

    height: 146px !important;


    background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #3288C7 2.84%, #5BD6FD 37.5%, #5CB0E0 57.29%, #3085D3 95.34%);
    box-shadow: 0px 4px 20px #4B8AA8;
    border-radius: 10px;
}

.card_1_super {
    box-sizing: border-box;

    height: 180px !important;


    background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #3288C7 2.84%, #5BD6FD 37.5%, #5CB0E0 57.29%, #3085D3 95.34%);
    box-shadow: 0px 4px 20px #4B8AA8;
    border-radius: 10px;
}

.card_1_text {
    margin-left: 105px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .card_1_text {
        margin-left: 105px !important;
    }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
    .card_1_text {
        margin-left: 65px !important;
    }
}

.card_2 {
    box-sizing: border-box;


    height: 146px !important;


    background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #3935FE 2.84%, #85D3EC 37.5%, #A7D1F7 57.29%, #486AC0 95.34%);
    box-shadow: 0px 4px 20px #6F94D6;
    border-radius: 10px;
}

/* .card_3 {
    box-sizing: border-box;
    height: 146px !important;


    background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #72D4EA 2.84%,  #7EFFAA 37.29%,#8FDDB3 57.5%, #20B7AE 65.34%);
    box-shadow: 0px 4px 20px #A4F4C1;
    border-radius: 10px;
} */
.card_3{
    box-sizing: border-box;
    height: 156px !important;
    background: transparent linear-gradient(180deg, #30FAD0 0%, #4EB4E6 64%, #4EB4E6 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000040;
    box-shadow: 0px 4px 20px #A4F4C1;
    border-radius: 10px;
 
 }
.card_1_img {
    margin-left: 135px !important;
    margin-top: -15px;
}

.card_graph {
    box-sizing: border-box;

    position: absolute;
    width: 582px !important;
    height: 350px !important;
    left: 60px;
    top: 20px;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;
}

.card_holiday {

    box-sizing: border-box;

    position: absolute;

    height: 350px !important;


    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;

}
.card_overtime {

    box-sizing: border-box;

    position: absolute;

    height: 350px !important;


    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;

}
.card_holidaysuper {

    box-sizing: border-box;

    position: absolute;

    height: 350px !important;


    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;

}

.card_super {

    box-sizing: border-box;

    position: absolute;

    height: 180px !important;


    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;


}

.remindercard {
    box-sizing: border-box;
    background: #FFFFFF;
    height: 63px !important;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;
}

.card_super_lead {

    box-sizing: border-box;

    position: absolute;

    height: 180px !important;



    border-radius: 10px;
    background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #72D4EA 2.84%, #449a6c 37.5%, #0ce354 57.29%, #20B7AE 95.34%);
    box-shadow: 0px 4px 20px #A4F4C1;

}

.card_super_demo {
    background: radial-gradient(114.16% 154.44% at 85.88% 181.42%, #7EFFAA 2.84%, #3abfd6 37.5%, #1be396 57.29%, #20B7AE 95.34%);
    box-shadow: 0px 4px 20px #0e697b;
}

.card_todo {

    box-sizing: border-box;

    position: absolute;
    width: 94% !important;
    height: 350px !important;


    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;

}
.card_leave {

    box-sizing: border-box;

    position: absolute;
    width: 94% !important;
    height: 350px !important;


    background: #FFFFFF;
    box-shadow: 0px 4px 20px #DBDBDB;
    border-radius: 10px;

}

.text_small {
    font-size: x-small;
}

.text_small_mid {
    font-size: 13px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    ._text {
        font-size: x-small;
    }
}

@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
    ._text {
        font-size: x-small;
    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1) {
    .searchbox {
        margin-left: 60px !important;
        width: 180px !important;

    }
    .year-buttonss {
        display: flex !important;
        flex-direction: row !important; 
        overflow-x: scroll !important;
      
    }
    .scroll-container {
        display: flex !important; 
        overflow-x: auto !important; 
        white-space: nowrap !important; 
        /* -webkit-overflow-scrolling: touch !important;  */
        /* width: 100%;
        scroll-snap-type: y mandatory !important; */
      }
     
     
      .scroll-container .card_1 {
        
        width: 338% !important; 
        
      }
      .scroll-container .card_2 {
        
        width: 338%;
        margin-left: 520% !important;  
      }
      .scroll-container .card_3 {
    
        width: 338%; 
        margin-left: 280% !important;  
      }
      .lefdash{
        margin-left: -40px !important;
      }

      .graphleft{
        margin-left: -38px !important;
      }
      .graphcardwidthmob{
        width: 97% !important;
      }
      .cardlef{
        margin-left: 5px !important;
      }
}

.pointer_ {
    cursor: pointer !important;
}

.gp-green {
    background-color: #0ce354 !important;
}

element.style {
    font-family: Helvetica, Arial, sans-serif;
    opacity: 1;
}

*,
::after,
::before {
    box-sizing: border-box;
}

@media screen   and (min-device-width: 600px)  and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)  {
 .crtblf{
    margin-left: -40px !important;
 }  
 
 .grlf{
   margin-left: -18px !important; 
 }
 .year-buttonss {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto !important;

}

.caltab{
    
    margin-left: -80px !important;
}
.upattri{
    margin-left: 6% !important;
}
.leavtablef{
    margin-left: -100px !important;
}
.levapptab{
    margin-left: -70px !important;
}
.tablfil{
    margin-left: 50px !important;
}
}

