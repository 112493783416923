.Card_1task{
    height: auto !important;
    width: 95% !important;
    
    border-radius: 10px;
}  

.Card_edittask{
    height: auto !important;
    width: 98% !important;
    
    border-radius: 10px;
}





.task_name{
    position: absolute;
    width: 159px;
    height: 19px;
    left: 102px;
    top: 63px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
   
    
    
    color: #212121;
}
.Task_position{
    
    position: absolute;
width: 160px;
height: 19px;
left: 101px;
top: 90px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;



color: #727272;

}
.tast_textbox1{
    box-sizing: border-box;
width: 525px !important;
height: 48px;

margin-left: 8px;
background: #F3F3F3;
border: 1px solid #C4C4C4;
border-radius: 10px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .tast_textbox1{
        box-sizing: border-box;
    width: 525px !important;
    height: 48px;
    
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    }
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .tast_textbox1{
        box-sizing: border-box;
    width: 420px !important;
    height: 48px;
    
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    }
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .tast_textbox1{
        margin-left: 0px;  
    }
    .task_btn{
        width: 250px !important;
    height: 48px !important;
    position: absolute;
    left: 50px !important;
    top:850px;
    border-radius: 5px;
    }

    .Card_edittask{
        height: auto !important;
        width: 110% !important;
        
        border-radius: 10px;
        margin-left: -50px !important;
    }
}
.task_textbox_2{
    box-sizing: border-box;


width: 526px !important;
height: 84px;


background: #F3F3F3 !important;
border: 1px solid #C4C4C4;
border-radius: 10px;


}
.task_btn{
    width: 250px !important;
height: 48px !important;



border-radius: 5px;
}
.text_small{
    font-size: small;
    left: -30px !important;
}
.filter_img{
    right: 50px !important;
    top: 25 px !important;
    position: absolute;
}

.appr-mv {
    width: 240px !important;
 }
 .reje-mv{
     width: 240px !important
 }
 .back-mv {
     width: 240px !important
 }

 @media (min-width: 600px) and (max-width: 1024px){

    .appr-mv {
       width: 150px !important;
    }
    .reje-mv{
        width: 150px !important
    }
    .back-mv {
        width: 150px !important
    }
    }