.bg-date {
    background-color: #D7E4ED !important;
    width: 90%;
}

.next-align2 {
    margin-left: 90% !important;
}
.listholiday{
margin-top: -680px !important;
margin-left: 50px !important;

}
.listholiday1{
margin-top: -620px !important;
margin-left: 50px !important;
width: 300px !important;
}
.listholiday2{
margin-top: -600px !important;
margin-left: 50px !important;
width: 300px !important;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .holiday-mb {
        margin-left: -4%;
    }

    .holiday-mb1 {
        margin-left: 5%;
    }

    .holiday-mb2 {
        margin-left: -100%;
    }

    .holiday-14 {
        margin-left: -2%;
    }

    .holiday-view {
        margin-left: -2%;
    }

    .twxt-fs {
       
        font-style: normal;
        font-weight: 200;
        font-size: 2px;
        line-height: 19px;
    }
}

@media screen and (min-device-width: 800px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
    .holiday-sm {
        margin-left: 2%;
    }

    .holidaycal-sm {
        margin-left: -2%;
    }

    .holidaysm-web {
        margin-left: -2%;
    }

    .col-history {
        margin-left: -2%;
        width: 100%;
    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) { 
    .holicalhide {
        display: none !important;
    }
    .calhis-ml {
        margin-left: 10%;
    }
    .nav-web {
        margin-left: -41%;
    }
    .apply-fss {
        margin-left: 23%;
    }

    .leave2-fs {
        margin-left: -16%;
    }
    .holiday2-mb {
        margin-left: -19%;
    }
    .holidayhl {
        margin-left: -5%;
    }
}
@media (min-width: 768px) and (max-width: 1024px){

.holiday-tabv {
    width: 95% !important;
    margin-left: 9% !important;

}
.holidaytab {
    display: none !important;
}
.fed-tab {
    margin-left: 15% !important;
    margin-top: 30% !important;
}
.fed-date {
    margin-left: 10% !important;
    margin-top: -3% !important;
    width: 300% !important;
}
.fed-num {
    margin-left: 10% !important;
    width: 300% !important;
}
.datetabview{
    margin-left: 150% !important;
    width: 300% !important;
    margin-top: -29% !important;
}
.datetabview1 {
    margin-left: 150% !important;
    width: 300% !important;
    margin-top: 3% !important;
}
.datetabview2 {
    margin-left: 260% !important;
    width: 300% !important;
    margin-top: -29% !important;

}
.datetabview3 {
    margin-left: 260% !important;
    width: 300% !important;
    margin-top: -20% !important;

    
}
.displayhidetab {
    display: none !important;
}


}