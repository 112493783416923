.timing{
    width:160px !important;
    font-size: 14px;
    

}
.stendw{

font-size: 12px !important;
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .timing{
       
        width:100px !important;
        font-size: 10px;
        
    
    }
   
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .timing{
        width:140px !important;
        font-size: 14px;
        
    
    }
}
@media screen and (min-device-width: 300px)and (max-device-width: 599px){
.timing_m{
    width:140px !important;
    font-size: 14px;
    

}
.time_pos{
    position: absolute;
    top:450px !important;
    left: -15px !important;
}
}
.hover{
    border-width:0px !important ;
    
}

@media screen and (min-device-width: 300px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
   
    .working_days_mob{
        position: absolute;
        height: 19px;
        font-size: smaller;
    width: 108px;
    left: 200px;
    top: 417px !important;
    border-radius: 0px;
    }
    .add_com_{
        position: absolute;
    top:850px !important;
        left: 40px!important;
    }
    }
.text_work_update{
    position: absolute;
width: 141px;
height: 16px;
left: 656px;
top: 135px;


font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
background-color: white;
color: #0080FF;
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .text_work_update{
    left: 489px;
    top: 155px;
   
    }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
  
    .text_work_update{
        left: 595px;
        top: 135px;
       
        }
}
@media screen and (min-device-width: 300px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .text_work_update{
        top: 262px;
        left: 60px;
        
    }
}
.imagework{
    width:  506.37px !important;
    
    margin-top: -20px;
    margin-left: 620px !important;
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .imagework{
       
        margin-left: 400px !important;
    }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
  
    .imagework{
        
        margin-left: 520px !important;
     }

}
 .text__size{
    font-size: small !important;
    height: 48px !important;
    width: 200px !important;
    margin-left: 365px !important;
    margin-top: 50px !important;
}
.workup{
margin-top: -300px!important;
}
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
  
    .text__size{
       
        width: 240px !important;
        margin-left: 291px !important;
    }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
  
    .text__size{
       
        margin-left: 355px !important;
    }
}
@media screen and (min-device-width: 300px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .text__size{
    position: absolute;
        margin-left:70px !important;
        width: 250px !important; 
        top: 750px !important;
    }
}
._lefttime{
    margin-left :80px !important;
    }
@media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
  
    ._lefttime{
        margin-left :80px !important;
        }
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
  
    ._lefttime{
        margin-left :70px !important;
        }
}

@media (min-width: 768px) and (max-width: 1024px){  
    .workupdatetab {
        margin-left: 3% !important;
        width: 95% !important;
    }
}

@media screen and (min-device-width: 760px)and (max-device-width: 899px){
    .timing{
        width:120px !important;
        font-size: 14px;
        
    
    }
   
    ._lefttime{
        margin-left :100px !important;
        margin-top:15% !important ;
        }
        .text__size{
       
            width: 240px !important;
            margin-left: 131px !important;
        }
        .imagework{
        
            margin-left: 120px !important;
         }
         .text_work_update{
            left: 289px;
            top: 135px;
           
            }
            .working_days_{
                position: absolute;
                height: 19px;
                font-size: smaller;
            width: 228px !important;
            left: 200px;
            top: 267px !important;
         
            }
}