.landingloginbtn {
    
   
    background: #DCDCDC !important;
}
.bdradius{
border-radius: 10px !important;
}
.imglnd{
width: 100% !important;
}

.Landingcard {
    
    height: 502px !important;
    border-radius: 0% !important;
    background: #0080FF !important;
}

.landsmallcard1 {
    
    height: 158px !important;
    background: #FFFFFF !important;
    border-radius: 6px !important;
}

.landsmallcard2 {
    position: absolute !important;
    width: 254px !important;
    height: 158px !important;
    left: 1140px !important;
    top: 55px !important;

    background: #FFFFFF !important;
    border-radius: 6px !important;
}

.toptext1img {
    position: absolute !important;
    width: 111px !important;
    height: 15px !important;
    left: 48px !important;
    top: 73px !important;
}

.centertxtimg1 {
    position: absolute !important;
    width: 58px !important;
    height: 19px !important;
    left: 48px !important;
    top: 98px !important;
}

.landsmallcard3 {
    position: absolute !important;
    width: 254px !important;
    height: 158px !important;
    left: 640px !important;
    top: 300px !important;

    background: #FFFFFF !important;
    border-radius: 6px !important;
}

.landsmallcard4 {
    position: absolute !important;
    width: 254px !important;
    height: 158px !important;
    left: 1115px !important;
    top: 270px !important;

    background: #FFFFFF !important;
    border-radius: 6px !important;
}

.nameimg {
    width: 31px !important;
    height: 15px !important;
}

.empname {
    width: 85px !important;
    height: 15px !important;
}

.chritname {
    width: 82px !important;
    height: 19px !important;
}

.useimg {
    position: absolute !important;
    margin-left: -15.5% !important;
    margin-top: 18px !important;
}

.montimg {
    width: 65px !important;
    height: 15px !important;
}

.landladyimg {
    position: absolute !important;
    width: 248px !important;
    height: 394px !important;
    left: 879px !important;
    top: 125px !important;
}

.getstartbtn {
    position: absolute !important;
    width: 200px !important;
    height: 56px !important;

    background: #FFFFFF !important;
    border-radius: 5px !important;
}
.btn_size{
    width:290px !important;
}
.curveimg {
    position:absolute !important;
  
    left: 48% !important;
    top: 25% !important;
}

.cloudimg {
    position: absolute !important;
    width: 179.11px;
    height: 99px;
    left: 44.62% !important;
    right: 78.87% !important;
    top: 90.79% !important;
}

.middleimg {
    position: absolute !important;
    left: 34.74% !important;
    right: 53.16% !important;
    top: 82.49% !important;
    bottom: 34.22% !important;
}

.rightimgs {
    position: absolute !important;
    left: 61.97% !important;
    right: 53.08% !important;
    top: 81.49% !important;
    bottom: 23.72% !important;
}

.curve1 {
    position: absolute !important;
    width: 720px !important;
    height: 428px !important;
    left: 405px !important;
    top: 600px !important;
}

.lftimg1 {
    position: absolute !important;
    left: 24.74% !important;
    right: 53.16% !important;
    top: 118.49% !important;
    bottom: 34.22% !important;
}

.rgtimg1 {
    position: absolute !important;
    left: 72.74% !important;
    right: 53.16% !important;
    top: 118.49% !important;
    bottom: 34.22% !important;
}

.wetext {
    margin-top: 200px !important;
    font-size: 45px !important;
}

.requestbtn {
    position: absolute !important;
    width: 361px !important;
    height: 108px !important;
    left: 600px !important;
    top: 150px !important;
    background: #0E1D2D !important;
    border-radius: 60px !important;
}

.drkblu {
    position: absolute !important;
    width: 1540px !important;
    height: 542.11px !important;
    left: 10px !important;
    top: 970.89px !important;
}

.pattern {
    position: relative;
    background-color: white !important;
}

.pattern:before {
    background-image: url("../Images/drkblu.svg");
    content: "";
    position: absolute;
    top: 50px !important;
    bottom: 0;
    left: -10px;
    width: 110%;
    height: 542.11px;
    background-size: cover;
    background-repeat: no-repeat;
}

.bsttxt {
    position: absolute !important;
    width: 527px !important;
    height: 65px !important;
    left: 467px !important;
    top: 151px !important;
    font-size: 48px !important;
}

.sectxt {
    position: absolute !important;
    width: 788px !important;
    height: 216px !important;
    left: 350px !important;
    top: 250px !important;
    font-size: 48px !important;
}

.sectxt1 {
    position: absolute !important;
    width: 768px !important;
    height: 216px !important;
    left: -100px !important;
    top: 70px !important;
    font-size: 48px !important;
}

.sectxt2 {
    position: absolute !important;
    width: 768px !important;
    height: 216px !important;
    left: 150px !important;
    top: 70px !important;
    font-size: 48px !important;
}

.sectxt3 {
    position: absolute !important;
    width: 768px !important;
    height: 216px !important;
    left: 20px !important;
    top: 150px !important;
    font-size: 48px !important;
}

.bentxt {
    position: absolute !important;
    top: 1550px !important;
    left: 620px !important;
    font-size: 30px !important;
}

.benefitsside {
    position: absolute !important;
    margin-top: 750px !important;
}

.demotxt {
    position: absolute;
    width: 310px !important;
    height: 48px !important;
    left: 0 !important;
    top: 7px !important;
    border: 1px solid gray !important;
    border-radius: 10px;
}

.dem1 {
    margin-top: 80px !important;
}

.dem2 {
    margin-top: 150px !important;
}
.demotxt1 {
    position: absolute;
    width: 310px !important;
    height: 118px !important;
    left: 0 !important;
    top: 7px !important;
    border: 1px solid gray !important;
    border-radius: 10px;
}

.nav-pills {
    --bs-nav-pills-border-radius: 30.375rem !important;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #0d6efd;
    width: 310px !important;
}


.benfull {
    margin-left: 30px !important;
}
.imgimg{
    width:90% !important;
   
}
.img_hei{
    height: 500px !important;
     
}


.landseccard {
   
    height: 320px !important;
    background-color: #0080FF !important;
    border-radius: 0px !important;
}

.curimg {
    background-image: url("../Images/cur.svg");
    content: "";
    position: absolute;
    top: 70px !important;
    bottom: 0;
    left: -1px;
    width: 100%;
    height: 248px !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.wanttxt {
    position: absolute !important;
    margin-left: -50px;
    top: 60px !important;
}

.footer_land {
    position: absolute !important;
    width: 100% !important;
    height: 78px !important;

    top: 2658px !important;
}

.empl-log {
    margin-left: -50%;
}

.reqtext {
    margin-left: 250px !important;
}

.demoalign {
    margin-top: 130px !important;
    margin-left: 350px !important;
}

.demosize {
    width: 145% !important;
    height: 75% !important;
}

.demo_text {
    position: absolute;
    width: 60px;
    height: 16px;
    left: 50px;
    top: 24px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
}
.demo_txt {
    position: absolute;
    width: 150px;
    height: 16px;
    left: 420px;
    top: 24px;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
}
.demotext {
    position: absolute;
    width: 300px;
    height: 138px;
    left: -10px;
    top: 8px;
    border-radius: 10px;
    border: 1px solid gray !important;
}

.backcl {
    background: #F3F3F3 !important;
}

.demsubbtn {
    position: absolute;
    width: 301px;
    height: 48px;
    left: 180px;
    top: 335px;

    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}


@media screen  and (min-device-width: 1440px) and (-webkit-min-device-pixel-ratio: 1) {
    .requestbtn {
        left: 40% !important;
    }
    .wanttxt {
      left: 36% !important;
    }
}
.border_null{
    border: none !important;
}



@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    

    .curveimg {
        position:absolute !important;
        left: 48% !important;
        top: 27.5% !important;
    }

    

    .landsmallcard2 {
        left: 1050px !important;
    }

    .landsmallcard3 {
        left: 540px !important;
    }

    .landsmallcard4 {
        left: 1015px !important;
    }

    .landladyimg {
        left: 779px !important;
    }

 

    .wetext {
        margin-top: 180px !important;
        margin-left: 170px !important;
        font-size: 48px !important;
    }

    .rescentertext {
        width: 100% !important;
        margin-left: 20% !important;
    }

    .benfull {
        margin-left: 10px !important;
    }

    .cloudimg {

        left: 48.62% !important;
        right: 78.87% !important;
        top: 85.79% !important;
    }

    .rightimgs {
        position: absolute !important;
        left: 70.97% !important;
        right: 53.08% !important;
        top: 81.49% !important;
        bottom: 23.72% !important;
    }

    .lftimg1 {
        position: absolute !important;
        left: 27.74% !important;
        right: 53.16% !important;
        top: 118.49% !important;
        bottom: 34.22% !important;
    }

    .rgtimg1 {
        position: absolute !important;
        left: 81.74% !important;
        right: 53.16% !important;
        top: 118.49% !important;
        bottom: 34.22% !important;
    }

    .wanttxt {
        position: absolute !important;
        margin-left: 370px;
        top: 60px !important;
    }

    .requestbtn {

        left: 520px !important;

    }

    .middleimg {

        top: 86.49% !important;
        left: 38% !important;
    }
    .nav-pills {
        --bs-nav-pills-border-radius: 30.375rem !important;
        --bs-nav-pills-link-active-color: #fff;
        --bs-nav-pills-link-active-bg: #0d6efd;
        width: 350px !important;
    } 
}

@media screen and (min-device-width: 800px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
    

    
    

    .landsmallcard2 {
        left: 960px !important;
    }

    .landsmallcard3 {
        left: 510px !important;
    }

    .landsmallcard4 {
        left: 980px !important;
    }

    .landladyimg {
        left: 745px !important;
    }

    


}

@media screen and (min-device-width: 600px)and (-webkit-min-device-pixel-ratio: 1) {
    .mobhide {
        display: none !important;
    }
}

@media screen and (min-device-width: 1199px)and (max-device-width: 1399px)and (-webkit-min-device-pixel-ratio: 1) {

    .leftimgs {

        top: 88.79% !important;

    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .mobhiding {
        display: none !important;
    }
    .demoalign{
        margin-top: 130px !important;
        margin-left: 0px !important;
        
    }
    .demosize {
        width: 95% !important;
        height: 75% !important;
    }
    .reqtext {
        margin-left: 2px !important;
    }
    .demo_txt {
        position: absolute;
        width: 150px;
        height: 16px;
        left: 189px;
        top: 24px;
    
    
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        background-color: white;
        color: #0080FF;
    }
    
    .landing-mob {
        margin-left: -2% !important;
        width: 110% !important;
    }

    .curveimg {
        display: none !important;
    }

    .footer_land {
        display: none !important;
    }

    .leftimgs {
        display: none !important;
    }

    .middleimg {
        display: none !important;
    }

    .rightimgs {
        display: none !important;
    }

    .curve1 {
        display: none !important;
    }

    .wetext {
        margin-top: 150px !important;
    }

    

    

    .landsmallcard2 {
        display: none !important;
    }

    .landsmallcard3 {
        display: none !important;
    }

    .landsmallcard4 {
        display: none !important;
    }

    .wetext {
        display: none !important;
    }

    .rescentertext {
        display: none !important;
    }

    

    .moblandladyimg {
        position: absolute !important;

        left: 10px !important;
        top: 278px !important;
    }

    

    .mobsimplytext {
        display: none !important;
    }

    .mobsimply {
        margin-top: 5% !important;
        width: 250% !important;
        font-size: 47px !important;
        text-align: center !important;
    }

    .mobbusiness {
        width: 300px !important;
        text-align: center !important;
        font-size: 24px !important;
    }

    .getstartbtn {
        width: 252px !important;
        height: 40px !important;
        top: 620px !important;
        left: 45px !important;
        background: #FFFFFF !important;
        border-radius: 2px !important;
    }

    .mobbtntext {
        position: absolute !important;
        width: 166px !important;
        height: 43px !important;
        left: 40px !important;
        top: -1px !important;

        font-weight: 700 !important;
        font-size: 24px !important;
    }

    .mobbtntext1 {
        position: absolute !important;
        width: 166px !important;
        height: 43px !important;
        left: 40px !important;
        top: -5px !important;

        font-weight: 700 !important;
        font-size: 24px !important;
    }
    .wanttxt {
        position: absolute !important;
        margin-left: 30px;
        top: 60px !important;
    }

    .requestbtn {
        left: 26% !important;
        width: 200px !important;
        height: 48px !important;
        margin-top: 30px !important;
    }
    .getbt{
        margin-left: 50px !important;
    }
   
}

@media (min-width: 768px) and (max-width: 1024px) {
    .landingdisnone {
        display: none !important;
    }

    

    .mobsimplytext2 {
        margin-left: -30% !important;
        width: 20% !important;
        height: 50% !important;
        margin-top: 10% !important;
    }

    .mobsimplytext1 {
        width: 160% !important;
    }

   

    .curve1 {
        width: 100% !important;
    }

    .leftimgs {
        margin-top: -35% !important;
    }

    .middleimg {
        margin-top: -20% !important;
        margin-left: 5% !important;
    }

    .rightimgs {
        margin-left: -10% !important;
        margin-top: -35% !important;
    }

    .wetext {
        margin-left: -40% !important;
        margin-top: 15% !important;
    }

    .rescentertext {
        width: 130% !important;
        margin-left: -70% !important;
    }

    .footer-tab {
        margin-top: -5% !important;
    }


}

.pd {
    padding: 10px !important;
}