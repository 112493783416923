
.bankaccountbox{
    height: 48px !important;
    border: 1px solid #0080FF !important;
    border-radius: 5px;   
}
.exit{
margin-left: 310px;
margin-top: -68px;
}
 .notice_img{
    height: 400px !important;
    width: 120% !important;
 }

.accountbutton{
    position: absolute;
    width: 340px;
    height: 48px;
    left: 731px;
    top: 381px;
    
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .exit{
        margin-left: 280px;
        margin-top: -68px;
        }
 }
 @media screen   and (min-device-width: 1660px)  and  (-webkit-min-device-pixel-ratio: 1)  {
    .exit{
        margin-left: 375px;
        margin-top: -68px;
        }
 }
 
 @media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .accountbutton{
        left: 100px !important;
        width: 190px;
     }
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .accountbutton{
    left: 490px !important;
 }
 }


.footerimage_2{
    top: 67.17% ;
    width:  166.37px !important;
    position: absolute;
   
    margin-left: 60% !important;
}
.footerimage1__1{
    position: absolute;
    left: 44.18%;
    right: 39.4%;
    top: 73.17%;
    bottom: -0.11%;
}
.footerimage1__2{

    position: absolute;
    left: 55.65%;
    right: 48.26%;
    top: 87.85%;
    bottom: 0.2%;
}
.footerimage1__3{
    position: absolute;
left: 41.17%;
right: 38.82%;
top: 87.34%;
bottom: -0.09%;

}
.footerimage1__4{
    position: absolute;
    left: 56.31%;
    right: 36.18%;
    top: 75.93%;
    bottom: 1.37%;
    
   
}
.text_position_heading{
    margin-left: 440px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .text_position_heading{
        
        margin-left: 340px !important;
    }
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .text_position_heading{
        
        margin-left: 280px;
    }
 }
 @media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .text_position_heading{
        
        margin-left: 10px;
        font-size: 12px !important;
    }
    .tab_pos{
        margin-left: 40px !important;
        width: 350px!important;
    }
    .tax_img{
       position: absolute;
        left: 40% !important;   
        }
        .add_wrk{
            position: absolute;
            top:480px !important;
        }
        .mobadd{

            margin-left: -205px !important;
            
            }
            
            .mobaddalign{
            
            margin-left: 10px !important;
            
            }
            
            .taximg{
            
            left: 42.5% !important;  
            
            }
            
            .taxtext{
            
            margin-left: 8%;
            
            }
            
            .bankimg{
            
            left: 56.2%;    
            
            }

 }

 .tax_img{
    position: absolute;
    left: 49% ;   
    }

    .tax_text{
        margin-left:3%;
    }
    @media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {

        
         .accountbutton{
            left: 290px !important;
            width: 190px;
         }
         .footerimage_2{
            top: 69.17% ;
            left: 17% !important;
            width:  166.37px !important;
            position: absolute;
           
            margin-left: 60% !important;
        }
        .footerimage1__1{
            position: absolute;
            left: 4.18%;
       
            top: 73.17%;
            bottom: -0.11%;
        }
        .footerimage1__2{
        
            position: absolute;
            left: 15.65%;
          
            top: 85.85%;
            bottom: 0.2%;
        }
        .footerimage1__3{
            position: absolute;
        left: 1.17%;
        right: 38.82%;
        top: 84.34%;
        bottom: -0.09%;
        
        }
        .footerimage1__4{
            position: absolute;
            left: 26.31%;
            right: 36.18%;
            top: 75.93%;
            bottom: 1.37%;
            
           
        }
        }