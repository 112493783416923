
.paytext{
    margin-left: 8.5%;
}
.basetext{
    margin-left: 8%;
}

.textboxwork_0{
    width: 517px;
    height: 48px;
    margin-left: 352px !important;
    top: 288px;
      border: 1px solid #0080FF !important;
      border-radius: 10px;
     
      
  }
  @media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .textboxwork_0{
        width: 517px;
        height: 48px;
        margin-left:372px !important;
        top: 288px;
          border: 1px solid #0080FF !important;
          border-radius: 10px;
         
          
      }

    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .textboxwork_0{
            width: 517px;
            height: 48px;
            margin-left:242px !important;
           }
        }
        @media screen   and (min-device-width: 200px)  and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1)  {
          .textboxwork_0{
              width: 324px;
              height: 48px;
              margin-left:-100px !important;
              
             }
             .mob_tx{
              width: 300px !important;
            margin-left: 75px !important; 
            }
            .mob_bs{
              width: 330px !important;
              margin-left: 40px !important; 
            }
             .textboxwork_1{
              width: 324px !important;
              height: 48px !important;
              margin-left:-100px !important;
             }
             .textboxwork_2{
              width: 324px !important;
              height: 48px;
              margin-left:-100px !important;
             }
             .taxbutton{
              width: 140px !important;
              top: 631px !important;
              left: 25px !important;
          
          }
          .taxbutton_1{
            width: 140px !important;
            top: 631px !important;
            left: 175px !important;
        
        }
        personalimg{
          position: relative;
          left: 7% !important;   
          }
          tax1img{
          position: relative;
          left:75% !important; 
          top: -42px !important;
          width: 100% !important;  
          }
          Payimg{
          position: relative;
          left: 8% !important; 
          top:-60px !important;   
          }
          tax1text{
          margin-left:78% !important;
          margin-top: -70px !important;
          }
          .mobcheckicon{
          position: relative !important;
          margin-left:88% !important;
          margin-top: -70px!important;
           
          }
          mobcheckicon1{
          position: relative;
          margin-left:118% !important; 
          margin-top: -90px!important;
          }
          .paytext{
          margin-left:103% !important;
          margin-top: -99px !important;
          }
          .slider1 {
            height: 50px;
            display: flex;
            overflow-x: auto;
              }
            .slide1 { 
            flex-shrink: 0;
             }
             .text_tax{
       
              left:80px !important;
            top:417px !important;
            }

          }
  .textboxwork_1{
    width: 517px;
    height: 48px;
    margin-left: 352px ;
    top: 288px;
      border: 1px solid #0080FF !important;
      border-radius: 10px;
      background-color: #F3F3F3 !important;
      
  }
  @media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .textboxwork_1{
        width: 517px;
        height: 48px;
        margin-left: 372px !important;
      
          
      }

    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .textboxwork_1{
            width: 517px;
            height: 48px;
            margin-left:242px !important;
           }
        }
  .textboxwork_2{
    width: 517px;
    height: 48px;
    margin-left: 352px ;
    top: 338px;
      border: 1px solid #0080FF !important;
      border-radius: 10px;
      background-color: #F3F3F3 !important;
      
  }
  @media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .textboxwork_2{
       
        margin-left:372px !important;
          
      }

    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .textboxwork_2{
            width: 517px;
            height: 48px;
            margin-left:242px !important;
           }
        }
  .text_tax{
    position: absolute;
    width: 60px;
    height: 16px;
    left:696px;
    top: 211px;
    
   
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
  }
  @media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .text_tax{
       
        left:456px;
      
      }
    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .text_tax{
       
            left:556px;
          
          }
        }
  .taxbutton{
    position: absolute;
    width: 253px;
    height: 48px;
    left: 637px;
    top: 441px;
    font-size: small !important;
    background: #727272;
    box-shadow: 0px 4px 12px #727272;
    border-radius: 5px;
}
@media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .taxbutton{
        top: 401px;
        left: 405px;
    
    }
    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .taxbutton{
            top: 431px;
            left: 505px;
        
        }
        }
.taxbutton_1{
    position: absolute;
    width: 253px;
    height: 48px;
    left: 901px;
    top: 441px;
    font-size: small !important;
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}
@media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .taxbutton_1{
       top: 401px;
        left: 665px;
    
    }
    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .taxbutton_1{
            top: 431px;
            left: 765px;
        
        }
        }
.imagetax{
    width:  433px !important;
    height: 234 px !important;
    position: absolute;
    margin-top: 430px;
    margin-left: 700px !important;
 }
 @media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
    .imagetax{
     
        margin-top: 430px;
        margin-left: 470px !important;
     }
    }
     @media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
        .imagetax{
            
            margin-top: 430px;
            margin-left: 570px !important;
         }
        }

        @media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {
          .textboxwork_0{
            width: 324px;
            height: 48px;
            margin-left:100px !important;
           }
           .text_tax{
       
            left:196px;
          top: 212px !important;
          }
           .textboxwork_1{
            width: 324px !important;
            height: 48px !important;
            margin-left:100px !important;
           }
           .textboxwork_2{
            width: 324px !important;
            height: 48px;
            margin-left:100px !important;
           }
           .imagetax{
            
            margin-top: 590px;
            margin-left: 160px !important;
         }
         .taxbutton{
          top: 421px;
           left: 180px;
           width: 120px;
       
       }
         .taxbutton_1{
          top: 421px;
           left: 330px;
           width: 120px;
       
       }
        }