.paybank{
    width: 517px;
    height: 48px;
    margin-left: 352px !important;
    top: 288px !important;
      border: 1px solid #0080FF !important;
      border-radius: 10px;
      background-color: #F3F3F3 !important;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .paybank{
        margin-left: 242px !important;
    }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
       .paybank{
        margin-left: 372px !important;
       }
    }
.text_bank{
    
        position: absolute;
        width: 40px;
        height: 16px;
        left:686px;
        top:220px;
        
        
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        background-color:lightgray;
        color: #0080FF;
      
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .text_bank{
        left:546px;
        
    }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
       .text_bank{
        left:446px;
        
       }
    }
.text_acc{
    
    position: absolute;
    width: 100px;
    height: 16px;
    left:686px;
    top: 284px;
    
 
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
  
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .text_acc{
        left:546px;
        
    }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
       .text_acc{
        left:446px;
        
       }
    }
.accbutton{
    position: absolute;
    width: 253px;
    height: 41px;
    left: 640px;
    top: 361px;
    font-size: small !important;
    background: #727272;
    box-shadow: 0px 4px 12px #727272;
    border-radius: 5px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .accbutton{
        left:500px;
        
    }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
        .accbutton{
            left:400px;
            
        }
    }
.accbutton_1{
    position: absolute;
    width: 253px;
    height: 41px;
    left: 901px;
    top: 361px;
    font-size: small !important;
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .accbutton_1{
        left:766px;
        
    }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
        .accbutton_1{
            left:666px;
            
        }
    }
.img_emp_pay{
  
    position: absolute;
    margin-top: 430px;
    margin-left: 650px !important;
    height: 222.21449279785156px;
width: 411.19287109375px;


}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .img_emp_pay{
    margin-left: 550px !important;
     }
    }
    @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
        .img_emp_pay{
            margin-left: 450px !important;
         }
    }
.Pay_img{
    position: absolute;
    left: 73%; 
    top:12.1%;   
    }
    @media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
        .Pay_img{
            position: absolute;
            left: 76%; 
            top:12%;   
            }
        }
        @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
            .Pay_img{
                position: absolute;
                left: 83%; 
                top:12.1%;   
                }
        }
    @media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
        ._text{
          font-size: x-small;
        }
        }
        @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
           ._text{
            font-size: x-small;
           }
        }
        @media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
         
        .accbutton{
            width: 140px !important;
            top: 581px !important;
            left: 25px !important;
        
        }
        .accbutton_1{
          width: 140px !important;
          top: 581px !important;
          left: 175px !important;
      
      }
      .paybank{
        width: 324px;
        height: 48px;
        margin-left:-100px !important;
       
      }
      .text_bank{
        left:80px !important;
            top:416px !important;
      }
      .text_acc{
        left:80px !important;
            top:411px !important;
      }
    }

    @media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {
        .accbutton{
            width: 140px !important;
 
            left: 155px !important;
        
        }
        .accbutton_1{
          width: 140px !important;
        
          left: 325px !important;
      
      }
      .paybank{
        width: 324px;
        height: 48px;
        margin-left:100px !important;
       
      }
      .text_bank{
        left:210px !important;
            top:216px !important;
      }
      .text_acc{
        left:80px !important;
            top:411px !important;
      }
      .img_emp_pay{
        margin-left: 150px !important;
         }
         .tabcl{
margin-left: 20px !important;
         }
    }