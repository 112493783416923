
background-image
{
  
    height: 606px;
    background-repeat: no-repeat;
    left:5px;
    margin-top:200px;
    background-size: contain;
    background-size: 100%;
}

.circleimg
{
    width:890px;
    height:200px;
    padding-top:0px;
}
.new
{
    height: 340px;
   width: 890px;
}
.circleimg2
{
    padding-left: 740px; 
    padding-top: 50px;
} 
.circleimg1
{
 padding-left: 67px;
 padding-top: 5px;
}
col-5
{
    height: 1024px;
}
.headn
{
    padding-top:100px;
}
.div3
{
    left:110px;
    top:70px;
    position: relative;

}
.txtstyle
{
    box-sizing: border-box;
    position: relative;
    width: 340px;
    height: 56px;  
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;
    left: 10px;
}
.lbltext
{
position: relative;
width: 44px;
height: 19px;
left: 10px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #000000;
}
.chekb{
margin-left: 20px;
box-sizing: border-box;
width: 20px;
height: 20px;
border: 1px solid #C4C4C4;
}
.btntxt
{
width: 340px;
height: 56px;
background: #0080FF;
border-radius: 5px;
}
.txtn
{
    padding-left: 20px;
}
mb-5
{
    position: relative;
    padding-left: 100px;
}
form-control inputcolor
{
    width: 50%;
}
.loginbtn
{
    margin-top: 50px;
    margin-left: 12px !important;
}
.btns
{
width: 335px !important;

}

@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
.btns
{
width: 300px !important;

}
}

@media screen   and (min-device-width: 1640px)   and (-webkit-min-device-pixel-ratio: 1)  {
    .btns
    {
    width: 440px !important;
    
    }
    }
.div4
{
    padding-top:270px;
    
}
.lblaccount
{
    width: 215px;
    height: 19px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
   
    color: #000000;
    padding-left: 40px;
}

.txtcenter
{
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #000000;
}
.signup
{
    height: 19px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
   
    color:#0080FF;
    
}
.headings
{
    margin-left:120px;
    padding-top:50px;
}
.headlanguage
{
margin-left: 10px;
width: 117px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #0080FF;
}
.headeng
{
    margin-left: 10px;
width: 117px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #727272;
}
.polygon
{
width: 9px;
height: 9px;
margin-left:1px;
}
.lblremeber
{
padding-left:10px;
width: 73px;
height: 19px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #727272;
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) { 

    .signuup-mb {
        margin-top: -2% !important;
        margin-left: 10% !important;
    }
    .labelml {
        margin-left: -20% !important;
        
    }
    .lastmv {
        margin-top: 20% !important;
        margin-left: -20% !important;
    }
    .emailmv {
        margin-top: 20% !important;
        margin-left: -20% !important;

    }
    .pwdmv {
        margin-top: 20% !important;
        margin-left: -20% !important;
    }
    .confir-mv {
        margin-top: 20% !important;
        margin-left: -20% !important;
    }
    .bg-mobv {
        width: 500px !important;
        
    }
    .signup-imgmv {
        margin-left: -100% !important;
    }
    
}
.sceen_size{
overflow: hidden !important;
position: fixed;

}
.text_small_error{
    font-size: smaller !important;
}
@media (min-width: 600px) and (max-width: 1024px){  
  
    .loginbackground {
        margin-top: 90% !important;
    }
    .signuup-mb {
        margin-left: -10% !important;
        width: 50% !important;
    }
    .bg-mobv {
        width: 50% !important;
    }
    .headlanguage {
        margin-top: -20% !important;
        margin-left: 20% !important;
    }
    .confir-mv1  {
        margin-left: -10% !important;
    }
    .labeltab {
        margin-top: 10% !important;
        margin-bottom: -30% !important;
    }
    .btns{
        width: 60% !important;
        
        }
    .imgborsig {
    max-width: 100% !important;
    height:680px !important
  }
  .inpucent{
    margin-left: 220px !important;
}
.tabimgcent{
    margin-left: 260px !important;
}
.tabinpwids{
    width: 60% !important;
}
.tmtp{
    margin-top: 30px !important;
}
      
}



.logo_size{
height: 95px !important;
width: 275px !important;
position: absolute;
margin-left: -35px !important;
margin-top: -20px 
!important;
}
