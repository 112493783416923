.bankaccount_box{
    box-sizing: border-box;

position: absolute;
width: 518px ;
height: 48px;
left: 650px;
top: 300px;

border: 1px solid #0080FF !important;
border-radius: 10px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .bankaccount_box{
    width: 518px !important;
    height: 48px;
    left: 500px;
    top: 300px;
    }
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .bankaccoun_tbox{
        width: 458px !important;
        height: 48px;
        left: 500px;
        top: 300px;
        }
 }

._position_heading{
    margin-left: 360px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    ._position_heading{
        margin-left: 320px !important;
    }
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    ._position_heading{
        margin-left: 260px;
    }
 }
.text_position{
    margin-left: 280px;
    color: rgb(228, 221, 221);
}
.addcopmanybutton{
    position: absolute;
    width: 220px;
    height: 48px;
    left: 655px !important;
    top: 381px;
    font-size: small !important;
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}

@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .addcopmanybutton{
        margin-left: -100px !important;
    }
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .addcopmanybutton{
        margin-left: -200px;
        width: 220px;
    }
 }
.addcopmanybutton_1{
    position: absolute;
    width: 220px !important;
    height: 48px;
    left: 900px;
    top: 381px;
    font-size: small !important;
    background: #0080FF;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .addcopmanybutton_1{
        margin-left: -100px !important;
    }
 }
 @media screen and (min-device-width: 800px)and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1){
    .addcopmanybutton_1{
        margin-left: -235px;
        width: 220px;
    }
 }
.text_account{
    position: absolute;
    width: 100px !important;
    height: 16px;
    left: 539px;
    top: 291px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;
}
.footerimage2{
    top: 67.17% ;
    width:  166.37px !important;
    position: absolute;
   
    margin-left: 63% !important;
}
.footerimage1_1{
    position: absolute;
    left: 48.18%;
    right: 39.4%;
    top: 73.17%;
    bottom: -0.11%;
}
.footerimage1_2{

    position: absolute;
    left: 59.65%;
    right: 48.26%;
    top: 87.85%;
    bottom: 0.2%;
}
.footerimage1_3{
    position: absolute;
left: 45.17%;
right: 38.82%;
top: 87.34%;
bottom: -0.09%;

}
.footerimage1_4{
    position: absolute;
    left: 60.31%;
    right: 36.18%;
    top: 75.93%;
    bottom: 1.37%;
    
   
}
@media screen and (min-device-width: 1200px)and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1){
    .footerimage2{
        top: 72.17% ;
        margin-left: 60% !important;
    }
    .footerimage1_1{
        left: 45.18%;
        top: 77.17% !important;
      
    }
    .footerimage1_2{
    
        left: 56.65%;
        top: 91.85% !important;
       
    }
    .footerimage1_3{
        left: 42.17%;
    top: 91.34% !important;
   
    
    }
    .footerimage1_4{
        left: 57.31%;
        top: 79.93% !important;     
    }
}
@media screen   and (min-device-width: 200px)  and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1)  {
    .mobcm1{
        margin-left: -55px !important;
         
        }      
._position_heading{
    margin-left: 0px;
    font-size: x-small !important;
}
.bankaccount_box{
    width: 324px;
    height: 48px;
    left:10px !important;
}
.addcopmanybutton{
    font-size :smaller !important; 
    width: 140px !important;
    top: 400px !important;
    left: 50px !important;

}
.addcopmanybutton_1{
  width: 140px !important;
  top: 400px !important;
  left: 205px !important;

}
}
@media screen   and (min-device-width: 600px)  and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)  {
    .bankaccount_box{
    width: 352px ;
     left: 208px;
    top: 300px;
    }
    .hid_tab{
        display: none !important;
    }

    ._position_heading{
        margin-left: 100px;
    }
    .addcopmanybutton{
        left: 205px !important;
        width: 365px ;
    }
    .addcopmanybutton_1{
        left: 205px !important;
        width: 365px !important;
       margin-top: 70px !important;
    }
    .text_account{
        left: 259px !important;
      
    }
    .footerimage2{
        top: 69.17% ;
        margin-left: 70% !important;
    }
    .footerimage1_1{
        left: 5.18%;
        top: 74.17% !important;
      
    }
    .footerimage1_2{
    
        left: 18.65%;
        top: 85.85% !important;
       
    }
    .footerimage1_3{
        left: 2.17%;
    top: 84.34% !important;
   
    
    }
    .footerimage1_4{
        left: 27.31%;
        top: 75.93% !important;     
    }
}