.timebox{
    width: 80px !important;
height: 30px  !important;
background-color:  #F3F3F3 !important;
border-radius: 10px !important;
}
.text-cmt{
    font-size: small;
}
.timeapp{
width: 95px!important;
}
.timecardrow{
    height: 20px !important;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px) and (-webkit-min-device-pixel-ratio: 1){
    .disp_none{
        display: none !important;
    }
    .timebox{
        width: 40px !important;
    height: 20px  !important;
    background-color:  #F3F3F3 !important;
    border-radius: 10px !important;
    }
    .text_s{
        font-size: x-small !important;
    }
    .dtbx{
        width: 100px !important;
        left: 30px !important;
        position: absolute;
    }
    .dtbx1{
        width: 100px !important;
        margin-left: 0px !important;
    }
.Depamob{

margin-top: -70px !important;
margin-left: -90px !important;
}
.Clockt{
 
    margin-top: 10px !important;
    margin-left: -50px !important;
}
.Clockt1{
 
    
    margin-left: -50px !important;
}
.Clockt2{
 
    
    margin-left: -49px !important;
}
.Clockt3{
 
    
    margin-left: -189px !important;
}
.Clockt4{
 
    
    margin-left: 80px !important;
    margin-top: -17px !important;
}
.Clockt8 {
    margin-left: 252px !important;
    margin-top: -60px !important;
}

.Clockt9 {
    margin-left: 338px !important;
    margin-top: -80px !important;
}
.Clockt10{
 
    
    margin-left: 72px !important;
}

.Clockt5{
 
    
    width: 120% !important;
}
.Clockt6{
 
    
    margin-left: 19px !important;
}
.Clockt7{
 
    
   align-items: center !important;
}

.chnge{

    margin-left: 31px !important;

}
.Close2{
 
    
    margin-left: 39px !important;
}
.hcl{

    margin-top: -15px !important;
}

}

.react-confirm-alert-body{
color: rgb(110, 110, 244) !important;


}
.react-confirm-alert-button-group > button {
   
    width:100px !important ;
height: 35px !important;
}