.leavecard{
    width: 1180px !important;
    margin-left: 60px !important;
}

.cardb{
  margin-top: 25px !important;
    }


@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
.leavecard{
    width: 1024px !important;
    margin-left: 70px !important;
}
.cardb{
  margin-top: 25px !important;
    }
}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
.leavecard{
    width: 970px !important;
    margin-left: 75px !important;
}

}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
    .leaveml {
        margin-left: -5% !important;
        width: 10% !important;
    }
    .leave-ml {
        margin-left: 6% !important;
        margin-top: -140% !important;
    }
.leavecard {
    width: 360px !important;
    margin-left: -50px !important;
    top: 15px !important;
}
}

.task_textbox_lev {
    box-sizing: border-box;


    width: 550px !important;
    height: 174px;


    background: #F3F3F3 !important;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

@media (min-width: 768px) and (max-width: 1024px){  
    .leavemana-tab{
        margin-left:-19% !important;
      
        width: 119% !important;
    
    }

    .leavedis-none {
        display: none !important;
    }
    .name-tab {
        column-width: -20%  !important;
    }
  

}

.paginationBttns {
    width: 85%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 1px;
   
    
    
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: lightblue;
  }
  
  .paginationActive a {
    color:#2b2eff;
    background-color:lightblue;
    height: 38px;
  }
  
  .paginationDisabled a {
    color: lightgrey;
    
     }


     @media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
        
 /* .Tcardmob{
            
            min-width: max-content !important;
            
           
        }   */


.btms{

    margin-left: 10px !important;
}

   .downmob{
       
          margin-left: 420px !important;
          overflow-x: auto !important;
          margin-top: 45px !important;
      }
      
      .downmob1{
       
        margin-left: -400px !important;
        margin-top: -32px !important;

       
    }

    
    
    .cardmob{
     
      margin-left: -500px !important;
      
  }
.closemob{

  margin-left: 150px !important;
}

.closemob1{

    margin-top: 320px !important;

}

.graphcardwidthmob6{
     width: 110% !important; 
    margin-left:-40px !important;
    margin-top: 30px !important;
  }


.fntss{

margin-left: -10px !important;
  }

  .cardbt{
margin-top: 85px !important;
  }


  .logscre{
    position: fixed;
     overflow: scroll;  
    }

    .crdb{

        margin-top: 20px !important;
    }

  
.year-buttonss {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto !important;

}



.paginationBttns {
    width: 100% !important; 
    max-width: 100%;
    height: auto !important;
    list-style: none !important;
    display: flex !important;
    justify-content: center !important;
    font-size: small !important;
     overflow-x: auto;  
    
}

.paginationBttns a {
    padding: 10px !important;
    margin: -18px !important;
    margin-bottom: 5px !important;
    cursor: pointer;
}

.paginationBttns a:hover {
    color: white !important;
    background-color: lightblue !important;
}

.paginationActive a {
    color: #2b2eff !important;
    background-color: lightblue;
    height: 38px !important;
}

.paginationDisabled a {
    color: lightgrey;
}

   
     }
